import React,{useState , Fragment, useEffect} from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import DataTable  from '@ashvin27/react-datatable';
import { useHistory } from 'react-router-dom';
import { deactivatefeehook, getallfeehook } from '../../../Hooks/adminHook';
const TableView = () =>{
    const {push} = useHistory();
    const [fee , setFee] = useState([]);

    useEffect(()=>{
        async function fetchdata(){
            await getallfeehook().then((result)=>{
                console.log("get fee api" , result);
                result?.data?.data?.record?.map((data , i)=> data.serial = i+1);
                setFee(result?.data?.data?.record);
            })
        }
        fetchdata();
    },[])
    const columns = [
        {
            key: "serial",
            text: "S.no.",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "id",
            text: "Id",
            className: "name",
            align: "left",
        },
        {
            key: "name",
            text: "Fee Name",
            className: "name",
            align: "left",
        },
        {
            key: "percentage",
            text: "Amount In Percentage",
            className: "name",
            align: "left",
        },
        {
            key: "status",
            text: "Status",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "action",
            text: "Action",
            className: "action",
            width: 100,
            align: "left",
            cell: record => {
                return (
                    <Fragment>
                       
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={() => editRecord(record)}
                            style={{ marginRight: '5px' }}
                            >
                            <i className="fa fa-edit mr-0"></i>
                        </button>
                       
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={() => deactivatefee(record)}
                            style={{ marginRight: '5px' }}>
                            <i className="fa fa-trash mr-0"></i>
                        </button>
                    </Fragment>
                );
            }
        }
    ]

    const editRecord = async(data)=>{
        push("/edit-fee" , {state : data});;
    }
    const deactivatefee = async(data)=>{
        await deactivatefeehook(data._id).then((result)=>{
            console.log("deactivate fee hook" , result);
            window.location.reload();
        })
    }
    const config = {
        page_size: 10,
        length_menu: [ 10, 20, 50 ],
        filename: "Users",
        no_data_text: 'No user found!',
        button: {
            excel: true,
            print: true,
            csv: true
        },className: "table",
        language: {
            length_menu: "Show _MENU_",
            filter: "Filter User",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first:"<<",
                previous: "<",
                next: ">",
                last: ">>"
            }
        },
        show_length_menu: true,
        show_filter: true,
        show_pagination: true,
        show_info: true,
    };

    return(
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont'>
                <h4 className='adminblue my-4'>Fee</h4>
                <div className='pl-4 text-end mt-3' >
                            <button className='btn btn-theme me-0' onClick={() => {push("/add-fee")}}>Add</button>
                        </div>
                
             
              
                <div className='tablesec shadowbox px-3 mt-3 br-10 mb-5'>
                    <h6 className='adminblue pt-4 me-2' ><span className='fa fa-book'></span> View User</h6>
                    <DataTable striped responsive
                        config={config}
                        className={config.className}
                        records={fee}
                        columns={columns}

                    />
               </div>
               </div> 
            </div>
        </div>
    )
}

export default TableView