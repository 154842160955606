import React,{useState , Fragment, useEffect} from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import {FloatingLabel, Form,Button} from 'react-bootstrap'
// import DataTable from 'react-data-table-component';
import DataTable  from '@ashvin27/react-datatable';
import { Bar } from "react-chartjs-2";
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { getallcancelbookingroomhook } from '../../../Hooks/adminHook';
import Refundmodal from "../refundmodal";
import { useHistory } from 'react-router-dom';
const TableView = () =>{
    const [cancel , setCancel] = useState();
    const [refundmodal , setRefundmodal] = useState(false);
    const [user , setUser] = useState();
    const [totalrecordcounts, setTotalRecordCounts] = useState(0)
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const host = {};
    const {push} = useHistory();

    useEffect(()=>{
        async function fetchdata(){
            let reqData = {
                page : page,
                limit : limit
            }
            await getallcancelbookingroomhook(reqData).then((result)=>{
                console.log("result" , result?.data?.data);
                result?.data?.data?.record?.map((data , i) => data.id = i+1);
                setCancel(result?.data?.data?.record);
                setTotalRecordCounts(result?.data?.data?.count);
            })
        }
        fetchdata();
    },[])
    const columns = [
        {
            key: "id",
            text: "Id",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "hostEmail",
            text: "Host Email",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "email",
            text: "Customer Email",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            // key: "checkInDate",
            cell : row =>(new Date(row?.checkInDate).toLocaleDateString()),
            text: "Checkin",
            className: "name",
            align: "left",
            sortable: true,
            
        },
        {
            // key: "iddocuments",
            cell : row =>(new Date(row?.checkOutDate).toLocaleDateString()),
            text: "checkout",
            className: "name",
            align: "left",
            sortable: true,
            
        },
        {
            key: "cancelledAmount",
            text: "Refund Amount",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "status",
            text: "Status",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "paymentstatus",
            text: "Payment Status",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "action",
            text: "Edit action",
            className: "action",
            width: 100,
            align: "left",
            sortable: true,
            cell: record => {
                return (
                    <Fragment>
                        {/* <button
                            className="btn btn-primary btn-sm"
                            // onClick={() => viewRecord(record)}
                            style={{ marginRight: '5px' }}>
                            <i class="fa fa-eye mr-0" ></i>
                        </button> */}
                        <button disabled = {record?.status == "Closed"}
                            className="btn btn-primary btn-sm"
                            // onClick={() => editRecord(record)}
                            // style={{ marginRight: '5px' }}
                           onClick={() => {setUser(record);setRefundmodal(true); }} >Refund
                            {/* <i className="fa fa-edit mr-0"></i> */}
                        </button>
                        <button
                            className="btn btn-danger btn-sm"
                            onClick={() => push("/view-refund" , {state: record})}>
                                View
                            {/* <i className="fa fa-edit mr-0"></i> */}
                        </button>
                        {/* <button
                            className="btn btn-primary btn-sm"
                            onClick={() => WhiteList(record)}
                            style={{ marginRight: '5px' }}>
                            <i className="fa fa-edit mr-0"></i>
                        </button> */}
                    </Fragment>
                );
            }
        }
        // {
        //     name:"Id",
        //     reorder: true,
        //     cell: (data) =>(data.id)
        // },
        // {
        //     name:"Reservation Id",
        //     cell: (data) =>(data.reserveid)           
        // },
        // {
        //     name:"Room name",
        //     cell: (data) =>(data.Roomname)          
        // },
        // {
        //     name:"User Name",
        //     cell: (data) =>(data.username)
        // },
        // {
        //     name:"Email Id",
        //     cell: (data) =>(data.emailid)
        // },
        // {
        //     name:"User Date From",
        //     cell: (data) =>(data.userdatafrom)
        // },
        // {
        //     name:"User Date To",
        //     cell: (data) =>(data.userdatato)
        // },
        // {
        //     name:"Id Document",
        //     cell: (data) =>(data.iddocuments)
        // },
        // {
        //     name:"Status",
        //     cell: (data) =>(data.staus)
        // }
    ]
    
    const config = {
        page_size: 10,
        length_menu: [ 10, 20, 50 ],
        filename: "Users",
        no_data_text: 'No user found!',
        button: {
            excel: true,
            print: true,
            csv: true
        },
        className: "table",
        language: {
            length_menu: "Show _MENU_",
            filter: "Filter User",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first:"<<",
                previous: "<",
                next: ">",
                last: ">>"
            }
        },
        show_length_menu: true,
        show_filter: true,
        show_pagination: true,
        show_info: true,
    };
  
    const pageChange = async (index) => {

        try {
            let reqData = {
                page: index.page_number,
                limit : index.page_size,
                search: index.filter_value
            }
            await getallcancelbookingroomhook(reqData).then((result)=>{
                console.log("pageChangeresult" , result);
                result?.data?.data?.record?.map((data , i) => data.id = i+1);
                setCancel(result?.data?.data?.record);
            })
            console.log('index_data', index)
        } catch (err) {
            console.log("pageChange_err", err)
        }
    } 

const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun"];


    return(
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
                {refundmodal && <Refundmodal type = { "refund"} hostdata = {user} onDismiss= {()=>setRefundmodal(false)}></Refundmodal>}
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont'>
                <h4 className='adminblue my-4'>Cancel Rooms Refund</h4>
                
             
              
                <div className='tablesec shadowbox px-3 mt-3 br-10 mb-5'>
                    <h6 className='adminblue pt-4 me-2' ><span className='fa fa-book'></span> View Cancel Rooms</h6>
                    {/* <DataTable pagination columns={columns} records={data} config = {config} className={config.className}/> */}
                    <DataTable striped responsive
                        config={config}
                        className={config.className}
                        records={cancel}
                        columns={columns}
                        dynamic={true}
                        total_record={totalrecordcounts}
                        onChange={pageChange}

                    />
               </div>
               </div> 
            </div>
        </div>
    )
}


export default TableView