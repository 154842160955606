import React,{useState , useEffect} from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import {FloatingLabel, Form,Button} from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import { Bar } from "react-chartjs-2";
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useHistory } from 'react-router-dom';
import {  editcancellationpolicyhook } from '../../../Hooks/adminHook';
const FormView = () =>{
//   const navigate = useNavigate();
const {push} = useHistory();
  const location = useLocation();
  const [cancellationpolicy , setCancellationpolicy] = useState({});
  const [type , setType] = useState('');
  const [status , setStatus] = useState('');
  const [days , setDays] = useState('');
  const [percentage , setpercentage] = useState('');

  useEffect(()=>{
    document.getElementById("managecancellationpolicy").classList.add("active");
    if(window.innerWidth>767)
    {
        // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
        if(document.querySelectorAll(".themescroll li a.active").length>0)
        {           
             var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
      console.log(pagelinkofset,"pagelinkofset")
             document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);

        }

    }
    else
    {
        // alert(2)
        if(document.querySelectorAll(".themescroll li a.active").length>0)
        {
            // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
            if(document.querySelectorAll(".themescroll li a.active").length>0)
            {  
            var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
            document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);
            }
        }
        
    }
},[]);


  useEffect(()=>{
    console.log("location data" , location?.state?.state);
    setCancellationpolicy(location?.state?.state);
    setType(location?.state?.state?.type);
    setStatus(location?.state?.state?.status);
    setDays(location?.state?.state?.days);
    setpercentage(location?.state?.state?.percentage);
},[])

  
const handlesubmit = async() => {
    console.log("lenght" , type?.length, status?.length ,days?.length , percentage?.length);
    if(type?.length >0 && status?.length >0 && days && percentage)
    {
        if(!(/\D/.test(days)) ){
        if(!(/\D/.test(percentage))&& percentage >0 && percentage<=100){
            let payload = {
                type : type,
                status : status,
                days :days,
                percentage : percentage,
                id : cancellationpolicy?.id
            }
            await editcancellationpolicyhook(payload).then((result) => {
                console.log("add cancellation policy result" , result);
                push("/managecancellationpolicy");
            });
        }
        else{
            window.alert("Percentage must be o to 100 !");
        }
    }
    else{
        window.alert("Please enter the valid input");
    }
    }
    else{
        window.alert("Fill all the fields!..")
    }
}
    return(
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont'>
                <h4 className='adminblue my-4'>Edit Cancellation Policy</h4>
                <div className='admin-forms mb-4 '>
                    <div className='shadowbox p-4 br-10'>
                    <div className='row'>
                        <div className='col-sm-6 mb-4'> 
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Type *">
                            <Form.Control placeholder="demo" type="text" defaultValue={type} onChange ={(e) =>{setType(e?.target?.value)}} />
                            </FloatingLabel>
                            </div>
                        <div className='col-sm-6 mb-4'> 
                            <FloatingLabel
                                controlId="floatingInput"
                                label="No. Of Days *">
                                <Form.Control placeholder="demo" type="text" defaultValue={days} onChange={(e) => {setDays(e?.target?.value)}} />
                            </FloatingLabel>
                        </div>
                        </div>

                        
                       <div className='row'>
                        <div className='col-sm-6 mb-4'> 
                        <FloatingLabel controlId="floatingSelect" label="Status *">
                            <Form.Select aria-label="Floating label select example" value={status} onChange={(e) => {setStatus(e?.target?.value)}}>
                                <option value="">Select Status</option>
                                <option >Active</option>
                                <option >Inactive</option>
                            </Form.Select>
                            </FloatingLabel>
                            </div>
                        <div className='col-sm-6 mb-4'> 
                            <FloatingLabel
                                controlId="floatingInput"
                                label="percentage *">
                                <Form.Control placeholder="demo" type="text" defaultValue={percentage} onChange={(e) => {setpercentage(e?.target?.value)}} />
                            </FloatingLabel>
                            </div>
                          
                       </div>
                        <div className='px-4 text-end mt-3 btn_view_oneline' >
                            <button className='btn btn-theme me-3'onClick={handlesubmit}>Submit</button>
                            <Button variant="outline-dark" className='f-12 fw-600 outlinebtn' onClick={()=>{push("/managecancellationpolicy")}}>Cancel</Button>
                        </div>
                    </div>
                
                </div>

                
               </div> 
            </div>
        </div>
    )
}

export default FormView