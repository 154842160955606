import React,{useState , Fragment, useEffect} from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import {FloatingLabel, Form,Button} from 'react-bootstrap'
// import DataTable from 'react-data-table-component';
import DataTable  from '@ashvin27/react-datatable';
import { Bar } from "react-chartjs-2";
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from 'react-router-dom';
import {CsvlegalfileUpload, deletelegalandprivacy, getlegalhook } from '../../../Hooks/adminHook';
import Papa from "papaparse";
import {toast , Toaster }from "react-hot-toast"
import { toastOptions } from '../../../config/env';
const TableView = () =>{

    const [legal , setLegal] = useState([]);
    const[path, setPath] = useState('')
    // const navigate = useNavigate();
    const {push} = useHistory();
    const [csvfile, setCsvfile] = useState({});
    const [csvfilename, setCsvfilename] = useState('');
    const [legalId , setLegalId] = useState('')

    useEffect(()=>{
        async function fetchdata(){
            await getlegalhook().then((result) => {
                console.log("get legal result" , result);
                setLegal(result?.data?.data?.record[0]?.content);
                setLegalId(result.data.data.record[0]._id)
                setPath(result?.data?.data?.record[0]?.path)
            })
        }
        fetchdata();
    },[])

    const deletelegal = async(data) => {
        console.log('deletelegalsssss',data,data?.id)
        let payload = {
            id : data.id,
            name : "legal"
        }
        await deletelegalandprivacy(payload).then((result) => {
            console.log("delete legal result" , result);
            window.location.reload();
        })
    }

    const editlegal = async(data) => {
        console.log('dataeditlegal',data)
        let datas ={
            data : data,
            path : path
        }
        push("/editlegal" , {state : datas});
    }

    const CsvfileReader = async (data) => {
        console.log('csvfilereader',data)
        try {
            let reqData = {
                '_id' : legalId,
                'content' : data
            }
            await CsvlegalfileUpload(reqData).then((res) => {
                console.log('ressssssssssdds', res.data.data)
                let status = res.data.data.status;
                let message = res.data.data.message;
                console.log('sdhafajksdhfjasd', message)
                if (status == true) {
                    toast.success(message, toastOptions)
                }
                else {
                    toast.error(message, toastOptions)
                }
            })
        } catch (err) {
    
        }
    
    }

    const changeHandler = async (e) => {
        console.log('shdgfhjsdfgsjafdsf',e.target.files[0].name)
        let splitFile = e.target.files[0].name.split(".");
        console.log("splitFile", splitFile)
        if (splitFile[splitFile.length - 1] != "csv") {
         toast.error('Select your csv file !',toastOptions)
            return false
        }
        const valuesArray = [];
        if(splitFile[splitFile.length - 1] == "csv") {
            setCsvfilename(e.target.files[0].name)
            Papa.parse(e.target.files[0], {
                header: true,
                skipEmptyLines: true,
                complete: async (results) => {
                    // this.setState({ valuesArray: results.data })
        
                    // Iterating data to get column name and their values
                    results.data.map((d) => {
                        console.log("===============", Object.keys(d));
                        valuesArray.push(Object.values(d));
                    });
        
                    // Filtered Values
                    setCsvfile({ valuesArray })
                    CsvfileReader(valuesArray)
                },
                
            });
        }
       
        
    }
    const columns = [
        {
            key: "id",
            text: "Id",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "type",
            text: "Type",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "status",
            text: "Status",
            className: "name",
            align: "left",
            sortable: true,
        },
        
        {
            key: "action",
            text: "Action",
            className: "action",
            width: 100,
            align: "left",
            // sortable: true,
            cell: record => {
                return (
                    <Fragment>
                        {/* <button
                            className="btn btn-primary btn-sm"
                            // onClick={() => viewRecord(record)}
                            style={{ marginRight: '5px' }}>
                            <i class="fa fa-eye mr-0" ></i>
                        </button> */}
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={() => editlegal(record)}
                            style={{ marginRight: '5px' }}
                            >
                            <i className="fa fa-edit mr-0"></i>
                        </button>
                        {/* <button
                            className="btn btn-danger btn-sm"
                            onClick={() => uploadImage(record)}>
                            <i className="fa fa-edit mr-0"></i>
                        </button> */}
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={() => deletelegal(record)}
                            style={{ marginRight: '5px' }}>
                            <i className="fa fa-trash mr-0"></i>
                        </button>
                    </Fragment>
                );
            }
        }
    ]

    const config = {
        page_size: 10,
        length_menu: [ 10, 20, 50 ],
        filename: "Users",
        no_data_text: 'No user found!',
        button: {
            excel: true,
            print: true,
            csv: true
        },className: "table",
        language: {
            length_menu: "Show _MENU_",
            filter: "Filter User",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first:"<<",
                previous: "<",
                next: ">",
                last: ">>"
            }
        },
        show_length_menu: true,
        show_filter: true,
        show_pagination: true,
        show_info: true,
    };
  

const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun"];


    return(
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont'>
                <h4 className='adminblue my-4'>Legal</h4>
                <div className='pl-4 text-end mt-3' >
                     <button className='btn btn-theme me-0' onClick={()=> {push("/addlegal")}}>Add</button>
                </div>
                
             
              
                <div className='tablesec shadowbox px-3 mt-3 br-10 mb-5'>
                    <h6 className='adminblue pt-4 me-2' ><span className='fa fa-book'></span> View Legal</h6>

                    <div className="row ">
                            <div className='col-md-12'>
                                <div className='text-right pr-1'>
                                    <div className='d-flex align-items-center' >

                                        {/* {console.log('sgdfasdfgasjkdf', csvfilename, csvfile)}
                                        {csvfilename ? csvfilename : ''} */}
                                        {/* <span className='ml-auto ' >{csvfilename ? csvfilename : ''}</span>
                                        <div className='csvbtn ml-2'>
                                            <input type="file" name='csvfile' onChange={(e) => { changeHandler(e) }} />
                                            <button className='btn btn-secondary-outline '>CSV</button>
                                        </div> */}
                                    </div>


                                </div>
                            </div>
                        </div>


                    {/* <DataTable pagination columns={columns} records={data} config = {config} className={config.className}/> */}
                    <DataTable striped responsive
                        config={config}
                        className={config.className}
                        records={legal}
                        columns={columns}

                    />
                     <Toaster />
               </div>
               </div> 
            </div>
        </div>
    )
}

export default TableView