import React,{useState , Fragment} from 'react';
import Cookies from 'universal-cookie';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import {FloatingLabel, Form,Button} from 'react-bootstrap'
// import DataTable from 'react-data-table-component';
import DataTable  from '@ashvin27/react-datatable';
import { Bar } from "react-chartjs-2";
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useEffect } from 'react';
import { acceptdisputebycrmhook, acceptthedisputebycrmhook, getsinglecrmdisputehook } from '../../../Hooks/adminHook';
import { useHistory } from 'react-router-dom';
const cookies = new Cookies();
const TableView = () =>{
    const {push} = useHistory();
    const [dispute , setDispute] = useState([]);
    const admindata = cookies.get("admin-Datas");

    useEffect(()=>{
        async function fetchdata(){
            await getsinglecrmdisputehook(admindata?.name).then((result)=>{
                console.log("get single crm dispute" , result);
                result?.data?.record?.map((data , i)=> data.id = i+1)
                setDispute(result?.data?.record);
            })
        }
        fetchdata();
    },[])
    
    const columns = [
        {
            key: "id",
            text: "Id",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "ticketid",
            text: "Ticket Id",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "description",
            text: "Description",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "status",
            text: "Status",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "raiser",
            text: "Raiser Id",
            className: "name",
            align: "left",
            sortable: true,
        },
        // {
        //     key: "userdatafrom",
        //     text: "Id",
        //     className: "name",
        //     align: "left",
        //     sortable: true,
        // },
        // {
        //     key: "userdatato",
        //     text: "Id",
        //     className: "name",
        //     align: "left",
        //     sortable: true,
        // },
        // {
        //     key: "iddocuments",
        //     text: "Id",
        //     className: "name",
        //     align: "left",
        //     sortable: true,
        // },
        {
            // key: "staus",
            text: "Accept the dispute",
            className: "name",
            align: "left",
            cell : record =>record?.status == "pending"?<><button onClick={()=>{handleaccept(record?.ticketid)}}>Accept</button></>:record?.status,
            // sortable: true,
        },
        {
            key: "action",
            text: "Messaging",
            className: "action",
            width: 100,
            align: "left",
            sortable: true,
            cell: record => {
                return (
                    <Fragment>
                        {/* <button
                            className="btn btn-primary btn-sm"
                            // onClick={() => viewRecord(record)}
                            style={{ marginRight: '5px' }}>
                            <i class="fa fa-eye mr-0" ></i>
                        </button> */}
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={() => gochat(record)}
                            style={{ marginRight: '5px' }}
                            >
                            <i className="fa fa-envelope mr-0"></i>
                        </button>
                        {/* <button
                            className="btn btn-danger btn-sm"
                            onClick={() => uploadImage(record)}>
                            <i className="fa fa-edit mr-0"></i>
                        </button> */}
                        {/* <button
                            className="btn btn-primary btn-sm"
                            onClick={() => WhiteList(record)}
                            style={{ marginRight: '5px' }}>
                            <i className="fa fa-edit mr-0"></i>
                        </button> */}
                    </Fragment>
                );
            }
        }
        // {
        //     name:"Id",
        //     reorder: true,
        //     cell: (data) =>(data.id)
        // },
        // {
        //     name:"Reservation Id",
        //     cell: (data) =>(data.reserveid)           
        // },
        // {
        //     name:"Room name",
        //     cell: (data) =>(data.Roomname)          
        // },
        // {
        //     name:"User Name",
        //     cell: (data) =>(data.username)
        // },
        // {
        //     name:"Email Id",
        //     cell: (data) =>(data.emailid)
        // },
        // {
        //     name:"User Date From",
        //     cell: (data) =>(data.userdatafrom)
        // },
        // {
        //     name:"User Date To",
        //     cell: (data) =>(data.userdatato)
        // },
        // {
        //     name:"Id Document",
        //     cell: (data) =>(data.iddocuments)
        // },
        // {
        //     name:"Status",
        //     cell: (data) =>(data.staus)
        // }
    ]
    
    const handleaccept = async(data)=>{
        console.log("data" , data);
        await acceptthedisputebycrmhook(data).then((result)=>{
            console.log("accepth the dispute by crm hook result" , result);
        })
    }

    const config = {
        page_size: 10,
        length_menu: [ 10, 20, 50 ],
        filename: "Users",
        no_data_text: 'No user found!',
        button: {
            excel: true,
            print: true,
            csv: true
        },
        className: "table",
        language: {
            length_menu: "Show _MENU_",
            filter: "Filter User",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first:"<<",
                previous: "<",
                next: ">",
                last: ">>"
            }
        },
        show_length_menu: true,
        show_filter: true,
        show_pagination: true,
        show_info: true,
    };
  

const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun"];

// const datas = {
//   labels: labels,
//   datasets: [
//     {
//       label: "My First dataset",
//       backgroundColor: "rgb(255, 99, 132)",
//       borderColor: "rgb(255, 99, 132)",
//       data: [0, 10, 5, 2, 20, 30, 45],
//     },
//   ],
// };
const gochat = async(data)=>{
    console.log("dataa" , data);
    push("/disputechat" , {state : data , key : "crm"});
}
    return(
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont'>
                <h4 className='adminblue my-4'>Disputes</h4>
                
             
              
                <div className='tablesec shadowbox px-3 mt-3 br-10 mb-5'>
                    <h6 className='adminblue pt-4 me-2' ><span className='fa fa-book'></span> View Disputes</h6>
                    {/* <DataTable pagination columns={columns} records={data} config = {config} className={config.className}/> */}
                    <DataTable striped responsive
                        config={config}
                        className={config.className}
                        records={dispute}
                        columns={columns}

                    />
               </div>
               </div> 
            </div>
        </div>
    )
}


export default TableView