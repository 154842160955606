import {
  Route,
  Redirect
} from 'react-router-dom';
import Adminlogin from './Components/Admin/Login';
function adminRoute({ children, email, ...rest  }) {
  console.log("sadsadsadsa",children, email ,rest )
  return (
    <Route
      render={
        ({ location }) => (
          (email) ? ( // all page
            // rest.path == '/login' ? children :  <Redirect to="/"></Redirect>
            children
          ) :( ! email &&
            // <Route exact path={'/login'}  component={Adminlogin} />
            <Redirect to="/login"></Redirect>
          )
         )
      }
      {...rest}

    />
  );
}

export default adminRoute;