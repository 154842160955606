import React,{useEffect, useState} from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import {FloatingLabel, Form,Button} from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import { Bar } from "react-chartjs-2";
import { useLocation } from "react-router-dom";
import { useHistory } from 'react-router-dom';
// import { TextField ,Checkbox , FormControl , FormControlLabel, FormGroup} from '@mui/material';
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { editadminhook } from '../../../Hooks/adminHook';
const FormView = () =>{
    // const navigate = useNavigate();
    const {push} = useHistory();
    const location=useLocation();
    const [name , setName] = useState('');
    const [email , setEmail] = useState('');
    const [role , setRole] = useState(location?.state?.state?.level);
    const [status , setStatus] = useState(location?.state?.state?.status);
    const [password , setPassword] = useState('');
    const [newpassword , setNewpassword] = useState('')
    const [iscms , setIscms] = useState(location?.state?.state?.isCMS);
    const [iscrm , setIscrm] = useState(location?.state?.state?.isCRM);
    const [ismanager , setIsmanager] = useState(location?.state?.state?.isManager);
    const [isreport , setIsreport] = useState(location?.state?.state?.isReport);
    const [issettings , setIssettings] = useState(location?.state?.state?.isSettings);
    useEffect(()=> {
        console.log("location.state" , location?.state , status);
        setName(location?.state?.state?.name);
        setEmail(location?.state?.state?.email);
        setPassword(location?.state?.state?.password);
    }, []);

    useEffect(()=>{
        document.getElementById("manageadmin").classList.add("active");
        if(window.innerWidth>767)
        {
            // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
            if(document.querySelectorAll(".themescroll li a.active").length>0)
            {           
                 var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
          console.log(pagelinkofset,"pagelinkofset")
                 document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);

            }

        }
        else
        {
            // alert(2)
            if(document.querySelectorAll(".themescroll li a.active").length>0)
            {
                // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
                if(document.querySelectorAll(".themescroll li a.active").length>0)
                {  
                var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
                document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);
                }
            }
            
        }
            },[]);

    const handlesubmit = async() => {
        console.log("handle submit");
        if(name?.length>0 && role?.length>0 && status?.length>0){
            let payload = {
                id : location?.state?.state?._id,
                name : name,
                email : email,
                level : role,
                password : (newpassword?.length>0) ? newpassword : password,
                status : status,
                iscms : iscms,
                iscrm : iscrm,
                ismanager : ismanager,
                isreport : isreport,
                issettings : issettings
            };
            console.log("payload" , payload);
            await editadminhook(payload).then((result)=> {
                console.log("handle submit result" , result);
                
                    push("/manageadmin");
               
               
            })
        }
        else{
            window.alert("Fill all the fields!")
        }
        
    }

    const handlecancel = async() => {
        console.log("handle cancel");
        push("/manageadmin");
    }
    return(
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont'>
                <h4 className='adminblue my-4'>Edit Admin User</h4>
                <div className='admin-forms mb-4 '>
                    <div className='shadowbox p-4 br-10'>
                    <div className='row'>
                            <div className='col-md-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Name *"
                                className="" >
                            <Form.Control placeholder="demo"  type="text" defaultValue = {name} onChange={e => setName(e.target.value)}/>
                            {/* <TextField value = "aaaa"/> */}
                            </FloatingLabel>
                         </div>
                            <div className='col-md-6 mb-4'> 
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Email *"
                                className="" >
                                <Form.Control placeholder="demo" type="email" value = {email} readOnly={true} />
                            </FloatingLabel> </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-6 mb-4'>
                            <FloatingLabel controlId="floatingSelect" label="Role *" className=''>
                            <Form.Select aria-label="Floating label select example" value = {role} onChange={e => {
                                console.log("role value" , e.target.value);
                                setRole(e.target.value)}}>
                                <option value = "">Select Role</option>
                                <option value="Admin">Admin</option>
                                <option value="SubAdmin">Sub Admin</option>
                                <option value="Accountant">Accountant</option>
                            </Form.Select>
                            </FloatingLabel> </div>
                            <div className='col-md-6 mb-4'> 
                            <FloatingLabel controlId="floatingSelect" label="Status *" className=''>
                            <Form.Select aria-label="Floating label select example" value = {status} onChange={e => setStatus(e.target.value)}>
                                <option value = "">Select status</option>
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                                {/* <option value="3">Three</option> */}
                            </Form.Select>
                            </FloatingLabel></div>
                        </div>
                     
                        <div className='row'>
                            <div className='col-md-6 mb-3'>
                            <div>
                                 <FloatingLabel
                                controlId="floatingInput"
                                label="Password"
                                className="" >
                                <Form.Control placeholder="demo" type="password"  onChange={e => setNewpassword(e.target.value)} />
                            </FloatingLabel>
                            <span className='text-danger f-14'>Enter new password only. Leave blank to use existing password.</span>
                            </div>
                            </div>
                        </div>
                      <div className='row'>
                         <div className='col-md-12'>
                         <div className=''>
                        <h5>Roles and Responsibilities</h5>
                        <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Is CMS</p>
                        </label>
                        <input class="form-check-input"  type="checkbox" value="isCms" id="isCms" checked = {iscms } onChange = {e =>  setIscms(e.target.checked)}/>
                        </div>   
                        <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Is CRM</p>
                        </label>
                        <input class="form-check-input"  type="checkbox" value="isCrm" id="isCrm" checked = {iscrm } onChange = {e =>  setIscrm(e.target.checked)}/>
                        </div> 
                        <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Is Manager</p>
                        </label>
                        <input class="form-check-input"  type="checkbox" value="isManager" id="isManager" checked = {ismanager} onChange = {e =>  setIsmanager(e.target.checked)}/>
                        </div> 
                        <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Is Report</p>
                        </label>
                        <input class="form-check-input"  type="checkbox" value="isReport" id="isReport" checked = {isreport} onChange = {e =>  setIsreport(e.target.checked)}/>
                        </div> 
                        <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Is Settings</p>
                        </label>
                        <input class="form-check-input"  type="checkbox" value="isSettings" id="isSettings" checked = {issettings} onChange = {e =>  setIssettings(e.target.checked) }/>
                        </div> 
                        </div>
                         </div>
                      </div>
                     
                       


                        <div className='px-4 text-end mt-3 btn_view_oneline' >
                            <button className='btn btn-theme me-3' onClick={handlesubmit}>Submit</button>
                            <Button variant="outline-dark" className='f-12 fw-600 outlinebtn' onClick={handlecancel}>Cancel</Button>
                        </div>
                    </div>
                
                </div>

                
               </div> 
            </div>
        </div>
    )
}

export default FormView