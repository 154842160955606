import React,{useEffect, useState} from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import {FloatingLabel, Form,Button} from 'react-bootstrap'
import { addfeehook, updatefeehook } from '../../../Hooks/adminHook';
import { useHistory, useLocation } from 'react-router-dom';
const FormView = () =>{
    const {push} = useHistory();
    const location = useLocation();
  const [name , setName] = useState('');
  const [percentage , setPercentage] = useState('');
  const [status , setStatus] = useState('');


  useEffect(()=>{
    document.getElementById("managefee").classList.add("active");
    if(window.innerWidth>767)
    {
        // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
        if(document.querySelectorAll(".themescroll li a.active").length>0)
        {           
             var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
      console.log(pagelinkofset,"pagelinkofset")
             document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);

        }

    }
    else
    {
        // alert(2)
        if(document.querySelectorAll(".themescroll li a.active").length>0)
        {
            // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
            if(document.querySelectorAll(".themescroll li a.active").length>0)
            {  
            var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
            document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);
            }
        }
        
    }
        },[])
  
  

  useEffect(()=>{
    console.log("location data" , location?.state?.state);
    setName(location?.state?.state?.name);
    setStatus(location?.state?.state?.status);
    setPercentage(location?.state?.state?.percentage);
  },[])
  const handlesubmit = async()=>{
    let payload = {
        name : name,
        percentage : percentage,
        status : status,
        id : location?.state?.state?._id
    };
    console.log("payload" , payload);
    if(name?.length >0 && status?.length > 0)
    {
        if(percentage > 0 && percentage < 100){
            await updatefeehook(payload).then((result)=>{
                console.log("add fee hook result" , result);
                push("/manage-fee");
                window.location.reload();
            })
        }
        else {
            window.alert("Percentage value is must 1 to 100")
        }
    }
    else {
        window.alert("Fill all the fields");
    }
  }
  

    return(
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont'>
                <h4 className='adminblue my-4'>Edit Fee</h4>
                <div className='admin-forms mb-4 '>
                    <div className='shadowbox p-4 br-10'>
                        <div className='row'>
                            <div className="col-sm-6 mb-4">
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Fee Name *">
                            <Form.Control placeholder="demo" type="email" defaultValue={name} onChange={(e)=>{setName(e?.target?.value)}}/>
                            </FloatingLabel></div>
                            <div className="col-sm-6 mb-4">
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Fee In Percentage *" >
                                <Form.Control placeholder="demo" type="email" defaultValue={percentage} onChange={(e)=>{setPercentage(e?.target?.value)}}/>
                            </FloatingLabel>
                            </div>
                        </div>

                        <div className='row' >
                        <div className="col-sm-6 mb-4">
                        <FloatingLabel controlId="floatingSelect" label="Status *" >
                            <Form.Select aria-label="Floating label select example" value = {status} onChange={(e)=>{setStatus(e?.target?.value)}}>
                                <option value = "">Open this select menu</option>
                                <option >Active</option>
                                <option >Inactive</option>
                               
                            </Form.Select>
                            </FloatingLabel>
                            </div>
                          
                       </div>
                        
                        
                        <div className='px-4 text-end mt-3 btn_view_oneline' >
                            <button className='btn btn-theme me-3' onClick={handlesubmit}>Submit</button>
                            <Button variant="outline-dark" className='f-12 fw-600 outlinebtn' onClick={()=>{push("/manage-fee")}}>Cancel</Button>
                        </div>
                    </div>
                
                </div>

                
               </div> 
            </div>
        </div>
    )
}

export default FormView