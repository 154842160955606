import React,{useState, useEffect} from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import {FloatingLabel, Form,Button} from 'react-bootstrap'
import { addfeehook } from '../../../Hooks/adminHook';
import { useHistory } from 'react-router-dom';
const FormView = () =>{
    const {push} = useHistory();
  const [name , setName] = useState('');
  const [percentage , setPercentage] = useState('');

  useEffect(()=>{
    document.getElementById("managefee").classList.add("active");
    if(window.innerWidth>767)
    {
        // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
        if(document.querySelectorAll(".themescroll li a.active").length>0)
        {           
             var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
      console.log(pagelinkofset,"pagelinkofset")
             document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);

        }

    }
    else
    {
        // alert(2)
        if(document.querySelectorAll(".themescroll li a.active").length>0)
        {
            // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
            if(document.querySelectorAll(".themescroll li a.active").length>0)
            {  
            var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
            document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);
            }
        }
        
    }
        },[])
  
  const handlesubmit = async()=>{
    let payload = {
        name : name,
        percentage : percentage
    };
    console.log("payload" , payload);
    if(name?.length >0 )
    {
        if( percentage > 0 && percentage < 100){
            await addfeehook(payload).then((result)=>{
                console.log("add fee hook result" , result);
                push("/manage-fee");
            })
        }
        else {
            window.alert("Percentage value is must 1 to 100")
        }
    }
    else {
        window.alert("Fill all the fields");
    }
  }
  

    return(
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont'>
                <h4 className='adminblue my-4'>Add Fee</h4>
                <div className='admin-forms mb-4 '>
                    <div className='shadowbox p-4 br-10'>
                        <div className='row'>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Fee Name *"
                                className="mb-3" >
                            <Form.Control type="text" placeholder='emeil' onChange={(e)=>{setName(e?.target?.value)}}/>
                            </FloatingLabel>
                            </div>
                            <div className='col-sm-6 mb-4'>
                            
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Fee In Percentage *"
                                className="mb-3" >
                                <Form.Control type="text"  placeholder='emeil' onChange={(e)=>{setPercentage(e?.target?.value)}}/>
                            </FloatingLabel>
                            </div>
                        </div>
                        
                        
                        <div className='px-4 text-end mt-3 btn_view_oneline' >
                            <button className='btn btn-theme me-3' onClick={handlesubmit}>Submit</button>
                            <Button variant="outline-dark" className='f-12 fw-600 outlinebtn' onClick={()=>{push("/manage-fee")}}>Cancel</Button>
                        </div>
                    </div>
                
                </div>

                
               </div> 
            </div>
        </div>
    )
}

export default FormView