

import React,{useState} from 'react';
import {Dropdown,Button} from 'react-bootstrap'
import { useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import logo from '../../Assets/images/logo.png'
import prof from '../../Assets/images/prof1.jpg'
import { adminLogout } from '../../Hooks/adminHook';

const AdminHeader = () =>{
    // const navigate = useNavigate();
    const cookies = new Cookies();
    const {push} = useHistory();
    const sidetoggle = () =>{
        if(document.getElementById("dashboard").classList.contains("showsidebar")){
            document.getElementById("dashboard").classList.remove("showsidebar")
            document.getElementById("dashboard").classList.add("hidesidebar")
        }
        else if(document.getElementById("dashboard").classList.contains("hidesidebar")){
            document.getElementById("dashboard").classList.remove("hidesidebar")
            document.getElementById("dashboard").classList.add("showsidebar")

        }
    }
    const logout = async() => {
        console.log('enter thisssss')
        cookies.remove("admin-email");
        cookies.remove("admin-Datas");
        window.location.reload();
        //  await adminLogout();
            push("/login");
       
    }
    return(
        <>
            <div className='d-flex jc-between w-full'>
               <div className='d-flex'><Button onClick={() => sidetoggle()}  variant="link" className='me-2 text-dark mobiletog ps-0'><span className='fa fa-bars'></span></Button> <img src={logo} className="me-auto mobileadminlogo"/></div>
                <Dropdown className="admin-prof">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <img src={prof} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item  onClick={logout}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    )
}


export default AdminHeader