import React,{useState , Fragment, useEffect} from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import {FloatingLabel, Form,Button} from 'react-bootstrap'
// import DataTable from 'react-data-table-component';
import { Bar } from "react-chartjs-2";
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import DataTable from '@ashvin27/react-datatable';
import { getwithdrawhook } from '../../../Hooks/adminHook';
import Payment from "../paymentmodal";
import { useHistory } from 'react-router-dom';


const TableView = () =>{
    const [withdraw , setWithdraw] = useState([]);
    const [pay , setPay] = useState(false);
    const [hostdata , setHostdata] = useState({});
    const {push} = useHistory();
    const [totalrecordcounts, setTotalRecordCounts] = useState(0)
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    useEffect(()=>{
        async function fetchdata(){
            let reqData = {
                page : page,
                limit : limit
            }
            await getwithdrawhook(reqData).then((result)=>{
                console.log("get withdraw result" , result?.data?.data?.record);
                result?.data?.data?.record?.map((data , i) => data.id = i+1);
                setWithdraw(result?.data?.data?.record);
                setTotalRecordCounts(result?.data?.data?.count);
            })
        }
        fetchdata();
    },[])
    const columns = [
        {
            key: "id",
            text: "S.no",
            className: "name",
            align: "left",
            // sortable: true,
            cell : (record, ind) => {
                return (<p>{ind + 1}</p>)
            }
        },
        {
            key: "hostemail",
            text: "Email",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "hostearning",
            text: "Earning",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "hostavaliablebalance",
            text: "Avaliable Amount",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "requestamount",
            text: "Request Amount",
            className: "name",
            align: "left",
            sortable: true,
        },
        
        {
            key: "status",
            text: "Status",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "paymentstatus",
            text: "Payment Status",
            className: "name",
            align: "left",
            // sortable: true,
        },
        
        {
            key: "action",
            text: "Payout",
            className: "action",
            width: 100,
            align: "left",
            // sortable: true,
            cell: record => {
                return (
                    <Fragment>
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={() => push("/view-withdraw" , {state :record})}
                            style={{ marginRight: '5px' }}>View
                            {/* <i class="fa fa-eye mr-0" ></i> */}
                        </button>
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={() => {setPay(true) ; setHostdata(record)}}
                            style={{ marginRight: '5px' }}
                             disabled = {record?.requestamount > record?.hostavaliablebalance || record?.status == "Closed"}>Pay
                            {/* <i className="fa fa-edit mr-0"></i> */}
                        </button>
                        {/* <button
                            className="btn btn-danger btn-sm"
                            onClick={() => uploadImage(record)}>
                            <i className="fa fa-edit mr-0"></i>
                        </button> */}
                        {/* <button
                            className="btn btn-primary btn-sm"
                            onClick={() => WhiteList(record)}
                            style={{ marginRight: '5px' }}>
                            <i className="fa fa-edit mr-0"></i>
                        </button> */}
                    </Fragment>
                );
            }
        }
]


const config = {
    page_size: 10,
    length_menu: [ 10, 20, 50 ],
    filename: "Users",
    no_data_text: 'No user found!',
    button: {
        excel: true,
        print: true,
        csv: true
    },className: "table",
    language: {
        length_menu: "Show _MENU_",
        filter: "Filter User",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
            first:"<<",
            previous: "<",
            next: ">",
            last: ">>"
        }
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
};
const data = [
        {
            id:1,
            reserveid:"1002",
            Roomname:"A Unique Studio",
            username:"David" ,
            emailid:"example@gmail.com",
            userdatafrom:"16.09.2022",
            userdatato:"22.09.2022",
            iddocuments:"file.pdf",
            staus:"approved"
        },
        {
            id:2,
            reserveid:"1002",
            Roomname:"A Unique Studio",
            username:"David" ,
            emailid:"example@gmail.com",
            userdatafrom:"16.09.2022",
            userdatato:"22.09.2022",
            iddocuments:"file.pdf",
            staus:"approved"
        },
        {
            id:3,
            reserveid:"1002",
            Roomname:"A Unique Studio",
            username:"David" ,
            emailid:"example@gmail.com",
            userdatafrom:"16.09.2022",
            userdatato:"22.09.2022",
            iddocuments:"file.pdf",
            staus:"approved"
        },
        {
            id:4,
            reserveid:"1002",
            Roomname:"A Unique Studio",
            username:"David" ,
            emailid:"example@gmail.com",
            userdatafrom:"16.09.2022",
            userdatato:"22.09.2022",
            iddocuments:"file.pdf",
            staus:"approved"
        }
     
    ]
  

const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun"];

// const datas = {
//   labels: labels,
//   datasets: [
//     {
//       label: "My First dataset",
//       backgroundColor: "rgb(255, 99, 132)",
//       borderColor: "rgb(255, 99, 132)",
//       data: [0, 10, 5, 2, 20, 30, 45],
//     },
//   ],
// };

const pageChange = async (index) => {

    try {
        let reqData = {
            page: index.page_number,
            limit : index.page_size,
            search: index.filter_value
        }
        await getwithdrawhook(reqData).then((result) => {
            console.log("get all dispute result" , result , result?.data?.data?.record[0]?.attachment?.path);
            result?.data?.data?.record?.map((data,i) => {data.serial = i+1});
            setWithdraw(result?.data?.data?.record);
        })
        console.log('index_data', index)
    } catch (err) {
        console.log("pageChange_err", err)
    }
} 



    return(
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               {pay && <Payment hostdata ={hostdata}  onDismiss = {()=>setPay(false)}></Payment>}
               <div className='cont'>
                <h4 className='adminblue my-4'>Manage Withdraw</h4>
                
                <div className='tablesec shadowbox px-3 mt-3 br-10 mb-5'>
                    <h6 className='adminblue pt-4 me-2' ><span className='fa fa-book'></span> View Withdraw</h6>
                    {/* <DataTable pagination columns={columns} records={data} config = {config} className={config.className}/> */}
                    <DataTable striped responsive
                        config={config}
                        className={config.className}
                        records={withdraw}
                        columns={columns}
                        dynamic={true}
                        total_record={totalrecordcounts}
                        onChange={pageChange}

                    />
               </div>
             
              
                {/* <div className='tablesec shadowbox px-3 mt-3 br-10 mb-5'>
                    <h6 className='adminblue pt-4 me-2' ><span className='fa fa-book'></span> View User</h6>
                    <DataTable pagination columns={columns} data={data} />
                    <DataTable striped responsive
                        config={config}
                        className={config.className}
                        records={withdraw}
                        columns={columns}

                    />
               </div> */}
               </div> 
            </div>
        </div>
    )
}

export default TableView