import React,{useEffect, useState} from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import {FloatingLabel, Form,Button, Tabs, Tab} from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import img1 from '../../../Assets/images/prof2.png'
import { Bar } from "react-chartjs-2";
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useHistory, useLocation } from 'react-router-dom';
import { approvehostingexperiencehook, reactivatehostingexperiencehook, rejecthostingexperiencehook } from '../../../Hooks/adminHook';
import { API_URL } from '../../../config/env';
const FormView = () =>{
  
  const location = useLocation();
  const {push} = useHistory();
  const [experience , setExperience] = useState({});
  const [provision , setProvision] = useState([]);
  const [provide , setProvide] = useState();
  const [items , setItems] = useState([]);
  const [bookinginfo , setBookinginfo] = useState([]);
  const [photos , setPhotos] = useState([]);

  useEffect(()=>{
    document.getElementById("managehostingexperience").classList.add("active");
    if(window.innerWidth>767)
    {
        // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
        if(document.querySelectorAll(".themescroll li a.active").length>0)
        {           
             var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
      console.log(pagelinkofset,"pagelinkofset")
             document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);

        }

    }
    else
    {
        // alert(2)
        if(document.querySelectorAll(".themescroll li a.active").length>0)
        {
            // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
            if(document.querySelectorAll(".themescroll li a.active").length>0)
            {  
            var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
            document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);
            }
        }
        
    }
        },[]);


  useEffect(()=>{
    console.log("location data" , location?.state?.state , location?.state?.state?.whatYouWillProvide[0]?.nothingToProvide);
    setExperience(location?.state?.state);
    setProvision(location?.state?.state?.whatYouWillProvide[0]?.provisions);
    setProvide(location?.state?.state?.whatYouWillProvide[0]?.nothingToProvide);
    // location?.state?.state?.packingList?.items?.map((data , i)=>data.serial= i+1)
    setItems(location?.state?.state?.packingList?.items)
    setBookinginfo(location?.state?.state?.bookingInfo);
    setPhotos(location?.state?.state?.photos);
  },[])

  const column1 = [
   
    {
        name:"Item",
        reorder: true,
        cell: (data) =>(data?.item)
    },
    {
        name:"Description",
        reorder: true,
        cell: (data) =>(data?.description)
    }
]

const column2 = [
   
    {
        name:"Items",
        reorder: true,
        cell: (data) =>(data)
    }
]

const column5 = [
   
    {
        name:"Booking Id",
        reorder: true,
        cell: (data) =>(data?.bookingId)
    },
    {
        name:"Checkin Date",
        reorder: true,
        cell: (data) =>(data?.checkInDate?.slice(0,10))
    },
    {
        name:"Checkin Date",
        reorder: true,
        cell: (data) =>(data?.checkOutDate?.slice(0,10))
    },
    {
        name:"Email",
        reorder: true,
        cell: (data) =>(data.email)
    },
    {
        name:"Booking Date",
        reorder: true,
        cell: (data) =>(data?.time?.slice(0,10))
    }
]
const column3 = [
    
    {
        name:"Name",
        reorder: true,
        cell: (data) =>(<div className='d-flex upimages my-1 px-4'><a  href = {API_URL+experience?.photosPath+"/"+data.name} target="_blank">
        <img src={API_URL+experience?.photosPath+"/"+data.name} onclick="window.open(this.src, '_blank');" />
        </a></div>)
    },
    {
        name:"Status",
        reorder: true,
        cell: (data) =>(data?.status?"Active" : "Inactive")
    }
]

const handleapprove = async()=> {
    await approvehostingexperiencehook(experience?._id).then((result) => {
        console.log("on approve data" , result);
        window.alert("Experience approved sucessfully !");
        push("/manage-hosting-experience");
        window.location.reload();
    })
}

const handlereject = async()=> {
    await rejecthostingexperiencehook(experience?._id).then((result) => {
        console.log("on reject data" , result);
        window.alert("Experience rejected sucessfully !");
        push("/manage-hosting-experience");
        window.location.reload();
    })
}
const activate = async() => {
    console.log("deactivate");
    await reactivatehostingexperiencehook(experience?._id).then((result) => {
        console.log("activate room hook" , result);
        window.alert("Experience ReActivate sucessfully !");
        push("/manage-hosting-experience");
    })
}

    return(
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont'>
                <h5 className='adminblue my-4'>Hosting Experience</h5>
                <div className='admin-forms mb-4 '>
                    <div className='shadowbox p-4 br-10'>

                    <Tabs
      defaultActiveKey="home"
      transition={false}
      id="noanim-tab-example"
      className="mb-3"
    >
      <Tab eventKey="home" title="Hosting Experience">
      <div className='row'>
                        <div className="col-sm-6 mb-4" >
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Email"
                                 >
                            <Form.Control placeholder="demo" type="text" value={experience?.email} readOnly={true} />
                            </FloatingLabel>
                            </div>
                        <div className="col-sm-6 mb-4" >
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Country"
                                 >
                                <Form.Control placeholder="demo" type="text" value={experience?.country} readOnly={true} />
                            </FloatingLabel>
                            </div>
                        </div>
                        <div className='row'>
                        <div className="col-sm-6 mb-4" >
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Language"
                                 >
                            <Form.Control placeholder="demo" type="text" value ={experience?.language} readOnly={true} />
                            </FloatingLabel>
                            </div>
                        <div className="col-sm-6 mb-4" >
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Title"
                                 >
                                <Form.Control placeholder="demo" type="text" value={experience?.title} readOnly={true}/>
                            </FloatingLabel>
                            </div>
                        </div>

                        <div className='row'>
                        <div className="col-sm-6 mb-4" >
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Start Date"
                                 >
                            <Form.Control placeholder="demo" type="text" value ={experience?.startTime?.slice(0,10)} readOnly={true} />
                            </FloatingLabel>
                            </div>
                        <div className="col-sm-6 mb-4" >
                            <FloatingLabel
                                controlId="floatingInput"
                                label="End Date"
                                 >
                                <Form.Control placeholder="demo" type="text" value={experience?.endTime?.slice(0,10)} readOnly={true}/>
                            </FloatingLabel>
                            </div>
                        </div>

                        <div className='row'>
                        <div className="col-sm-6 mb-4" >
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Maximum Guest"
                                 >
                            <Form.Control placeholder="demo" type="text" value ={experience?.maxGuest} readOnly={true} />
                            </FloatingLabel>
                            </div>
                        <div className="col-sm-6 mb-4" >
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Price Per Guest"
                                 >
                                <Form.Control placeholder="demo" type="text" value={experience?.pricePerGuest} readOnly={true}/>
                            </FloatingLabel>
                            </div>
                        </div>

                        <div className='row'>
                        <div className="col-sm-6 mb-4" >
                            <FloatingLabel
                                controlId="floatingInput"
                                label="First Name"
                                 >
                            <Form.Control placeholder="demo" type="text" value ={experience?.firstName} readOnly={true} />
                            </FloatingLabel>
                            </div>
                        <div className="col-sm-6 mb-4" >
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Last Name"
                                 >
                                <Form.Control placeholder="demo" type="text" value={experience?.lastName} readOnly={true}/>
                            </FloatingLabel>
                            </div>
                        </div>

                        <div className='row'>
                        <div className="col-sm-6 mb-4" >
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Is Approved"
                                 >
                            <Form.Control placeholder="demo" type="text" value ={experience?.isApproved} readOnly={true} />
                            </FloatingLabel>
                            </div>
                        <div className="col-sm-6 mb-4" >
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Is Rejected"
                                 >
                                <Form.Control placeholder="demo" type="text" value={experience?.isRejected} readOnly={true}/>
                            </FloatingLabel>
                            </div>
                        </div>

                        <div className='row'>
                        <div className="col-sm-6 mb-4" >
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Instant Booking"
                                 >
                            <Form.Control placeholder="demo" type="text" value ={experience?.instantBooking} readOnly={true} />
                            </FloatingLabel>
                            </div>
                        <div className="col-sm-6 mb-4" >
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Is Deactivate"
                                 >
                                <Form.Control placeholder="demo" type="text" value={experience?.isDeactivate} readOnly={true}/>
                            </FloatingLabel>
                            </div>
                        </div>

                        <div className='row'>
                        <div className="col-sm-6 mb-4" >
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Is Listed"
                                 >
                            <Form.Control placeholder="demo" type="text" value ={experience?.isListed} readOnly={true} />
                            </FloatingLabel>
                            </div>
                        <div className="col-sm-6 mb-4" >
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Wishlist Count"
                                 >
                                <Form.Control placeholder="demo" type="text" value={experience?.wishlistcount} readOnly={true}/>
                            </FloatingLabel>
                            </div>
                        </div>
                        <div className='row'>
                        <div className="col-sm-6 mb-4" >
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Promoted"
                                 >
                            <Form.Control placeholder="demo" type="text" value ={experience?.thispromoted} readOnly={true} />
                            </FloatingLabel>
                            </div>
                        <div className="col-sm-6 mb-4" >
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Tagline"
                                 >
                            <Form.Control placeholder="demo" as="textarea"  value = {experience?.tagline} readOnly={true}/>
                            </FloatingLabel>
                            </div>
                        </div>

                        <div className='row'>
                        <div className="col-sm-6 mb-4" >
                            <FloatingLabel
                                controlId="floatingInput"
                                label="What You Will Do"
                                 >
                            <Form.Control placeholder="demo" as ="textarea" rows = {2} value ={experience?.whatYouWillDo} readOnly={true} />
                            </FloatingLabel>
                            </div>
                        <div className="col-sm-6 mb-4" >
                            <FloatingLabel
                                controlId="floatingInput"
                                label="What You Will Be"
                                 >
                            <Form.Control placeholder="demo" as="textarea"  value = {experience?.whereYouWillBe} readOnly={true}/>
                            </FloatingLabel>
                            </div>
                        </div>
                        <div className='row'>
                        <div className="col-sm-6 mb-4" >
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Bio"
                                 >
                            <Form.Control placeholder="demo" as ="textarea" rows = {2} value ={experience?.bio} readOnly={true} />
                            </FloatingLabel>
                            </div>
                        <div className="col-sm-6 mb-4" >
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Status"
                                 >
                            <Form.Control placeholder="demo" as ="textarea" rows = {2} value ={experience?.status} readOnly={true} />
                            </FloatingLabel>
                            </div>
                           
                        </div>
      </Tab>
      <Tab eventKey="profile" title="Location & Provision">
         <div className='row'>
            <div className='col-md-6'>
            <h5 className='adminblue'>Provisions : </h5>
            <DataTable  columns={column2} data={items} />
            {/* <p className='adminblue mt-3'>item1</p>
                <p>Drinks</p>
                <p className='adminblue mt-3'>Description</p>
                <p>Tea</p> */}

                <div className='row mt-4'>
                    <h6>Nothing To Provide : <span className='text-danger'>  {provide?.toString()}</span></h6>
                    {console.log(" provide" , provide)}
                </div>
            <div>
                <h5 className='adminblue mt-4'>what Guest Should Know </h5>
                {/* <hr className='themehr mt-0' /> */}
                <div className='d-flex align-items-center gap-2'>
                                <h6 className='mb-0'>Things :</h6>{console.log(" provide" , provide)}
                                <p>{experience?.whatGuestShouldKnow?.things}</p>
                            </div>
                    </div>

                    <div>
                        <h5 className='adminblue my-4'>Who can Attend </h5>
                        
                        <div className='d-flex align-items-center gap-2 mb-3'>
                                <h6 className='mb-0 adminblue'>Alcohol : </h6>{console.log(" provide" , provide)}
                                <p className='mb-0'>{experience?.whoCanAttend?.alcohol?.toString()}</p>
                            </div>
                            <div className='d-flex align-items-center gap-2 mb-3'>
                                <h6 className='mb-0 adminblue'>Guidance  : </h6>{console.log(" provide" , provide)}
                                <p className='mb-0'>{experience?.whoCanAttend?.guidance?.toString()}</p>
                            </div>

                            <div className='d-flex align-items-center gap-2 mb-3'>
                                <h6 className='mb-0 adminblue'>Age  : </h6>{console.log(" provide" , provide)}
                                <p className='mb-0'>{experience?.whoCanAttend?.age?.toString()}</p>
                            </div>
                            <div className='d-flex align-items-center gap-2 mb-3'>
                                <h6 className='mb-0 adminblue'>Special Certificate  : </h6>{console.log(" provide" , provide)}
                                <p className='mb-0'>{experience?.whoCanAttend?.specialCertificate?.toString()}</p>
                            </div>
                            <div className='d-flex align-items-center gap-2 mb-3'>
                                <h6 className='mb-0 adminblue'>Additional Requirements  : </h6>{console.log(" provide" , provide)}
                                <p className='mb-0'>{experience?.whoCanAttend?.additionalRequirements?.toString()}</p>
                            </div>
                    </div>

            </div>
            <div className='col-md-6'>
            <div>
                        <h5 className='adminblue mb-4'>Location :</h5>
                            <div className='d-flex align-items-center gap-2 mb-3'>
                                <h6 className='mb-0 adminblue'>Location Name : </h6>
                                <p className='mb-0'>{experience?.meetingLocation?.area}</p>
                            </div>
                            <div className='d-flex align-items-center gap-2 mb-3'>
                                <h6 className='mb-0 adminblue'>Country : </h6>
                                <p className='mb-0'>{experience?.meetingLocation?.countrycode}</p>
                            </div>
                            <div className='d-flex align-items-center gap-2 mb-3'>
                                <h6 className='mb-0 adminblue'>Street Address : </h6>
                                <p className='mb-0'>{experience?.meetingLocation?.street}</p>
                            </div>
                            <div className='d-flex align-items-center gap-2 mb-3'>
                                <h6 className='mb-0 adminblue'>City : </h6>
                                <p className='mb-0'>{experience?.meetingLocation?.city}</p>
                            </div>
                            {/* <div className='d-flex align-items-center gap-2 mb-3'>
                                <h6 className='mb-0 adminblue'>Suite : </h6>
                                <p className='mb-0'>{experience?.meetingLocation?.suite}</p>
                            </div> */}
                            <div className='d-flex align-items-center gap-2 mb-3'>
                                <h6 className='mb-0 adminblue'>State : </h6>
                                <p className='mb-0'>{experience?.meetingLocation?.state}</p>
                            </div>
                    </div>

                    
                    
            </div>
         </div>
      </Tab>
      <Tab eventKey="contact" title="Prep time & Packing" >
         <div className='row'>
            <div className='col-lg-6'>
            <div className='mb-4'>
                        <h5 className='adminblue mb-4'>Preparation Time </h5>
                        
                        <div className='d-flex align-items-center gap-2 mb-3'>
                                <h6 className='mb-0 adminblue'>Preparation Days :</h6>{console.log(" provide" , provide)}
                                <p className='mb-0'>{experience?.preparationTime?.preparationDays}</p>
                            </div>
                            <div className='d-flex align-items-center gap-2 mb-3'>
                                <h6 className='mb-0 adminblue'>Last Minute :</h6>{console.log(" provide----------" , provide,experience)}
                                <p className='mb-0'>{experience?.preparationTime?.lastMinute?.toString()}</p>
                            </div>
                            <div className='d-flex align-items-center gap-2 mb-3'>
                                <h6 className='mb-0 adminblue'>Cut Off :</h6>{console.log(" provide" , provide)}
                                <p className='mb-0'>{experience?.preparationTime?.cutoff}</p>
                            </div>
                    </div>

                    <div className='mb-4'>
                        <h5 className='adminblue'>Packing List : </h5>
                            <div className='row'>
                            <DataTable  columns={column2} data={items} />

                            </div>
                            <div className='d-flex align-items-center gap-2 mt-3'>
                                <h6 className='mb-0'>Nothing  : <span className='text-danger'>{location?.state?.state?.packingList?.nothing?.toString()}</span> </h6>
                                {console.log(" provide" , provide)}
                            </div>
                    </div>

                 

                   

                    <h5 className='adminblue my-4'>Booking Information :</h5>
                        <div className='row'>
                            
                       
                          <DataTable columns={column5} data={bookinginfo} />
                          </div>
                       
                    
                      

                        
                        
            </div>
            <div className='col-lg-6'>
            <div>
                        <h5 className='adminblue mb-4'>Terms and Conditions </h5>
                        
                        <div className='d-flex align-items-center gap-2 mb-3'>
                                <h6 className='mb-0 adminblue'>Quality Standards :</h6>{console.log(" provide" , provide)}
                                <p className='mb-0'>{experience?.termsAndConditions?.qualityStandards.toString()}</p>
                            </div>
                            <div className='d-flex align-items-center gap-2 mb-3'>
                                <h6 className='mb-0 adminblue'>Licensing :</h6>{console.log(" provide" , provide)}
                                <p className='mb-0'>{experience?.termsAndConditions?.licensing.toString()}</p>
                            </div>
                    </div>
            <h5 className='adminblue my-4'>Photos : </h5>
            <div>
                          <DataTable columns={column3} data={photos} />
                          </div>
                        
                        {/* <div className='row photos pb-3'>
                            <div className='col-md-6'>
                               <div className='row small'>
                                <div className='col-md-6 pb-3'><img src={img1}/></div>
                                <div className='col-md-6'><img src={img1}/></div> 
                                <div className='col-md-6'><img src={img1}/></div> 
                                <div className='col-md-6 '><img src={img1}/></div>
                                </div> 
                            </div>
                            <div className='col-md-6 large'> 
                             <div className='row'><img src={img1}/></div>
                            </div>
                            </div>
                            <div className='row photos'>
                            <div className='col-md-6 large'>
                                <div className='row'>
                                     <img src={img1}/>
                                </div>
                               
                                </div>
                            <div className='col-md-6'>
                               
                                <div className='row small'>
                                    <div className='col-md-6 pb-3'><img src={img1}/></div>
                                    <div className='col-md-6'><img src={img1}/></div> 
                                    <div className='col-md-6'><img src={img1}/></div> 
                                    <div className='col-md-6'><img src={img1}/></div>
                                    </div> 
                               
                                
                            </div>
                           
                            
                            
                        </div> */}
            </div>
         </div>
         <div className='row'>
         <div className='px-4 text-start mt-3' >
            {console.log("experience?.isApproved", experience, experience?.isApproved, experience?.isRejected, experience?.isDeactivate)}
                            <button className='btn btn-theme me-3' disabled={experience?.isApproved == true ? true : false } onClick={handleapprove}>Approve</button>
                            <button className='btn btn-theme me-3' disabled={experience?.isRejected == true ? true : false} onClick={handlereject}>Reject</button>
                            <button className='btn btn-theme me-3' disabled={experience?.isDeactivate == true ? false : true}  onClick={activate}>Reactivate</button>
                            <Button variant="outline-dark" className='f-12 fw-600 outlinebtn' onClick={()=>{push("/manage-hosting-experience")}}>Cancel</Button>
                        </div>

         </div>
      </Tab>
    </Tabs>




              

                   
                    


                   
                    </div>
                
                </div>

                
               </div> 
            </div>
        </div>
    )
}

export default FormView