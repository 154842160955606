import React,{useEffect, useState} from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import {FloatingLabel, Form,Button} from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import { Bar } from "react-chartjs-2";
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import validator from 'validator'
import { getsitesettinghook, updatesitesettinghook } from '../../../Hooks/adminHook';
import { API_URL } from '../../../config/env';
const FormView = () =>{
    let NumOnly = /^\d+$/

  const [sitename , setSitename] = useState('');
  const [headcode , setHeadcode] = useState('');
  const [logo , setLogo] = useState('');
  const [emaillogo , setEmaillogo] = useState('');
  const [favicon , setFavicon] = useState('');
  const [defaultcurrency , setDefaultcurrency] = useState('');
  const [paypalcurrency , setPaypalcurrency] = useState('');
  const [minimumprice , setMinimumprice] = useState();
  const [maximumprice , setMaximumprice] = useState();
  const [defaultlanguage , setDefaultlanguage] = useState('');
  const [fileuploaddriver , setFileuploaddriver] = useState('');
  const [maintenancemode , setMaintenancemode] = useState('');
  const [helppagecoverimage , setHelppagecoverimage] = useState('');
  const [homepagestayimage , setHomepagestayimage] = useState('');
  const [homepageexperienceimage , setHomepageexperienceimage] = useState('');
  const [mobilebannerimage , setMobilebannerimage] = useState('');
  const [mobilebannercontent , setMobilebannercontent] = useState('');
  const [sitedateformat , setSitedateformat] = useState('');
  const [version , setVersion] = useState('');
  const [copyrightyear , setCopyrightyear] = useState('');
  const [copyrighturl , setCopyrighturl] = useState('');
  const [copyrightname , setCopyrightname] =useState('');
  const [adminprefix , setAdminprefix] = useState('');
  const [customersupportnumber , setCustomersupportnumber] = useState('');
  const [facebooklogin , setFacebooklogin] = useState();
  const [googlelogin , setGooglelogin] = useState();
  const [applelogin , setApplelogin] = useState();
  const [recaptcha , setRecaptcha] = useState();
  const [covidverification , setCovidverification] = useState();
  const [otp , setOtp] = useState();
  const [path , setPath] = useState('');
  const [logoview , setLogoview] = useState('');
  const [emaillogoview , setEmaillogoview] = useState('');
  const [faviconview , setFaviconview] = useState('');
  const [helppagecoverimageview , setHelppagecoverimageview] = useState('');
  const [homepagestayimageview , setHomepagestayimageview] = useState('');
  const [homepageexperienceimageview , setHomepageexperienceimageview] = useState('');
  const [mobilebannerimageview , setMobilebannerimageview] = useState('');
  useEffect(()=>{
      async function fetchdata(){
          await getsitesettinghook().then((result) => {
              console.log("get site setting result" , result?.data?.data?.record[0]?.details);
              setSitename(result?.data?.data?.record[0]?.details?.sitename);
              setHeadcode(result?.data?.data?.record[0]?.details?.headcode);
              setLogo(result?.data?.data?.record[0]?.details?.logo);
              setEmaillogo(result?.data?.data?.record[0]?.details?.emaillogo);
              setFavicon(result?.data?.data?.record[0]?.details?.favicon);
              setDefaultcurrency(result?.data?.data?.record[0]?.details?.defaultcurrency);
              setPaypalcurrency(result?.data?.data?.record[0]?.details?.paypalcurrency);
              setMinimumprice(result?.data?.data?.record[0]?.details?.minimumprice);
              setMaximumprice(result?.data?.data?.record[0]?.details?.maximumprice);
              setDefaultlanguage(result?.data?.data?.record[0]?.details?.defaultlanguage);
              setFileuploaddriver(result?.data?.data?.record[0]?.details?.fileuploaddriver);
              setMaintenancemode(result?.data?.data?.record[0]?.details?.maintenancemode);
              setHelppagecoverimage(result?.data?.data?.record[0]?.details?.helppagecoverimage);
              setHomepagestayimage(result?.data?.data?.record[0]?.details?.homepagestayimage);
              setHomepageexperienceimage(result?.data?.data?.record[0]?.details?.homepageexperienceimage);
              setMobilebannerimage(result?.data?.data?.record[0]?.details?.mobilebannerimage);
              setMobilebannercontent(result?.data?.data?.record[0]?.details?.mobilebannercontent);
              setSitedateformat(result?.data?.data?.record[0]?.details?.sitedateformat);
              setVersion(result?.data?.data?.record[0]?.details?.version);
              setCopyrightyear(result?.data?.data?.record[0]?.details?.copyrightyear);
              setCopyrighturl(result?.data?.data?.record[0]?.details?.copyrighturl);
              setCopyrightname(result?.data?.data?.record[0]?.details?.copyrightname);
              setAdminprefix(result?.data?.data?.record[0]?.details?.adminprefix);
              setCustomersupportnumber(result?.data?.data?.record[0]?.details?.customersupportnumber);
              setFacebooklogin(result?.data?.data?.record[0]?.details?.facebooklogin);
              setGooglelogin(result?.data?.data?.record[0]?.details?.googlelogin);
              setApplelogin(result?.data?.data?.record[0]?.details?.applelogin);
              setRecaptcha(result?.data?.data?.record[0]?.details?.recaptcha);
              setCovidverification(result?.data?.data?.record[0]?.details?.covidverification);
              setOtp(result?.data?.data?.record[0]?.details?.otp);
              setPath(result?.data?.data?.record[0]?.path);
          })
      }
      fetchdata();
  },[])

  const filevalidate = (image)=>{
    var fileName = image?.name ? image?.name :image;
        var idxDot = fileName?.lastIndexOf(".") + 1;
        var extFile = fileName?.substr(idxDot, fileName.length).toLowerCase();
        if(extFile=="jpg" || extFile=="jpeg" || extFile=="png" || extFile=="webp"){
            return true;
        }else{
            return false;
        }
  }
  const handlesubmit = async()=>{
    console.log(sitename?.length , headcode?.length , logo?.length , emaillogo?.length , favicon?.length ,
        defaultcurrency?.length,paypalcurrency?.length,minimumprice?.length,maximumprice?.length,
        defaultlanguage?.length,fileuploaddriver?.length,maintenancemode?.length,helppagecoverimage?.length,
        homepagestayimage?.length,homepageexperienceimage?.length,mobilebannerimage?.length,mobilebannercontent?.length,
        sitedateformat?.length,version?.length,copyrightname?.length,copyrighturl?.length,copyrightyear?.length,
        adminprefix?.length,customersupportnumber?.length>0)
    if(sitename?.length > 0 && headcode?.length > 0 && logo &&
        defaultcurrency?.length>0&&paypalcurrency?.length>0&&minimumprice?.length>0&&maximumprice?.length>0&&
        defaultlanguage?.length>0&&fileuploaddriver?.length>0&&maintenancemode?.length>0&&
        mobilebannercontent?.length>0&&
        sitedateformat?.length>0&&version?.length>0&&copyrightname?.length>0&&copyrighturl?.length>0&&copyrightyear?.length>0&&
        adminprefix?.length>0&&customersupportnumber?.length>0)
    {
        if( !(/\D/.test(copyrightyear)) && !(/\D/.test(customersupportnumber)))
        {
            const re =/^\d+\.\d{0,2}$/;

            // const re = /^[-+]?[0-9]+\.[0-9]+$/;
            // const re = /^(\d*([.,](?=\d{3}))?\d+)+((?!\2)[.,]\d\d)?$/;
            // if(minimumprice?.match(re) || !(/\D/.test(minimumprice))){
                // if(maximumprice?.match(re) || !(/\D/.test(maximumprice))){
            // if(version?.match(re) || !(/\D/.test(version)) ){
                if(filevalidate(logo) && filevalidate(emaillogo) && filevalidate(favicon)&&
                    filevalidate(helppagecoverimage) && filevalidate(homepagestayimage) &&
                    filevalidate(homepageexperienceimage) && filevalidate(mobilebannerimage)){
                        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
                        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
                        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
                        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
                        '(\\#[-a-z\\d_]*)?$','i');
                        if(validator.isURL(copyrighturl)){
                            if(!isNaN(version) ){
                            let payload = {
                                sitename : sitename,
                                headcode : headcode,
                                logo : logo,
                                emaillogo : emaillogo,
                                favicon : favicon,
                                defaultcurrency : defaultcurrency,
                                paypalcurrency : paypalcurrency,
                                minimumprice : minimumprice,
                                maximumprice : maximumprice,
                                defaultlanguage : defaultlanguage,
                                fileuploaddriver : fileuploaddriver,
                                maintenancemode : maintenancemode,
                                helppagecoverimage : helppagecoverimage,
                                homepagestayimage : homepagestayimage,
                                homepageexperienceimage : homepageexperienceimage,
                                mobilebannerimage : mobilebannerimage,
                                mobilebannercontent : mobilebannercontent,
                                sitedateformat : sitedateformat,
                                version : version,
                                copyrightname : copyrightname,
                                copyrighturl : copyrighturl,
                                copyrightyear : copyrightyear,
                                adminprefix : adminprefix,
                                customersupportnumber : customersupportnumber,
                                facebooklogin : facebooklogin,
                                googlelogin : googlelogin,
                                applelogin : applelogin,
                                recaptcha : recaptcha,
                                covidverification : covidverification,
                                otp : otp
                            }
                            console.log("payload" , payload);
                            await updatesitesettinghook(payload).then((result)=>{
                                console.log("result" , result);
                                window.location.reload();
                            })
                        }
                        else{
                            window.alert("Fill the valid numeric value !..")
                        }
                        }
                        else{
                            window.alert("Invalid Url format !..");
                        }
                    }
                    else{
                        window.alert("Invalid file format !..");
                    }
                // }
                //     else{
                //         window.alert("Fill the valid numeric value");
                //     }
            // }
            // else{
            //     window.alert("Fill the valid numeric value");
            // }
            // }
            // else{
            //     window.alert("Fill the valid numeric value");
            // }
    }
    else{
        window.alert("Fill the valid numeric value");
    }
    }
    else{
        window.alert("Fill all the field!");
    }
  }
  const NumANdDotOnly = (data) => {
    console.log("data" , data)
    var data = data.toString();
    var str = data
      ? data.includes(".")
        ? data.split(".").length >= 3
          ? (data.split(".")[0] + "." + data.split(".")[1]).toString()
          : data
        : data
      : data;
      console.log("datas" , str);
      return str.toString().replace("/[^0-9\.]/g", "");
  };
    return(
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont'>
                <h4 className='adminblue my-4'>Site Settings</h4>
                <div className='admin-forms mb-4 '>
                    <div className='shadowbox p-4 br-10'>
                        <div className='row'>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                required="true"
                                label="Site Name*"
                                className="mb-3 " >
                            <Form.Control placeholder='demo'  type="text" required ={true} defaultValue={sitename} onChange = {(e)=>{setSitename(e?.target?.value)}} />
                            </FloatingLabel>
                          
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Add code to the < head >"
                                className="mb-3" >
                                <Form.Control placeholder='demo' type="text" defaultValue={headcode} onChange = {(e) => {setHeadcode(e?.target?.value)}} />
                            </FloatingLabel>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Minimum Price"
                                className="mb-3" >
                            <Form.Control placeholder='demo' type="text" value={minimumprice} onChange={(e) => {if (NumOnly.test(e?.target?.value) || e?.target?.value == "") {{setMinimumprice( e?.target?.value) }}}}  />
                            </FloatingLabel>
                        
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Maximum Price"
                                className="mb-3" >
                                    {/* onChange={(e) => {setMaximumprice(NumANdDotOnly(e?.target?.value))}} */}
                                <Form.Control placeholder='demo' type="text" value={maximumprice}  onChange={(e) => {if (NumOnly.test(e?.target?.value) || e?.target?.value == "") {{setMaximumprice( e?.target?.value) }}}}/>
                            </FloatingLabel>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Admin Prefix"
                                className="mb-3" >
                            <Form.Control placeholder='demo' type="text" defaultValue={adminprefix} onChange = {(e)=>{setAdminprefix(e?.target?.value)}} />
                            </FloatingLabel>
                           
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Mobile Banner Content"
                                className="mb-3" >
                                <Form.Control placeholder='demo' type="text" defaultValue={mobilebannercontent} onChange={(e)=>{setMobilebannercontent(e?.target?.value)}} />
                            </FloatingLabel>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Version"
                                className="mb-3" >
                            <Form.Control placeholder='demo' type="text" defaultValue={version} onChange ={(e)=>{setVersion(e?.target?.value)}} />
                            </FloatingLabel>
                         
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Copyright Year"
                                className="mb-3" >
                                <Form.Control placeholder='demo' type="text" defaultValue={copyrightyear} onChange={(e)=>{setCopyrightyear(e?.target?.value)}} />
                            </FloatingLabel>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Copyright Url"
                                className="mb-3" >
                            <Form.Control placeholder='demo' type="text" defaultValue={copyrighturl} onChange ={(e)=>{setCopyrighturl(e?.target?.value)}} />
                            </FloatingLabel>
                        
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Copyright Name"
                                className="mb-3" >
                                <Form.Control placeholder='demo' type="text" defaultValue={copyrightname} onChange={(e)=>{setCopyrightname(e?.target?.value)}} />
                            </FloatingLabel>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel controlId="floatingSelect" label="Paypal Currency" className=' mt-4'>
                            <Form.Select aria-label="Floating label select example" value={paypalcurrency} onChange={e=>{setPaypalcurrency(e?.target?.value)}}>
                                <option value ="">Select Paypal Currency</option>
                                <option >INR</option>
                                {/* <option value="2">Two</option> */}
                               
                            </Form.Select>
                            </FloatingLabel>
                          
                          
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel controlId="floatingSelect" label="Default Language" className=' mt-4'>
                            <Form.Select aria-label="Floating label select example" value={defaultlanguage} onChange={e=>{setDefaultlanguage(e?.target?.value)}}>
                                <option value = "">Select Default Language</option>
                                <option >English</option>
                            </Form.Select>
                            </FloatingLabel>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel controlId="floatingSelect" label="File Upload Driver" className=' mt-4'>
                            <Form.Select aria-label="Floating label select example" value = {fileuploaddriver} onChange={e=>{setFileuploaddriver(e?.target?.value)}}>
                                <option value = "">Select Fileupload Driver</option>
                                <option >Local</option>
                                <option >Cloudinary</option>
                            </Form.Select>
                            </FloatingLabel>
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel controlId="floatingSelect" label="Maintenance Mode" className=' mt-4'>
                            <Form.Select aria-label="Floating label select example" value = {maintenancemode} onChange={e=>{setMaintenancemode(e?.target?.value)}}>
                                <option value = "">Select Maintenance Mode</option>
                                <option >Yes</option>
                                <option >No</option>
                            </Form.Select>
                            </FloatingLabel>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel controlId="floatingSelect" label="Site Date Format" className=' mt-4'>
                            <Form.Select aria-label="Floating label select example" value = {sitedateformat} onChange={e=>{setSitedateformat(e?.target?.value)}}>
                                <option value = "">Select Site Date Format</option>
                                <option >DD/MM/YYYY</option>
                                <option >MM/DD/YYYY</option>
                                <option >YYYY/MM/DD</option>
                            </Form.Select>
                            </FloatingLabel>
                          
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel controlId="floatingSelect" label="Default Currency" className=' mt-4'>
                            <Form.Select aria-label="Floating label select example" value = {defaultcurrency} onChange={e=>{setDefaultcurrency(e?.target?.value)}}>
                                <option value = "">Select Default Currency</option>
                                <option >INR</option>
                                
                                {/* <option value="3">Three</option> */}
                            </Form.Select>
                            </FloatingLabel>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Customer Support Number"
                                className="mb-3" >
                            <Form.Control placeholder='demo' type="text" defaultValue={customersupportnumber} onChange = {(e)=>{setCustomersupportnumber(e?.target?.value)}} />
                            </FloatingLabel>
                            </div>
                        </div>
                 
             
                      
      
<div className='row'>
<div className='col-xxl-3 col-xl-4 col-sm-6'>
         <h6>Logo :</h6>
                        <div className='upphoto  ml-6'>
                        <Button variant="link" className='nounder text-dark me-2 mb-2'>
                            <span className='fa fa-photo'></span>
                            <p>Upload Image</p>
                            <img src = {logoview ? logoview : API_URL+path+"/"+logo}></img>
                            <input type="file"  name = "img" onChange ={(e)=>{setLogo(e?.target?.files[0]); setLogoview(URL.createObjectURL(e?.target?.files[0]))}}/>
                        </Button>
                        </div>
                        </div>
      <div className='col-xxl-3 col-xl-4 col-sm-6'>          

                        <h6>Email Logo :</h6>
                        <div className='upphoto my-3 ml-6'>
                        <Button variant="link" className='nounder text-dark me-2 mb-2'>
                            <span className='fa fa-photo'></span>
                            <p>Upload Image</p>
                            {console.log("image path" , API_URL+path+"/"+emaillogo)}
                            <img src = {emaillogoview ? emaillogoview : API_URL+path+"/"+emaillogo}></img>
                            <input type="file"  name = "img" onChange ={(e)=>{setEmaillogo(e?.target?.files[0]); setEmaillogoview(URL.createObjectURL(e?.target?.files[0]))}}/>
                        </Button>
                        </div>

                        </div>
                        <div className='col-xxl-3 col-xl-4 col-sm-6'>

                        <h6>Favicon :</h6>
                        <div className='upphoto my-3 ml-6'>
                        <Button variant="link" className='nounder text-dark me-2 mb-2'>
                            <span className='fa fa-photo'></span>
                            <p>Upload Image</p>
                            <img src = {faviconview ? faviconview : API_URL+path+"/"+favicon}></img>
                            <input type="file"  name = "img" onChange ={(e)=>{setFavicon(e?.target?.files[0]); setFaviconview(URL.createObjectURL(e?.target?.files[0]))}}/>
                        </Button>
                        </div>
                        </div>

                        <div className='col-xxl-3 col-xl-4 col-sm-6'>

                        <h6>Help Page Cover Image :</h6>
                        <div className='upphoto my-3 ml-6'>
                        <Button variant="link" className='nounder text-dark me-2 mb-2'>
                            <span className='fa fa-photo'></span>
                            <p>Upload helppage coverimage</p>
                            <img src = {helppagecoverimageview ? helppagecoverimageview : API_URL+path+"/"+helppagecoverimage}></img>
                            <input type="file"  name = "img" onChange ={(e)=>{setHelppagecoverimage(e?.target?.files[0]); setHelppagecoverimageview(URL.createObjectURL(e?.target?.files[0]))}}/>
                        </Button>
                        </div>
                        </div>

                        <div className='col-xxl-3 col-xl-4 col-sm-6'>

                        <h6>Home Page Stay Image :</h6>
                        <div className='upphoto my-3 ml-6'>
                        <Button variant="link" className='nounder text-dark me-2 mb-2'>
                            <span className='fa fa-photo'></span>
                            <p>Upload home page stay image</p>
                            <img src = {homepagestayimageview ? homepagestayimageview : API_URL+path+"/"+homepagestayimage}></img>
                            <input type="file"  name = "img" onChange ={(e)=>{setHomepagestayimage(e?.target?.files[0]); setHomepagestayimageview(URL.createObjectURL(e?.target?.files[0]))}}/>
                        </Button>
                        </div>
                        </div>

                        <div className='col-xxl-3 col-xl-4 col-sm-6'>

                        <h6>Home Page Experience Image :</h6>
                        <div className='upphoto my-3 ml-6'>
                        <Button variant="link" className='nounder text-dark me-2 mb-2'>
                            <span className='fa fa-photo'></span>
                            <p>Upload image</p>
                            <img src = {homepageexperienceimageview ? homepageexperienceimageview : API_URL+path+"/"+homepageexperienceimage}></img>
                            <input type="file"  name = "img" onChange ={(e)=>{setHomepageexperienceimage(e?.target?.files[0]); setHomepageexperienceimageview(URL.createObjectURL(e?.target?.files[0]))}}/>
                        </Button>
                        </div>
                        </div>


                        <div className='col-xxl-3 col-xl-4 col-sm-6'>

                        <h6>Mobile Banner Image :</h6>
                        <div className='upphoto my-3 ml-6'>
                        <Button variant="link" className='nounder text-dark me-2 mb-2'>
                            <span className='fa fa-photo'></span>
                            <p>Upload Mobile Banner image</p>
                            <img src = {mobilebannerimageview ? mobilebannerimageview : API_URL+path+"/"+mobilebannerimage}></img>
                            <input type="file"  name = "img" onChange ={(e)=>{setMobilebannerimage(e?.target?.files[0]); setMobilebannerimageview(URL.createObjectURL(e?.target?.files[0]))}}/>
                        </Button>
                        </div>
                        </div>

                        </div>

                        <div className='col-sm-6 mb-4'>
                        
                        <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Facebook Login</p>
                        </label>
                        <input class="form-check-input"  type="checkbox"  checked ={facebooklogin} onChange = {e =>  setFacebooklogin(e.target.checked)}/>
                        </div> 
                        <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Google Login :</p>
                        </label>
                        <input class="form-check-input"  type="checkbox"  checked ={googlelogin} onChange = {e =>  setGooglelogin(e.target.checked)}/>
                        </div> 
                        <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Apple Login :</p>
                        </label>
                        <input class="form-check-input"  type="checkbox" checked ={applelogin}  onChange = {e =>  setApplelogin(e.target.checked)}/>
                        </div> 

                        <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Recaptcha :</p>
                        </label>
                        <input class="form-check-input"  type="checkbox"  checked ={recaptcha} onChange = {e =>  setRecaptcha(e.target.checked)}/>
                        </div> 

                        <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Covid Verification :</p>
                        </label>
                        <input class="form-check-input"  type="checkbox"  checked ={covidverification} onChange = {e =>  setCovidverification(e.target.checked)}/>
                        </div> 
                        
                        <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>OTP :</p>
                        </label>
                        <input class="form-check-input"  type="checkbox" value ="otp" id="otp" checked={otp} onChange = {e =>  setOtp(e.target.checked)}/>
                        </div> 
                        <div className='px-4 text-end mt-3' >
                            <button className='btn btn-theme me-3' onClick={handlesubmit}>Submit</button>
                            {/* <Button variant="outline-dark" className='f-12 fw-600 outlinebtn'>Cancel</Button> */}
                        </div>
                    </div>
                    </div>
                
                </div>

                
               </div> 
            </div>
        </div>
    )
}

export default FormView