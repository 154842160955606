import React,{useEffect, useState} from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import {FloatingLabel, Form,Button, InputGroup} from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import { Bar } from "react-chartjs-2";
import { useLocation } from "react-router-dom";
import { useHistory } from 'react-router-dom';
// import { TextField ,Checkbox , FormControl , FormControlLabel, FormGroup} from '@mui/material';
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { addadminhook } from '../../../Hooks/adminHook';
const FormView = () =>{
    // const navigate = useNavigate();
    const {push} = useHistory();
    const location=useLocation();
    const [name , setName] = useState('');
    const [email , setEmail] = useState('');
    const [role , setRole] = useState();
    const [status , setStatus] = useState('');
    const [password , setPassword] = useState('');
    const [newpassword , setNewpassword] = useState('')
    const [iscms , setIscms] = useState(false);
    const [iscrm , setIscrm] = useState(false);
    const [ismanager , setIsmanager] = useState(false);
    const [isreport , setIsreport] = useState(false);
    const [issettings , setIssettings] = useState(false);

    useEffect(()=>{
document.getElementById("manageadmin").classList.add("active");
if(window.innerWidth>767)
{
    // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
    if(document.querySelectorAll(".themescroll li a.active").length>0)
    {           
         var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
  console.log(pagelinkofset,"pagelinkofset")
         document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);

    }

}
else
{
    // alert(2)
    if(document.querySelectorAll(".themescroll li a.active").length>0)
    {
        // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
        if(document.querySelectorAll(".themescroll li a.active").length>0)
        {  
        var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
        document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);
        }
    }
    
}

    },[]);
   
    const handlesubmit = async() => {
        console.log("handle submit",name?.length,email?.length,role?.length,status?.length,newpassword?.length);
        var validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if(name?.length > 0 && email?.length >0 && role?.length >0 && status?.length>0 && newpassword?.length>0)
        {
            if(validRegex?.test(email))
            {
                let payload = {
                    name : name,
                    email : email,
                    level : role,
                    password : newpassword,
                    status : status,
                    iscms : iscms,
                    iscrm : iscrm,
                    ismanager : ismanager,
                    isreport : isreport,
                    issettings : issettings
                };
                console.log("payload" , payload);
                await addadminhook(payload).then((result)=> {
                    console.log("handle submit result" , result);
                    if(result?.data?.type == "error"){
                        window.alert(result?.data?.record);
                    }
                    else if(result?.data?.type == "error1")
                    {
                        window.alert(result?.data?.record);
                    }
                    else{
                        // window.alert("New admin user added sucessfully!");
                        push("/manageadmin");
                        window.location.reload();
                    }
                    
                })
            }
            else{
                window.alert("Invalid Email!")
            }
    }
    else{
        window.alert("fill all the fields!")
    }
    }

    const handlecancel = async() => {
        console.log("handle cancel");
        push("/manageadmin");
    }
    const[showpass, setShowpass] = useState(false)
    return(
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont'>
                <h4 className='adminblue my-4'>Add Admin User</h4>
                <div className='admin-forms mb-4 '>
                    <div className='shadowbox p-4 br-10'>
                        <div className='row'>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Name *" >
                            <Form.Control  type="text" placeholder='demo' onChange={e => setName(e.target.value)}/>
                            </FloatingLabel>
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Email *" >
                            <Form.Control  type="email" placeholder='demo'  onChange={e => setEmail(e.target.value)}/>
                            </FloatingLabel>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel controlId="floatingSelect" label="Roles *">
                            <Form.Select aria-label="Floating label select example"  onChange={e => {
                                console.log("role value" , e.target.value);
                                setRole(e.target.value)}}>
                                <option value = "">Select Role</option>
                                <option value="Admin">Admin</option>
                                <option value="SubAdmin">Sub Admin</option>
                                <option value="Accountant">Accountant</option>
                            </Form.Select>
                            </FloatingLabel>
                            </div>
                            <div className='col-sm-6 mb-4'>
                                   
                        <FloatingLabel controlId="floatingSelect" label="Status *">
                            <Form.Select aria-label="Floating label select example"  onChange={e => {setStatus(e?.target?.value)}}>
                                <option value="">Select status</option>
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                            </Form.Select>
                        </FloatingLabel>
                            </div>
                        </div>

                        
                        <div className='row'>
                            <div className='col-sm-6 mb-4'>

                            <InputGroup className="mb-3 password">
                                <FloatingLabel controlId="floatingInput" label="Password *" >
                                    <Form.Control type= {showpass ? "text" : "password"  } placeholder='demo'  onChange={e => setNewpassword(e.target.value)} />
                                </FloatingLabel>
                                <Button variant="outline-secondary" id="button-addon1" onClick={() =>setShowpass(!showpass) }>
                                    <span className={showpass ? 'fa fa-eye' : 'fa fa-eye-slash'} ></span>
                                </Button>
                            </InputGroup>
                            </div>
                        </div>
                       

                        <div className='row'>
                        <div className='col-md-12 mb-4'>
                        <h5>Roles and Responsibilities</h5>

                        <div class="form-check justify-content-end gap-2 ">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Is CMS</p>
                        </label>
                        <input class="form-check-input"  type="checkbox" value="isCms" id="isCms"  onChange = {e =>{e.target.checked ?setIscms(true) : setIscms(false)}}/>
                        </div>   
                        <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Is CRM</p>
                        </label>
                        <input class="form-check-input"  type="checkbox" value="isCrm" id="isCrm"  onChange = {e =>{e.target.checked ?setIscrm(true) : setIscrm(false)}}/>
                        </div> 
                        <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Is Manager</p>
                        </label>
                        <input class="form-check-input"  type="checkbox" value="isManager" id="isManager" onChange = {e =>{e.target.checked ?setIsmanager(true) : setIsmanager(false)}}/>
                        </div> 
                        <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Is Report</p>
                        </label>
                        <input class="form-check-input"  type="checkbox" value="isReport" id="isReport"  onChange = {e =>{e.target.checked ?setIsreport(true) : setIsreport(false)}}/>
                        </div> 
                        <div class="form-check justify-content-end gap-2">
                        <label class="form-check-label" for="flexCheckChecked">
                            <p>Is Settings</p>
                        </label>
                        <input class="form-check-input"  type="checkbox" value="isSettings" id="isSettings"  onChange = {e =>{e.target.checked ?setIssettings(true) : setIssettings(false)}}/>
                        </div> 
                        </div>
                        </div>
                        <div className='px-4 text-end mt-3 btn_view_oneline' >
                            <button className='btn btn-theme me-3' onClick={handlesubmit}>Submit</button>
                            <Button variant="outline-dark" className='f-12 fw-600 outlinebtn' onClick={handlecancel}>Cancel</Button>
                        </div>
                    </div>
                
                </div>

                
               </div> 
            </div>
        </div>
    )
}

export default FormView