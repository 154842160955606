import React,{useState , Fragment, useEffect} from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import {FloatingLabel, Form,Button} from 'react-bootstrap'
// import DataTable from 'react-data-table-component';
import DataTable  from '@ashvin27/react-datatable';
import { Bar } from "react-chartjs-2";
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from 'react-router-dom';
import { getalldisputehook } from '../../../Hooks/adminHook';
import { API_URL } from '../../../config/env';
const TableView = () =>{
    const [dispute , setDispute] = useState([]);
    const [path , setPath] = useState('');
    const [totalrecordcounts, setTotalRecordCounts] = useState(0)
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const {push} = useHistory();

    useEffect(()=>{
        async function fetchdata(){
            let reqData = {
                page : page,
                limit : limit
            }
            await getalldisputehook(reqData).then((result) => {
                console.log("get all dispute result" , result , result?.data?.data?.record[0]?.attachment?.path);
                result?.data?.data?.record?.map((data,i) => {data.serial = i+1});
                setDispute(result?.data?.data?.record);
                setPath(result?.data?.data?.record[0]?.attachment?.path);
                setTotalRecordCounts(result?.data?.data?.count);
            })
        }
        fetchdata();
    },[])
    const columns = [
        {
            key: "serial",
            text: "Id",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "ticketid",
            text: "Ticket Id",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key : "firstName",
            text: "User Name",
            className: "name",
            align: "left",
            // data?.userdetails?.firstName
            cell: (data) =>(<> <p> {data?.userdetails?.firstName}</p></>)
        },
        {
            key: "raiser",
            text: "Raiser",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "assignedto",
            text: "Assigned to",
            className: "name",
            align: "left",
            // sortable: true,
        },
        // {
        //     key: "subject",
        //     text: "Subject",
        //     className: "name",
        //     align: "left",
        //     // sortable: true,
        // },
        {
            key: "description",
            text: "Description",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            // key: "attachment",
            text: "Attachment",
            className: "name",
            align: "left",
            // sortable: true,
            cell: (data) =>(<div className='d-flex upimages my-1 px-4'><a  href = {API_URL+data?.attachment?.path+"/"+data?.attachment?.name} target="_blank">
        <img src={API_URL+data?.attachment?.path+"/"+data?.attachment?.name} onclick="window.open(this.src, '_blank');" />
        </a></div>)
        },
        {
            key: "status",
            text: "Status",
            className: "name",
            align: "left",
            sortable: true,
        },
        
        {
            key: "action",
            text: "Action",
            className: "action",
            width: 100,
            align: "left",
            // sortable: true,
            cell: record => {
                return (
                    
                    <Fragment>
                        {/* <button
                            className="btn btn-primary btn-sm"
                            // onClick={() => viewRecord(record)}
                            style={{ marginRight: '5px' }}>
                            <i class="fa fa-eye mr-0" ></i>
                        </button> */}
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={() => editRecord(record)}
                            style={{ marginRight: '5px' }}
                            >
                            <i className="fa fa-edit mr-0"></i>
                        </button>
                        {/* <button
                            className="btn btn-danger btn-sm"
                            onClick={() => uploadImage(record)}>
                            <i className="fa fa-edit mr-0"></i>
                        </button> */}
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={()=>{gochat(record)}}
                            style={{ marginRight: '5px' }}>
                            <i className="fa fa-envelope"></i>
                        </button>
                    </Fragment>
                );
            }
        }
       
    ]
    const gochat = async(data)=>{
        console.log("dataa" , data);
        push("/disputechat" , {state : data});
    }

    const config = {
        page_size: 10,
        length_menu: [ 10, 20, 50 ],
        filename: "Users",
        no_data_text: 'No user found!',
        button: {
            excel: true,
            print: true,
            csv: true
        },className: "table",
        language: {
            length_menu: "Show _MENU_",
            filter: "Filter User",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first:"<<",
                previous: "<",
                next: ">",
                last: ">>"
            }
        },
        show_length_menu: true,
        show_filter: true,
        show_pagination: true,
        show_info: true,
    };
  

const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun"];


const editRecord = async(data) => {
    console.log("edit record data" , data);
    push("/updatedispute" , {state:data})
    // window.location.href = window.location.origin + "/updatedispute"
}

const pageChange = async (index) => {

    try {
        let reqData = {
            page: index.page_number,
            limit : index.page_size,
            search: index.filter_value
        }
        await getalldisputehook(reqData).then((result) => {
            console.log("get all dispute result" , result , result?.data?.data?.record[0]?.attachment?.path);
            result?.data?.data?.record?.map((data,i) => {data.serial = i+1});
            setDispute(result?.data?.data?.record);
            setPath(result?.data?.data?.record[0]?.attachment?.path);
        })
        console.log('index_data', index)
    } catch (err) {
        console.log("pageChange_err", err)
    }
} 

    return(
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont'>
                <h4 className='adminblue my-4'>Disputes</h4>
                
             
              
                <div className='tablesec shadowbox px-3 mt-3 br-10 mb-5'>
                    <h6 className='adminblue pt-4 me-2' ><span className='fa fa-book'></span> View Disputes</h6>
                    {/* <DataTable pagination columns={columns} records={data} config = {config} className={config.className}/> */}
                    <DataTable striped responsive
                        config={config}
                        className={config.className}
                        records={dispute}
                        columns={columns}
                        dynamic={true}
                        total_record={totalrecordcounts}
                        onChange={pageChange}

                    />
               </div>
               </div> 
            </div>
        </div>
    )
}

export default TableView