import React,{useEffect, useState} from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import {FloatingLabel, Form,Button} from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import { Bar } from "react-chartjs-2";
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useHistory, useLocation } from 'react-router-dom';
import { addcouponhook, editcouponhook } from '../../../Hooks/adminHook';
const FormView = () =>{
    const location = useLocation();
  const {push} = useHistory();
  const [couponcode , setCouponcode] = useState('');
  const [discountamount , setDiscountamount] = useState('');
  const [minamount , setMinamount] = useState('');
  const [currency , setCurrency] = useState('');
  const [expiredate , setExpiredate] = useState('');
  const [status , setStatus] = useState('');
  const [id , setId] = useState('');

  useEffect(()=>{
      console.log("locationdata" , location?.state?.state , location?.state?.state?.expireddate?.slice(0,10));
      setCouponcode(location?.state?.state?.couponcode);
      setDiscountamount(location?.state?.state?.discountamount);
      setMinamount(location?.state?.state?.minamount);
      setCurrency(location?.state?.state?.currency);
      setExpiredate(location?.state?.state?.expireddate?.slice(0,10));
      setStatus(location?.state?.state?.status);
      setId(location?.state?.state?._id);
  },[])
  
  const handlesubmit = async() => {
    console.log("submit" , couponcode?.length , currency?.length , expiredate?.length ,status?.length ,discountamount );
    if(couponcode?.length >0 && discountamount && minamount && currency?.length > 0 && expiredate && status?.length >0)
    {
      const re = /^[-+]?[0-9]+\.[0-9]+$/;
      if((!/\D/.test(discountamount) || discountamount?.match(re)) &&(!/\D/.test(minamount) || minamount?.match(re))){
        let payload = {
            id : id,
            couponcode : couponcode,
            discountamount : discountamount,
            minamount : minamount,
            currency : currency,
            expiredate : expiredate,
            status : status
        }
        console.log("payload" , payload);
        await editcouponhook(payload).then((result) => {
            console.log("add coupon hook result" , result);
            push("/managecoupon");
        })
      }
      else{
          window.alert("Please enter valid input!..")
      }
    }
    else{
        window.alert("Fill all the Fields!");
    }
}
  

    return(
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont'>
                <h4 className='adminblue my-4'>Edit Coupon</h4>
                <div className='admin-forms mb-4 '>
                    <div className='shadowbox p-4 br-10'>
                    <div className='row'>
                        <div className='col-sm-6 mb-4'> 
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Coupon Code *"
                                 >
                            <Form.Control placeholder="demo" type="text"  defaultValue={couponcode} onChange={(e) => {setCouponcode(e?.target?.value)}}/>
                            </FloatingLabel>
                            </div>
                            <div className='col-sm-6 mb-4'> 
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Discount Amount *"
                                 >
                                <Form.Control placeholder="demo" type="text" defaultValue={discountamount} onChange={(e) => {setDiscountamount(e?.target?.value)}} />
                            </FloatingLabel>
                            </div>
                        </div>
                        <div className='row'>
                        <div className='col-sm-6 mb-4'> 
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Minimum Amount *"
                                 >
                            <Form.Control placeholder="demo" type="text" defaultValue={minamount} onChange={(e) => {setMinamount(e?.target?.value)}} />
                            </FloatingLabel>
                            </div>
                            {/* <div className='col-sm-6 mb-4'> 
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Currency *"
                                 >
                                <Form.Control placeholder="demo" type="text" defaultValue={currency} onChange={(e) => {setCurrency(e?.target?.value)}} />
                            </FloatingLabel>
                            </div> */}
                            <div className='col-sm-6 mb-4'>
                           
                           <FloatingLabel controlId="floatingSelect" label="Currency *">
                               <Form.Select aria-label="Floating label select example" onChange={(e) => {setCurrency(e?.target?.value)}}>
                                   <option value="">Select Currency</option>
                                   <option >INR</option>
                               </Form.Select>
                               </FloatingLabel>
                           </div>
                        </div>
                        <div className='row' >
                        <div className='col-sm-6 mb-4'> 
                        <FloatingLabel controlId="floatingSelect" label="Status *">
                            <Form.Select aria-label="Floating label select example" value ={status} onChange={(e) => {setStatus(e?.target?.value)}}>
                                <option value="">Select Status</option>
                                <option >Active</option>
                                <option >Inactive</option>
                                
                            </Form.Select>
                            </FloatingLabel>
                            </div>
                            <div className='col-sm-6 mb-4'> 
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Expired Date *"
                                 >
                                <Form.Control placeholder="demo" type="date" min={new Date().toISOString().slice(0, 10)} value={expiredate} onChange={(e) =>{setExpiredate(e?.target?.value)}} className='' />
                               </FloatingLabel>
                                </div>
                        </div>
                        <div className='px-4 text-end mt-3 btn_view_oneline' >
                            <button className='btn btn-theme me-3'onClick={handlesubmit}>Submit</button>
                            <Button variant="outline-dark" className='f-12 fw-600 outlinebtn' onClick={()=>{push("/managecoupon")}}>Cancel</Button>
                        </div>
                    </div>
                
                </div>

                
               </div> 
            </div>
        </div>
    )
}

export default FormView