import React,{useEffect, useState} from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import {FloatingLabel, Form,Button, InputGroup} from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import { Bar } from "react-chartjs-2";
import { useLocation } from "react-router-dom";
import { useHistory } from 'react-router-dom';
// import { TextField ,Checkbox , FormControl , FormControlLabel, FormGroup} from '@mui/material';
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { addadminhook, Addsubscriptionhooks } from '../../../Hooks/adminHook';
const FormView = () =>{
    // const navigate = useNavigate();
    const {push} = useHistory();
    const location=useLocation();
    const [name , setName] = useState('');
    // const [email , setEmail] = useState('');
    const [role , setRole] = useState();
    const [status , setStatus] = useState('');
    const [price , setPrice] = useState('');
    const [days , setDays] = useState('');
    const [type  , setType] = useState("Host");
    const [percentage , setPercentage] = useState("");

    useEffect(()=>{
        document.getElementById("managesubscription").classList.add("active");
        if(window.innerWidth>767)
        {
            // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
            if(document.querySelectorAll(".themescroll li a.active").length>0)
            {           
                 var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
          console.log(pagelinkofset,"pagelinkofset")
                 document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);

            }

        }
        else
        {
            // alert(2)
            if(document.querySelectorAll(".themescroll li a.active").length>0)
            {
                // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
                if(document.querySelectorAll(".themescroll li a.active").length>0)
                {  
                var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
                document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);
                }
            }
            
        }
    },[]);
   
    const handlesubmit = async() => {
        console.log("handle submit",name?.length,price?.length,status?.length);
        var validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if(name?.length > 0 && price?.length >0 && days?.length >0)
        {
            if(isNaN(parseFloat(price)) || parseFloat(price) <=0){
                window.alert("Invalid price field!")
            }
            else if(isNaN(parseFloat(days)) || parseFloat(days) <=0){
                window.alert("Invalid Days field!")
            }
            else{
                let payload = {
                    name : name,
                    price : price,
                    days : days,
                    // status : status
                    type : type,
                    percentage : percentage
                }
                let res = await Addsubscriptionhooks(payload);
                push("/manage-subscription");
                window.location.reload();
            }
    }
    else{
        window.alert("fill all the fields!")
    }
    }

    const handlecancel = async() => {
        console.log("handle cancel");
        push("/manage-subscription");
    }
    const[showpass, setShowpass] = useState(false)
    return(
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont'>
                <h4 className='adminblue my-4'>Add Subscription</h4>
                <div className='admin-forms mb-4 '>
                    <div className='shadowbox p-4 br-10'>
                        <div className='row'>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Name *" >
                            <Form.Control  value = {name} type="text" placeholder='demo' onChange={e => setName(e.target.value)}/>
                            </FloatingLabel>
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Price *" >
                            <Form.Control value={price}  type="text" placeholder='demo'  onChange={e => setPrice(e.target.value)}/>
                            </FloatingLabel>
                            </div>
                        </div>
                        <div className='row'>
                        <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Days *" >
                            <Form.Control value={days}  type="text" placeholder='demo'  onChange={e => setDays(e.target.value)}/>
                            </FloatingLabel>
                            </div>
                            <div className='col-sm-6 mb-4'>
                        <FloatingLabel controlId="floatingSelect" label="Subscription type *">
                            <Form.Select aria-label="Floating label select example" value={type}  onChange={e => {setType(e?.target?.value)}}>
                                {/* <option value="">Select status</option> */}
                                <option value="Host">Host User</option>
                                <option value="Guest">Guest User</option>
                            </Form.Select>
                        </FloatingLabel>
                            </div>
                        </div>
                        <div className='row'>
                        <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Offer Percentage *" >
                            <Form.Control value={percentage}  type="text" placeholder='demo'  onChange={e => setPercentage(e.target.value)}/>
                            </FloatingLabel>
                            </div>
                            </div>

                        
                      
                       

                       
                        <div className='px-4 text-end mt-3 btn_view_oneline' >
                            <button className='btn btn-theme me-3' onClick={handlesubmit}>Submit</button>
                            <Button variant="outline-dark" className='f-12 fw-600 outlinebtn' onClick={handlecancel}>Cancel</Button>
                        </div>
                    </div>
                
                </div>

                
               </div> 
            </div>
        </div>
    )
}

export default FormView