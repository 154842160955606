import React,{useState, useEffect} from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import {FloatingLabel, Form,Button} from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import { Bar } from "react-chartjs-2";
import Datepicker from 'react-datepicker';
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css'; 
// import 'react-summernote/lang/summernote-ru-RU';
import 'bootstrap/js/src/modal.js';
import 'bootstrap/js/src/dropdown.js';
import 'bootstrap/js/src/tooltip.js';
import 'bootstrap/dist/css/bootstrap.css';
import "react-datepicker/dist/react-datepicker.css";
import { addcmshook } from '../../../Hooks/adminHook';
import { useHistory } from 'react-router-dom';
import { API_URL } from '../../../config/env';
const FormView = () =>{
  const [status , setStatus] = useState("");
  const [name , setName] = useState("");
  const [content , setContent] = useState("");
  const [image , setImage] = useState("");
  const [imageview , setImageview] = useState("");
  const [path , serPath] = useState("");
  const {push} = useHistory();
  

  useEffect(()=>{
    document.getElementById("managecms").classList.add("active");
    if(window.innerWidth>767)
    {
        // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
        if(document.querySelectorAll(".themescroll li a.active").length>0)
        {           
             var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
      console.log(pagelinkofset,"pagelinkofset")
             document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);

        }

    }
    else
    {
        // alert(2)
        if(document.querySelectorAll(".themescroll li a.active").length>0)
        {
            // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
            if(document.querySelectorAll(".themescroll li a.active").length>0)
            {  
            var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
            document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);
            }
        }
        
    }
},[]);

  const handlesubmit = async()=>{
    if(status?.length >0 && name?.length >0 && content?.length > 0)
    {
        let payload = {
            name : name ,
            status : status,
            content : content,
            image : image
        };
        await addcmshook(payload).then((result)=>{
            console.log("add cms hook result" , result);
            push("/manage-cms")
        })
    }
    else{
        window.alert("Fill all the fields !..");
    }
  }

    return(
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont'>
                <h4 className='adminblue my-4'>Add CMS</h4>
                <div className='admin-forms mb-4 '>
                    <div className='shadowbox p-4 br-10'>
                        <div className='d-sm-flex gap-4 flex-1 px-4 pt-3'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Name"
                                className="mb-3" >
                            <Form.Control type="email" placeholder="name@example.com" onChange={(e)=>{setName(e?.target?.value)}}/>
                            </FloatingLabel>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel controlId="floatingSelect" label="Status *">
                                <Form.Select aria-label="Floating label select example" onChange={(e)=>setStatus(e?.target?.value)}>
                                    <option value ="">Select Status</option>
                                    <option >Active</option>
                                    <option >Inactive</option>
                                </Form.Select>
                            </FloatingLabel>
                            </div>
                        </div>
                        {/* <div className='d-sm-flex gap-4 flex-1 px-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="First Name"
                                className="mb-3" >
                            <Form.Control type="email" placeholder="name@example.com" />
                            </FloatingLabel>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Last Name"
                                className="mb-3" >
                                <Form.Control type="email" placeholder="name@example.com" />
                            </FloatingLabel>
                        </div> */}

                    <div className='col-sm-6 mb-4'> 
                        <h6>Image :</h6>
                        <div className='upphoto my-3 ml-6'>
                        <Button variant="link" className='nounder text-dark me-2 mb-2'>
                                    <div className={imageview&& 'd-none'}>
                                    <span className='fa fa-photo'></span>
                                   
                                    <p>Upload image</p></div>
                                    <img src = {imageview && imageview }/>
                                    
                                    <input type="file" accept=".jpg,.jpeg,.png"  onChange ={(e)=>{console.log("e" , e);setImageview(URL.createObjectURL(e?.target?.files[0]));setImage(e?.target?.files[0]); }}/>
                                </Button>
                        </div>
                            </div>
                       
                            <div className='px-4'>
                            <h5 className='mb-3'>Content *:</h5>
                            
                            <ReactSummernote
                            value="Default value"
                            options={{
                                //   lang: 'ru-RU',
                                height: 350,
                                dialogsInBody: true,
                                toolbar: [
                                    ['style', ['style']],
                                    ['font', ['bold', 'underline', 'clear']],
                                    ['fontname', ['fontname']],
                                    ['para', ['ul', 'ol', 'paragraph']],
                                    ['table', ['table']],
                                    ['insert', ['link', 'picture', 'video']],
                                    ['view', ['fullscreen', 'codeview']]
                                ]
                            }}
                            onChange={(e)=>{console.log("e" ,e);setContent(e)}}
                        />
                        </div>
                        <div className='px-4 text-end mt-3 btn_view_oneline' >
                            <button className='btn btn-theme me-3' onClick={handlesubmit}>Submit</button>
                            <Button variant="outline-dark" className='f-12 fw-600 outlinebtn' onClick={()=>{push("/manage-cms")}}>Cancel</Button>
                        </div>
                    </div>
                
                </div>

                
               </div> 
            </div>
        </div>
    )
}


export default FormView