import React, { useEffect, useState } from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import { FloatingLabel, Form, Button, Tabs, Tab } from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import { Bar } from "react-chartjs-2";
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
// import DataTable from 'react-data-table-component';
import { useLocation, useHistory } from 'react-router-dom';
import { API_URL } from '../../../config/env';
import { activateroomhook, admininstantbookinghooks, onapprovedata, onrejecteddata } from '../../../Hooks/adminHook';
const FormView = () => {
    const location = useLocation();
    console.log('location', location)
    //   const navigate = useNavigate();
    const { push } = useHistory();
    const [roomdetail, setRoomdetail] = useState({});
    const [floorplan, setFloorplan] = useState([]);
    const [propertytype, setPropertytype] = useState([]);
    const [amenity, setAmenity] = useState([]);
    const [photos, setPhotos] = useState([]);
    const [description, setDescription] = useState([]);
    const [bookinginfo, setBookinginfo] = useState([]);
    const [instantbooking , setInstantBooking] = useState(null);


    useEffect(() => {
        console.log("location data", location?.state?.state);
        setRoomdetail(location?.state?.state);
        setFloorplan(location?.state?.state?.floorPlan);
        let property = [];
        property.push(location?.state?.state?.propertyType);
        setPropertytype(property);
        setAmenity(location?.state?.state?.amenities);
        setPhotos(location?.state?.state?.photos);
        setDescription(location?.state?.state?.description);
        setBookinginfo(location?.state?.state?.bookingInfo);
        setInstantBooking(location?.state?.state?.instantBookingadmin);
    }, []);

    useEffect(()=>{
        document.getElementById("manageroom").classList.add("active");
        if(window.innerWidth>767)
        {
            // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
            if(document.querySelectorAll(".themescroll li a.active").length>0)
            {           
                 var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
          console.log(pagelinkofset,"pagelinkofset")
                 document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);

            }

        }
        else
        {
            // alert(2)
            if(document.querySelectorAll(".themescroll li a.active").length>0)
            {
                // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
                if(document.querySelectorAll(".themescroll li a.active").length>0)
                {  
                var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
                document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);
                }
            }
            
        }
    },[])

    const column = [
        // {
        //     name:"Id",
        //     reorder: true,
        //     cell: (data) =>(data.serial)

        // },
        {
            name: "Type",
            reorder: true,
            cell: (data) => (data.type)
        },
        {
            name: "Count",
            reorder: true,
            cell: (data) => (data.count)
        }
    ]
    const column1 = [

        {
            name: "Heading",
            reorder: true,
            cell: (data) => (data.heading)
        },
        {
            name: "Content",
            reorder: true,
            cell: (data) => (data.content)
        }
    ]
    const column2 = [

        {
            // name:"type",
            reorder: true,
            cell: (data) => (data.type)
        }
        // {
        //     name:"Content",
        //     reorder: true,
        //     cell: (data) =>(data.content)
        // }
    ]

    const column3 = [

        {
            name: "Name",
            reorder: true,
            cell: (data) => (<div className='d-flex upimages my-1 px-4'><a href={API_URL + roomdetail?.photosPath + "/" + data.name} target="_blank">
                <img src={API_URL + roomdetail?.photosPath + "/" + data.name} onclick="window.open(this.src, '_blank');" />
            </a></div>)
        },
        {
            name: "Status",
            reorder: true,
            cell: (data) => (data?.status ? "Active" : "Inactive")
        }
    ]

    const column4 = [

        {
            name: "Type",
            reorder: true,
            cell: (data) => (data.type)
        },
        // {
        //     name:"Count",
        //     reorder: true,
        //     cell: (data) =>(data.count)
        // }
    ]

    const column5 = [

        {
            name: "Booking Id",
            reorder: true,
            cell: (data) => (data?.bookingId)
        },
        {
            name: "Checkin Date",
            reorder: true,
            cell: (data) => (data?.checkInDate?.slice(0, 10))
        },
        {
            name: "Checkin Date",
            reorder: true,
            cell: (data) => (data?.checkOutDate?.slice(0, 10))
        },
        {
            name: "Email",
            reorder: true,
            cell: (data) => (data.email)
        },
        {
            name: "Booking Date",
            reorder: true,
            cell: (data) => (data?.time?.slice(0, 10))
        }
    ]

    const handleapprove = async () => {
        await onapprovedata(roomdetail?._id).then((result) => {
            console.log("on approve data", result);
            window.alert("Room approved successfully !");
            push("/manageroom");
            window.location.reload();
        })
    }

    const handlereject = async () => {
        await onrejecteddata(roomdetail?._id).then((result) => {
            console.log("on reject data", result);
            window.alert("Room rejected successfully !");
            push("/manageroom");
            // window.location.reload();
        })
    }
    const activateroom = async () => {
        console.log("deactivate");
        await activateroomhook(roomdetail?._id).then((result) => {
            console.log("activate room hook", result);
            window.alert("Room ReActivate successfully !");
            push("/manageroom");
        })
    }

    const admininstantbooking = async(data) => {
        let payload = {
            instantbooking : false,
            admininstantbooking : data,
            id : location?.state?.state?._id
        }
        let update = await admininstantbookinghooks(payload);
        setInstantBooking(data)
    }

    return (
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar />
            </div>
            <div className='headandcont'>
                <div className="adminheader"><AdminHeader /></div>
                <div className='cont'>

                    <h4 className='adminblue my-4'>Form view</h4>
                    <div className='admin-forms mb-4 '>
                        <div className='shadowbox p-4 br-10'>
                            <Tabs
                                defaultActiveKey="home"
                                transition={false}
                                id="noanim-tab-example"
                                className="mb-3"
                            >
                                <Tab eventKey="home" title="Form view">
                                    <div className='row'>
                                        <div className='col-sm-6 mb-4'>
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Room Name"
                                                className="mb-3" >
                                                <Form.Control type="text" placeholder="name@example.com" value={roomdetail.title} readOnly={true} />
                                            </FloatingLabel>
                                        </div>
                                        <div className='col-sm-6 mb-4'>
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Price Per Night"
                                                className="mb-3" >
                                                <Form.Control type="text" placeholder="name@example.com" value={roomdetail.price} readOnly={true} />
                                            </FloatingLabel>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-6 mb-4'>
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Host First Name"
                                                className="mb-3" >
                                                <Form.Control type="text" placeholder="name@example.com" value={roomdetail.firstName} readOnly={true} />
                                            </FloatingLabel>
                                        </div>
                                        <div className='col-sm-6 mb-4'>
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Host Last Name"
                                                className="mb-3" >
                                                <Form.Control type="text" placeholder="name@example.com" value={roomdetail.lastName} readOnly={true} />
                                            </FloatingLabel>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-6 mb-4 instant_bookinsw' >
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Instant Booking"
                                                className="mb-3" >
                                                <Form.Control type="text" placeholder="name@example.com" value={roomdetail.instantBooking} readOnly={true} />
                                            </FloatingLabel>
                                        </div>
                                        <div className='col-sm-6 mb-4'>
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Cancellation Policy"
                                                className="mb-3" >
                                                <Form.Control type="text" placeholder="name@example.com" value={roomdetail.cancellationPolicy} readOnly={true} />
                                            </FloatingLabel>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-6 mb-4'>
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Privacy Type"
                                                className="mb-3" >
                                                <Form.Control type="text" placeholder="name@example.com" value={roomdetail?.privacyType ? roomdetail?.privacyType : "Not Provided !"} readOnly={true} />
                                            </FloatingLabel>
                                        </div>

                                        <div className='col-sm-6 mb-4'>
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Property Type Group"
                                                className="mb-3" >
                                                <Form.Control type="text" placeholder="name@example.com" value={roomdetail?.propertyTypeGroup ? roomdetail?.propertyTypeGroup?.type : "Not Provided !"} readOnly={true} />
                                            </FloatingLabel>
                                        </div>
                                    </div>


                                    <div className='row'>
                                        <div className='col-sm-6 mb-4'>
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Address"
                                                className="mb-3" >
                                                <Form.Control as="textarea" placeholder="name@example.com" value={roomdetail?.location ? roomdetail?.location?.street + ", " + roomdetail?.location?.area + ", " + roomdetail?.location?.city + ", " + roomdetail?.location?.state + ", " + roomdetail?.location?.postcode + ", " + roomdetail?.location?.countrycode : "Not Provided !"} readOnly={true} />
                                            </FloatingLabel>
                                        </div>
                                        <div className='col-sm-6 mb-4'>
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Description Detail"
                                                className="mb-3" >
                                                <Form.Control type="text" placeholder="name@example.com" value={roomdetail?.descriptionDetails ? roomdetail?.descriptionDetails : "Not Provided !"} readOnly={true} />
                                            </FloatingLabel>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-6 mb-4'>
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Listed"
                                                className="mb-3" >
                                                <Form.Control type="text" placeholder="name@example.com" value={roomdetail?.isListed ? "Active" : "Inactive"} readOnly={true} />
                                            </FloatingLabel>
                                        </div>
                                        <div className='col-sm-6 mb-4'>
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Status"
                                                className="mb-3" >
                                                <Form.Control type="text" placeholder="name@example.com" value={roomdetail?.status && roomdetail?.status} readOnly={true} />
                                            </FloatingLabel>
                                        </div>

                                    </div>
                                    <div className='row'>
                                    </div>
                                    <div className='row'>
                                        <div className='col-sm-6 mb-4'>
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="DeActive Status"
                                                className="mb-3" >
                                                <Form.Control type="text" placeholder="name@example.com" value={roomdetail?.isDeactivate && roomdetail?.isDeactivate} readOnly={true} />
                                            </FloatingLabel>
                                        </div>
                                        <div className='col-sm-6 mb-4 instant_bookinsw'>
                                            <Form.Check
                                                type="switch"
                                                checked = {instantbooking}
                                                onChange={(e) => {
                                                    console.log("e.target.checked", e.target.checked);
                                                    admininstantbooking(e.target.checked);
                                                }}
                                                id="custom-switch"
                                                className="ps-0 align-items-center"
                                                label="Instant Booking"
                                            />
                                        </div>
                                    </div>

                                </Tab>
                                <Tab eventKey="profile" title="Floor plan">
                                    <div className='row'>
                                        <h4 className="adminblue" >Floor Plan :</h4>
                                        <div className='row'>
                                            <div>
                                                <DataTable columns={column} data={floorplan} />
                                            </div>
                                        </div>

                                        <h4 className="adminblue mt-4" >Property Type :</h4>
                                        <div className='row'>

                                            <div>
                                                {console.log("column", column1, propertytype)}
                                                <DataTable columns={column1} data={propertytype} />
                                            </div>

                                        </div>
                                        <h4 className="adminblue mt-4" >Amenities :</h4>
                                        <div className='row amenity_table_hide_head'>

                                            <div>
                                                {console.log("column", column3, propertytype)}
                                                <DataTable columns={column2} data={amenity} />
                                            </div>
                                        </div>
                                        <h4 className="adminblue" >Host Images :</h4>
                                        <div className='row'>
                                            <div>
                                                <DataTable columns={column3} data={photos} />
                                            </div>
                                        </div>
                                    </div>


                                    <h4 className="adminblue mt-4" >Description :</h4>
                                    <div className='row'>

                                        <div>
                                            <DataTable columns={column4} data={description} />
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="contact" title="Booking Information" >
                                    <h4 className="adminblue" >Booking Information :</h4>
                                    <div className='row'>

                                        <div>
                                            <DataTable columns={column5} data={bookinginfo} pagination />
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>












                            <div className='px-4 text-end mt-3 btn_view_oneline btn_view_oneline_mor_bt' >
                                <button className='btn btn-theme me-3' disabled={roomdetail?.status == "Approved" ? true : false} onClick={handleapprove}>Approve</button>
                                <button className='btn btn-theme me-3' disabled={roomdetail?.status == "Rejected" ? true : false} onClick={handlereject}>Reject</button>
                                <button className='btn btn-theme me-3' disabled={roomdetail?.isDeactivate == true ? false : true} onClick={activateroom}>Re Activate</button>
                                <Button variant="outline-dark" className='f-12 fw-600 outlinebtn' onClick={() => { push("/manageroom") }}>Cancel</Button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormView