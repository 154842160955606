import React, { Component, useEffect, useState } from 'react';
// import Toast,{toast, Toaster} from "react-hot-toast"
import { Link, useHistory } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { createwithdrawpaymenthook, manualwithdrawpaymenthook, verifywithdrawpaymenthook } from '../../Hooks/adminHook';
// import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
// import { confirmRoom, createRazorPayment, reserveRoom, verifyRazorPayment } from '../../../Hooks/useUserHook';

// import { toastOptions } from '../../../config/env';

const PaymentIntegrationModal = (props) =>{
    const[show , setShow] = useState(true)
    const [paymentUrl, setPaymentUrl] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [image , setImage] = useState();
    const [imageshow , setImageshow] = useState();

    const {push} = useHistory();

    useEffect(()=>{
        console.log("propsdata" , props?.data);
    })

    

    const handlesubmit = async() => {
        let payload = {
            id : props ?.data?._id,
            image : image,
            type :props?.type ==  "refund"?props?.type : "payout",
            amount : props?.data?.requestamount,
            hostemail : props?.data?.hostemail
        }
        console.log("payload" ,  payload);
        await manualwithdrawpaymenthook(payload).then((result) => {
            console.log("manual withdraw payment" , result);
            setIsOpen(false);
            window.location.reload();
        })
    }

	

    

    return(
        <Modal show={show} size="md"  centered scrollable className='modal_style'>
          <Modal.Header className='border-0 pb-0'>
      
      <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
      
      </Modal.Header>
      <h5 className='modalhead text-center mb-4'>Manual Payment</h5>
            <Modal.Body>
               <div>
                {/* <div>
                <PayPalScriptProvider>
                    <PayPalButtons 
                    createOrder={(data, actions) => {
                        return actions.order.create({
                            purchase_units: [
                                {
                                    amount: {
                                        value: props.bookingDetails.totalAmount,
                                    },
                                    payee: {
                                        email_address: "sb-sun8j15395062@business.example.com"
                                    }
                                },
                            ],
                        });
                    }}
                    onApprove={(data, actions) => {
                        console.log("onApprove data:",data,"actions:",actions);
                        return actions.order.capture().then((details) => {
                            console.log("onApprove then actions.order.capture() details:",details);
                            const name = details.payer.name.given_name;
                            bookRoom(details.id,"paypal");
                            alert(`Transaction completed by ${name}`);
                        });
                    }}
                    onError = {(err) => {
                        alert('error in fund transfer');
                    }}
                    style={{ layout: "horizontal" }} />
                </PayPalScriptProvider>
                </div> */}

                <Modal isOpen={isOpen} onDismiss={()=>{setIsOpen(false)}}>
                    <iframe src={paymentUrl} />
                </Modal>
                <div className='upphoto my-3 ml-6'>
                    <h4>Upload receipt</h4>
                        <Button variant="link" className='nounder text-dark me-2 mb-2'>
                                    
                                    <span className='fa fa-photo'></span>
                                   
                                    <p>Upload receipt</p>
                                    <img src = {imageshow}/>
                                    
                                    <input type="file"  name = "img" accept=".jpg,.jpeg,.png" onChange ={(e)=>{setImage(e?.target?.files[0]); 
                                        setImageshow(URL.createObjectURL(e?.target?.files[0]));
                                        // if(filevalidate(image) && reason?.length > 0){
                                        //     setSubmit(true);
                                        // }
                                        // else{
                                        //     setSubmit(false);
                                        // }
                                        }}/>
                                </Button>
                        </div>
                {/* <button  className="w-full btn btn-theme mb-4">Paypal</button>
                <button  className="w-full btn btn-theme mb-4" onClick={handlePayment}>Razorpay</button> */}
                <button  className="w-full btn btn-theme mb-4" onClick={handlesubmit}>Manual payment</button>
               </div>
            </Modal.Body>
            {/* <Toaster/> */}
      </Modal>
    )
}

export default PaymentIntegrationModal