import React, { useEffect, useState } from 'react';
import { Button, FloatingLabel, Form,InputGroup } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import logo from '../../Assets/images/logo.png'
import { FRONT_URL } from '../../config/env';
import { loginhook } from '../../Hooks/adminHook';

const AdminLogin = () =>{
    // const navigate = useNavigate();
    
    const cookies = new Cookies();
    const {push} = useHistory();
    const [useremail , setUseremail] = useState('');
    const [email , setEmail] = useState('');
    const [password , setPassword] = useState('');
useEffect(()=>{
    if(cookies.get("admin-email"))
    {
        push("/");
       
    }
},[])
    const log = async() => {
        if(email?.length >0 && password?.length > 0)
        {
            let payload = {
                email : email,
                password : password
            };
            await loginhook(payload).then((result) => {
                console.log("login hook result" , result);
                if(result?.data?.type == "success")
                {
                    push("/")
                    // window.location.href = FRONT_URL;
                    window.location.reload();
                } else if(result?.data?.type == "errors") {
                    window.alert("User Deactivated !.. ");
                }
                else if(result?.data?.type == "error"){
                    window.alert("Admin user not found !.. ");
                }
                else if(result?.data?.type == "error1"){
                    window.alert("Incorrect password");
                }
            })
        }
        else {
            window.alert("Fill all the fields!..")
        }
    }

    const handleKeyDown = (event)=>{
        if(event?.key === "Enter"){
            console.log("enter")
            log();
        }
    }
    const[showpass, setShowpass] = useState(false)
    return(
        <div className='adminlogin'>
             <div className='container container-theme'>
                <div className='row justify-content-center mh-100vh align-items-center m-sm-0 m-3'>
                    <div className='shadowbox col-lg-5 col-xl-4 col-md-6 bg-white br-10'>
                            <div className='py-5'>
                                <h3 className='text-center mb-3'>Login</h3>
                                <div className='text-center'>
                                <img src={logo} className='img-fluid'/>
                                </div>
                                <div className='px-lg-5 px-3 mt-4'>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Email"
                                        className="mb-3">
                                        <Form.Control type="email" placeholder="name@example.com" onChange={(e)=>{setEmail(e?.target?.value)}}/>
                                    </FloatingLabel>
                                    
                                    <InputGroup className="mb-3 password">
                                    <FloatingLabel controlId="floatingPassword" label="Password">
                                        <Form.Control type= {showpass ? "text" : "password"} placeholder="Password" onKeyDown = {(e)=>{handleKeyDown(e)}} onChange={(e) => {
                                            
                                            setPassword(e?.target?.value)}}/>
                                    </FloatingLabel>

                                    <Button variant="outline-secondary" id="button-addon1" onClick={() => setShowpass(!showpass)}>
                                    <span className={showpass ? 'fa fa-eye' : 'fa fa-eye-slash'}></span>
                                </Button>
                        </InputGroup>


                                 
                                    <Button variant="link" className="nounder adminblue ps-0 f-14" onClick={()=>{push("/forgot-password")}}>Forgot Password?</Button>

                                    <div className='mt-4 text-center'>
                                       <button className='btn btn-theme' onClick={log}>Sign in</button>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
             </div>
        </div>
    )
}

export default AdminLogin