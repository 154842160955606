import React,{useState, useEffect} from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import {FloatingLabel, Form,Button} from 'react-bootstrap'
import { addfaqhooks, addfeehook } from '../../../Hooks/adminHook';
import { useHistory } from 'react-router-dom';
import ReactSummernote from 'react-summernote';
const FormView = () =>{
    const {push} = useHistory();
  const [heading , setHeading] = useState('');
  const [percentage , setPercentage] = useState('');
  const [question , setQuestion] = useState('');
  const [answer , setAnswer] = useState('');

  useEffect(()=>{
    document.getElementById("managefaq").classList.add("active");
    if(window.innerWidth>767)
    {
        // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
        if(document.querySelectorAll(".themescroll li a.active").length>0)
        {           
             var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
      console.log(pagelinkofset,"pagelinkofset")
             document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);

        }

    }
    else
    {
        // alert(2)
        if(document.querySelectorAll(".themescroll li a.active").length>0)
        {
            // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
            if(document.querySelectorAll(".themescroll li a.active").length>0)
            {  
            var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
            document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);
            }
        }
        
    }
},[]);
console.log("heading_question_answer", heading, question, answer)
  const handlesubmit = async()=>{
    let payload = {
      question : question,
      answer : answer
    };
    console.log("payload" , payload);
    if(question?.length >0 && answer?.length > 0)
    {
        await addfaqhooks(payload).then((res) => {
            console.log("add fee hook result" , res);
            if(res?.data?.type == "success"){
                push("/faq");
            }
    })
    }
    else {
        window.alert("Fill all the fields");
    }
  }
  

    return(
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont'>
                <h4 className='adminblue my-4'>Add Fee</h4>
                <div className='admin-forms mb-4 '>
                    <div className='shadowbox p-4 br-10'>
                        <div className='row'>
                            {/* <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Heading"
                                className="mb-3" >
                            <Form.Control type="text" placeholder='heading' onChange={(e)=>{setHeading(e?.target?.value)}}/>
                            </FloatingLabel>
                            </div> */}
                            <div className='col-sm-6 mb-4'>
                            
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Question"
                                className="mb-3" >
                                <Form.Control type="text"  placeholder='question' onChange={(e)=>{setQuestion(e?.target?.value)}}/>
                            </FloatingLabel>
                            </div>
                        </div>
                        {/* <div className='row'>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Answer"
                                className="mb-3" >
                            <Form.Control type="text" placeholder='answer' onChange={(e)=>{setAnswer(e?.target?.value)}}/>
                            </FloatingLabel>
                            </div>
                            <div className='col-sm-6 mb-4'>
            
                            </div>
                        </div> */}
                                 <div className='px-4'>
                            <h5 className='mb-3'>Answer</h5>
                            
                           <ReactSummernote
                            value={answer}
                            options={{
                                height: 350,
                                dialogsInBody: true,
                                toolbar: [
                                    ['style', ['style']],
                                    ['font', ['bold', 'underline', 'clear']],
                                    ['fontname', ['fontname']],
                                    ['para', ['ul', 'ol', 'paragraph']],
                                    ['table', ['table']],
                                    ['insert', ['link', 'picture', 'video']],
                                    ['view', ['fullscreen', 'codeview']]
                                ]
                            }}
                            onChange={(e)=>{console.log("e" ,e);setAnswer(e)}}
                        />
                        </div>
                        <div className='px-4 text-end mt-3 btn_view_oneline' >
                            <button className='btn btn-theme me-3' onClick={handlesubmit}>Submit</button>
                            <Button variant="outline-dark" className='f-12 fw-600 outlinebtn' onClick={()=>{push("/manage-fee")}}>Cancel</Button>
                        </div>
                    </div>
                
                </div>

                
               </div> 
            </div>
        </div>
    )
}

export default FormView