import React,{useState , useEffect} from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import {FloatingLabel, Form,Button} from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import { Bar } from "react-chartjs-2";
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { acceptuserverificationhook, changebankdetailstatusHooks, edituserhook, rejectuserverificationhook } from '../../../Hooks/adminHook';
import { API_URL } from '../../../config/env';
const FormView = () =>{
    const location = useLocation();
    // const navigate = useNavigate();
    const {push} = useHistory();
  const [singleuser , setSingleuser] = useState({});
  const [status , setStatus] = useState(false);
  const [kyc , setKyc] = useState({});
  const [comments, setComment] = useState(false);
  const [commentdata, setCommentData] = useState('');
  const [commentshow, setCommentShow] = useState('')
  const [err, setErr] = useState("")



  const [govtid, setGovtId] = useState("")
console.log('commentdataaaaaaa', commentdata, govtid)
  useEffect(() => {
      console.log("location darta" , location?.state?.state, location?.state?.state?.govtid);
      setSingleuser(location?.state?.state)
      setStatus(location?.state?.state?.status)
      if (location?.state?.state?.govtproof?.length > 0) {
        console.log("location?.state?.state?.govtproof", location?.state?.state)
          setKyc(location?.state?.state?.govtproof[location?.state?.state?.govtproof?.length - 1]);
      }
      if (location?.state?.state?.govtidcomment != "") {
        setCommentData(location?.state?.state?.govtidcomment)
        setCommentShow(location?.state?.state?.govtidcomment)
      }
      setGovtId(location?.state?.state?.govtid)

  }, [location])

  useEffect(()=>{
    document.getElementById("managehostuser").classList.add("active");
    if(window.innerWidth>767)
    {
        // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
        if(document.querySelectorAll(".themescroll li a.active").length>0)
        {           
             var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
      console.log(pagelinkofset,"pagelinkofset")
             document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);

        }

    }
    else
    {
        // alert(2)
        if(document.querySelectorAll(".themescroll li a.active").length>0)
        {
            // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
            if(document.querySelectorAll(".themescroll li a.active").length>0)
            {  
            var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
            document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);
            }
        }
        
    }
},[])
  
  
const handlesubmit = async() => {
    console.log("handle submit");
    if(status?.length>0)
    {
        let payload = {
            id : location?.state?.state?._id,
            status : status
        };
        console.log("payload" , payload);
        await edituserhook(payload).then((result) => {
            console.log("edit userhook result" , result);
            push("/managehost");
        })
    }
}

const handlecancel = async() =>{
    console.log("cancel");
    push("/managehost");
}

const handleaccept = async() => {
    await acceptuserverificationhook(singleuser._id).then((result) => {
        console.log("accept user verification hook" , result);
        window.alert("Verification Accepted Sucessfully");
        setTimeout(()=> {
            push("/managehost");
        }, 1000)
    })
}

const handlebankaccountstatus = async(data) => {
    let payload = {
        email : singleuser?.email,
        status : data
    }
    let result = await changebankdetailstatusHooks(payload);
    if(data == "Approved")
    window.alert("Bankdetail Approved Sucessfully");
    else
    window.alert("Bankdetail Rejected Sucessfully");

    push("/managehost");
}

// const handlereject = async() => {
//     console.log("handle reject");
//     if(singleuser?.govtproof?.status){
//     await rejectuserverificationhook(singleuser._id).then((result) => {
//         console.log("handl;e reject result" , result);
//         window.alert("Verification Rejected Sucessfully");
//     })
//     }
// }
const handlereject = async() => {
    console.log("handle reject");
    // if (commentdata != "" && commentdata != undefined && commentdata != "undefined") {
        let data = {
            _id: singleuser?._id,
            comments: commentdata
        }
        console.log("edithost handlrejected", data)
        // if (kyc?.status) {
            await rejectuserverificationhook(data).then((result) => {
                console.log("handl;e reject result", result);
                window.alert("Verification Rejected Sucessfully");
                setTimeout(()=> {
                    push("/managehost");
                }, 1000)
            })
        // }
    // } else {
    //     setErr("Comments is required")
    // }
}


const handlerejectcomment = async () => {
    setComment(true)
}
    return(
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont'>
                <h4 className='adminblue my-4'>Edit Host User</h4>
                <div className='admin-forms mb-4 '>
                    <div className='shadowbox p-4 br-10'>
                        <div className='row'>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="First Name"
                                >
                            <Form.Control type="text"  value = {singleuser?.firstName} readOnly = {true} placeholder="name@example.com" />
                            </FloatingLabel>
                            </div>
                            {/* <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Last Name"
                                >
                                <Form.Control type="text" value = {singleuser?.lastName} readOnly = {true} placeholder="name@example.com" />
                            </FloatingLabel>
                            </div> */}
                        </div>
                        <div className='row' >
                        <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Gender"
                                >
                                <Form.Control type="text" value = {singleuser?.gender} readOnly = {true} placeholder="name@example.com" />
                            </FloatingLabel>
                            </div>
                            <div className='col-sm-6 mb-4'>
                        
                            <FloatingLabel controlId="floatingSelect" label="Date Of Birth" className=' mt-3'>
                                  <Form.Control type="text" placeholder="name@example.com" readOnly={true} value = {singleuser?.dob?.slice(0,10)}/>
                            </FloatingLabel>
                                
                        </div>
                        </div>
                        <div className='row'>
                        <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Email"
                                >
                            <Form.Control type="text" placeholder="name@example.com" value ={singleuser?.email} readOnly = {true}/>
                            </FloatingLabel>
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Country"
                                >
                                <Form.Control type="text" placeholder="name@example.com" value ={singleuser?.countrycode} readOnly = {true}/>
                            </FloatingLabel>
                        </div>
                        </div>
                        <div className='row'>
                        <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Login Type"
                                >
                            <Form.Control type="text" placeholder="name@example.com" value ={singleuser?.loginType} readOnly = {true}/>
                            </FloatingLabel>
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Is Host"
                                >
                                <Form.Control type="text" placeholder="name@example.com" value = {singleuser?.isHost ? "Active" : "Inactive"} readOnly = {true}/>
                            </FloatingLabel>
                            </div>
                        </div>
                        <div className='row'>
                        <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Status"
                                >
                            <Form.Select aria-label="Floating label select example" value = {status} onChange={e => setStatus(e.target.value)}>
                                <option value = "">Select status</option>
                                <option>Active</option>
                                <option>Inactive</option>
                            </Form.Select>
                            </FloatingLabel>
                            </div>
                            {/* <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Govt Proof"
                                >
                                <Form.Control type="text" placeholder="name@example.com" value ={kyc?.proof ? kyc?.proof : "Not Provided!"} readOnly = {true} />
                            </FloatingLabel>
                            
                            </div> */}
                            
                            {/* <div className='col-sm-6 mb-4'> */}
                            {/* <FloatingLabel
                                controlId="floatingInput"
                                label="Govt Proof Status"
                                 >
                                <Form.Control type="text" placeholder="name@example.com" value ={kyc?.verification ? kyc?.verification : "Not Provided!"} readOnly = {true} />
                            </FloatingLabel> */}
                            {/* </div> */}
                            {console.log("govt proof data", govtid != "", govtid, govtid != "" ? govtid : "Not Provided!")}
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Govt Proof Id "
                                 >
                                <Form.Control type="text" placeholder="name@example.com" value ={govtid != "" ? govtid : "Not Provided!"} readOnly = {true} />
                            </FloatingLabel>
                            </div>

                            {(kyc?.verification != "pending" &&  kyc?.verification != "accepted" )&& <div className='col-sm-6 mb-4'> 
                            {/* <FloatingLabel
                                controlId="floatingInput"
                                label="Comments"
                                >
                                <Form.Control type="text" placeholder="name@example.com" value ={commentshow} readOnly = {true} />
                            </FloatingLabel> */}
                            </div>}
                        </div>
                        <div className='row' >

                        {/* {kyc?.status && <h6>Govt Proof Image :</h6>}
                            
                            <div className='d-flex upimages upimages_edit mb-3 px-3' >
                            {kyc?.status && kyc?.govtidimg?.map((data) => <a href = {API_URL+kyc?.path+singleuser?.email+"/"+data} target="_blank">
                            <img src={API_URL+kyc?.path+singleuser?.email+"/"+data} onclick="window.open(this.src, '_blank');" />
                            </a>)}
                                
                            </div> */}
                            
                         
                                {/* {govtid != "" && govtid != undefined && govtid != "undefined" &&
                                <div className='px-3 mb-3'>
                                    
                                        <button className='btn btn-theme me-2 mb-3'  onClick={handleaccept}>Accept</button>
                                       
                                        <button className='btn btn-theme mb-3' disabled={kyc?.verification == 'rejected' ? true : false} onClick={handlereject}>Decline</button>
                                    
                        </div>} */}
                        </div>


                        {/* Bank detail  */}
                        {comments && <div className='row'>
                                <div className='col-sm-6 mb-4'>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Comments"
                                    >
                                        <Form.Control type="text" placeholder="name@example.com"  onChange={(e) => setCommentData(e?.target?.value)}/>
                                    </FloatingLabel>
                                    <span className='text-danger'>{err}</span> 
                                    {<div className='px-0 mb-3 mt-3'>
                                    <button className='btn btn-theme mb-3' onClick={handlereject}>Update</button>
                                </div>}
                                </div>
                               
                               
                            </div>}

                         



                        <h6>Bank Detail :</h6>
                        <div className='row'>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Bank Name"
                                 >
                            <Form.Control type="text"  value = {singleuser?.bankname} readOnly = {true} placeholder="name@example.com" />
                            </FloatingLabel>
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Branch Name"
                                 >
                                <Form.Control type="text" value = {singleuser?.branchname} readOnly = {true} placeholder="name@example.com" />
                            </FloatingLabel>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Bank Holder Name"
                                 >
                            <Form.Control type="text"  value = {singleuser?.holdername} readOnly = {true} placeholder="name@example.com" />
                            </FloatingLabel>
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Account Number"
                                 >
                                <Form.Control type="text" value = {singleuser?.accountno} readOnly = {true} placeholder="name@example.com" />
                            </FloatingLabel>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="IFSC Code"
                                 >
                            <Form.Control type="text"  value = {singleuser?.ifsccode} readOnly = {true} placeholder="name@example.com" />
                            </FloatingLabel>
                            </div>

                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Bank Detail Status"
                                 >
                            <Form.Control type="text"  value = {singleuser?.bankaccountstatus} readOnly = {true} placeholder="name@example.com" />
                            </FloatingLabel>
                            </div>
                        </div>

                        {singleuser?.bankaccountstatus == "Pending" &&
                                <div className='px-3 mb-3'>
                                    
                                        <button className='btn btn-theme me-2 mb-3' onClick={() => handlebankaccountstatus("Approved")}>Approve</button>
                                        <button className='btn btn-theme mb-3' onClick={() => handlebankaccountstatus("Rejected")}>Reject</button>
                                    
                        </div>}
                      
                        <div className='px-3 mb-3'>
                                        {/* <button className='btn btn-theme me-2 mb-3' onClick={handleaccept}>Accept</button>
                                        <button className='btn btn-theme mb-3' onClick={handlereject}>Decline</button> */}
                        </div>
                        
                        <div className='px-4 text-end mt-3' >
                            <button className='btn btn-theme me-3' onClick={handlesubmit}>Submit</button>
                            <Button variant="outline-dark" className='f-12 fw-600 outlinebtn' onClick={handlecancel}>Cancel</Button>
                        </div>
                    </div>
                
                </div>

                
               </div> 
            </div>
        </div>
    )
}

export default FormView