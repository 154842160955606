import React,{useEffect, useState} from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import {FloatingLabel, Form,Button} from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import { Bar } from "react-chartjs-2";
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { getemailsettinghook, updateemailsettinghook } from '../../../Hooks/adminHook';
const FormView = () =>{
  const [driver , setDriver] = useState('');
  const [host , setHost] = useState('');
  const [port , setPort] = useState('');
  const [fromaddress , setFromaddress] = useState('');
  const [fromname , setFromname] = useState('');
  const [encryption , setEncryption] = useState('');
  const [username , setUsername] = useState('');
  const [password , setPassword] = useState('');

  useEffect(()=>{
      async function fetchdata(){
          await getemailsettinghook().then((result)=>{
              console.log("get email setting hook result" , result?.data?.data?.record[0]?.details);
              setDriver(result?.data?.data?.record[0]?.details?.driver);
              setHost(result?.data?.data?.record[0]?.details?.host);
              setPort(result?.data?.data?.record[0]?.details?.port);
              setFromaddress(result?.data?.data?.record[0]?.details?.fromaddress);
              setFromname(result?.data?.data?.record[0]?.details?.fromname);
              setEncryption(result?.data?.data?.record[0]?.details?.encryption);
              setUsername(result?.data?.data?.record[0]?.details?.username);
              setPassword(result?.data?.data?.record[0]?.details?.password);
          })
      }
      fetchdata();
  },[])
  
  const handlesubmit = async()=>{
    var validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if(driver?.length>0 && host?.length >0 && port?.length > 0 && fromaddress?.length > 0 && fromname?.length>0 &&
        encryption?.length>0 && username?.length >0&&password?.length>0)
    {console.log("email validation" , validRegex?.test(host),fromaddress?.toLocaleLowerCase()?.match(validRegex),username?.value?.match(validRegex))
        
        if (validRegex?.test(host)&& validRegex?.test(fromaddress)&&validRegex?.test(username)) {
            if(!(/\D/.test(port))){
            let payload = {
                driver : driver,
                host : host,
                port : port,
                fromaddress : fromaddress,
                fromname : fromname,
                encryption : encryption,
                username : username,
                password : password
            }
            await updateemailsettinghook(payload).then((result) =>{
                console.log("update email setting hook" , result);
                window.location.reload();
            })
        }
        else{
            window.alert("Fill numeric value in port");
        }
        }
        else{
            window.alert("Invalid email!")
        }
    }
    else{
        window.alert("Fill all the fields!");
    }
  }

    return(
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont'>
                <h4 className='adminblue my-4'>Email Setting Form</h4>
                <div className='admin-forms mb-4 '>
                    <div className='shadowbox p-4 br-10'>
                        <div className='row'>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Driver *" >
                            <Form.Control placeholder="demo" type="text" defaultValue={driver} onChange={(e)=>{setDriver(e?.target?.value)}} />
                            </FloatingLabel>
                           
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="host *" >
                                <Form.Control placeholder="demo" type="text" defaultValue={host} onChange={(e)=>{setHost(e?.target?.value)}} />
                            </FloatingLabel>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Port *" >
                            <Form.Control placeholder="demo" type="text" defaultValue={port} onChange={(e)=>{setPort(e?.target?.value)}} />
                            </FloatingLabel>
                          
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="From Address *" >
                                <Form.Control placeholder="demo" type="text" defaultValue={fromaddress} onChange={(e)=>{setFromaddress(e?.target?.value)}} />
                            </FloatingLabel>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="From Name *" >
                            <Form.Control placeholder="demo" type="text" defaultValue={fromname} onChange={(e)=>{setFromname(e?.target?.value)}} />
                            </FloatingLabel>
                          
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Encryption *" >
                                <Form.Control placeholder="demo" type="text" defaultValue={encryption} onChange={(e)=>{setEncryption(e?.target?.value)}} />
                            </FloatingLabel>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="User Name *" >
                            <Form.Control placeholder="demo" type="text" defaultValue={username} onChange={(e)=>{setUsername(e?.target?.value)}} />
                            </FloatingLabel>
                           
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Password *" >
                                <Form.Control placeholder="demo" type="text" defaultValue={password} onChange={(e)=>{setPassword(e?.target?.value)}} />
                            </FloatingLabel>
                            </div>
                        </div>
                      
                         
                        <div className='px-4 text-end mt-3 btn_view_oneline' >
                            <button className='btn btn-theme me-3' onClick={handlesubmit}>Submit</button>
                            <Button variant="outline-dark" className='f-12 fw-600 outlinebtn'>Cancel</Button>
                        </div>
                    </div>
                
                </div>

                
               </div> 
            </div>
        </div>
    )
}

export default FormView