import React,{useState , Fragment, useEffect} from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import {FloatingLabel, Form,Button} from 'react-bootstrap'
// import DataTable from 'react-data-table-component';
import DataTable  from '@ashvin27/react-datatable';
import { Bar } from "react-chartjs-2";
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {DateTimeForm, getcancelledbookinghook} from '../../../Hooks/adminHook';
const TableView = () =>{
    const [cancelbookings , setCancelbookings] = useState([]);

    useEffect(() => {
        async function fetchdata(){
            await getcancelledbookinghook().then((result) => {
                console.log("get cancelled booking result" , result);
                result?.data?.data?.record?.map((data , i) => data.serial = i+1);
                setCancelbookings(result?.data?.data?.record);
            })
        }
        fetchdata();
    },[])
    const columns = [
        {
            key: "serial",
            text: "S.no.",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "email",
            text: "Guest Email",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "hostEmail",
            text: "Host Email",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "roomTitle",
            text: "Room Title",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "totalAmount",
            text: "Total Amount",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "nights",
            text: "Nights",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "refundstatus",
            text: "Refund Status",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "orderId",
            text: "Order Id",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "orderType",
            text: "Order Type",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "createdAt",
            text: "Created At",
            className: "name",
            align: "left",
            sortable: true,
            cell: (data) =>(DateTimeForm(String(data?.createdAt))?.split(",")[0]  + "   " + DateTimeForm(String(data?.createdAt))?.split(",")[1])
        },
        {
            key: "action",
            text: "Action",
            className: "action",
            width: 100,
            align: "left",
            // sortable: true,
            cell: record => {
                return (
                    <Fragment>
                        {/* <button
                            className="btn btn-primary btn-sm"
                            // onClick={() => viewRecord(record)}
                            style={{ marginRight: '5px' }}>
                            <i class="fa fa-eye mr-0" ></i>
                        </button> */}
                        <button
                            className="btn btn-primary btn-sm"
                            // onClick={() => editRecord(record)}
                            // style={{ marginRight: '5px' }}
                            >
                            <i className="fa fa-share mr-0"></i>
                        </button>
                        {/* <button
                            className="btn btn-danger btn-sm"
                            onClick={() => uploadImage(record)}>
                            <i className="fa fa-edit mr-0"></i>
                        </button> */}
                        {/* <button
                            className="btn btn-primary btn-sm"
                            onClick={() => WhiteList(record)}
                            style={{ marginRight: '5px' }}>
                            <i className="fa fa-edit mr-0"></i>
                        </button> */}
                    </Fragment>
                );
            }
        }
        // {
        //     name:"Id",
        //     reorder: true,
        //     cell: (data) =>(data.id)
        // },
        // {
        //     name:"Reservation Id",
        //     cell: (data) =>(data.reserveid)           
        // },
        // {
        //     name:"Room name",
        //     cell: (data) =>(data.Roomname)          
        // },
        // {
        //     name:"User Name",
        //     cell: (data) =>(data.username)
        // },
        // {
        //     name:"Email Id",
        //     cell: (data) =>(data.emailid)
        // },
        // {
        //     name:"User Date From",
        //     cell: (data) =>(data.userdatafrom)
        // },
        // {
        //     name:"User Date To",
        //     cell: (data) =>(data.userdatato)
        // },
        // {
        //     name:"Id Document",
        //     cell: (data) =>(data.iddocuments)
        // },
        // {
        //     name:"Status",
        //     cell: (data) =>(data.staus)
        // }
    ]
   

    const config = {
        page_size: 10,
        length_menu: [ 10, 20, 50 ],
        filename: "Users",
        no_data_text: 'No user found!',
        button: {
            excel: true,
            print: true,
            csv: true
        },className: "table",
        language: {
            length_menu: "Show _MENU_",
            filter: "Filter User",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first:"<<",
                previous: "<",
                next: ">",
                last: ">>"
            }
        },
        show_length_menu: true,
        show_filter: true,
        show_pagination: true,
        show_info: true,
    };
  

const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun"];

// const datas = {
//   labels: labels,
//   datasets: [
//     {
//       label: "My First dataset",
//       backgroundColor: "rgb(255, 99, 132)",
//       borderColor: "rgb(255, 99, 132)",
//       data: [0, 10, 5, 2, 20, 30, 45],
//     },
//   ],
// };
    return(
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont'>
                <h4 className='adminblue my-4'>Cancelled Bookings</h4>
                
             
              
                <div className='tablesec shadowbox px-3 mt-3 br-10 mb-5'>
                    <h6 className='adminblue pt-4 me-2' ><span className='fa fa-book'></span> View User</h6>
                    {/* <DataTable pagination columns={columns} records={data} config = {config} className={config.className}/> */}
                    <DataTable striped responsive
                        config={config}
                        className={config.className}
                        records={cancelbookings}
                        columns={columns}

                    />
               </div>
               </div> 
            </div>
        </div>
    )
}

export default TableView