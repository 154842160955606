import React,{useEffect, useState} from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import {FloatingLabel, Form,Button} from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import { Bar } from "react-chartjs-2";
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { getapicredentialhook, updateapicredentialhook } from '../../../Hooks/adminHook';
import { API_URL } from '../../../config/env';
import pdflogo from '../../../Assets/images/pdflogo.jpg';
const FormView = () =>{
  const [facebookclientid , setFacebookclientid] = useState('');
  const [facebookclientsecret , setFacebookclientsecret] = useState('');
  const [googleclientid , setGoogleclientid] = useState('');
  const [googleclientsecret , setGoogleclientsecret] = useState('');
  const [googlemapbrowserkey , setGooglemapbrowserkey] = useState('');
  const [googlemapserverkey , setGooglemapserverkey] = useState('');
  const [cloudinaryname , setCloudinaryname] = useState('');
  const [cloudinarykey , setCloudinarykey] = useState('');
  const [cloudinarysecret , setCloudinarysecret] = useState('');
  const [cloudinarybaseurl , setCloudinarybaseurl] = useState('');
  const [cloudinarysecreturl , setCloudinarysecreturl] = useState('');
  const [cloudinaryapiurl , setCloudinaryapiurl] = useState('');
  const [appleteamid , setAppleteamid] = useState('');
  const [appleserviceid , setAppleserviceid] = useState('');
  const [applekeyid , setApplekeyid] = useState('');
  const [applekeyfile , setApplekeyfile] = useState('');
  const [fcmserverkey , setFcmserverkey] = useState('');
  const [firebaseserviceaccountfile , setFirebaseserviceaccountfile] = useState('');
  const [firebasedatabaseurl , setFirebasedatabaseurl] = useState('');
  const [firebaseapikey , setFirebaseapikey] = useState('');
  const [firebaseauthdomain , setFirebaseauthdomain] = useState('');
  const [firebaseprojectid , setFirebaseprojectid] = useState('');
  const [firebasemessagingsenderid , setFirebasemessagingsenderid] = useState('');
  const [firebaseappid , setFirebaseappid] = useState('');
  const [recaptchasitekey , setRecaptchasitekey] = useState('');
  const [recaptchasecretkey , setRecaptchasecretkey] = useState('');
  const [path , setPath] = useState('');
  const [applekeyfileview , setApplekeyfileview] = useState('');
  const [credential , setCredential] = useState('');

  useEffect(()=>{
    async function fetchdata(){
        await getapicredentialhook().then((result)=>{
            console.log("get api credential result" , result);
            setCredential(result?.data?.data?.record[0]?.details);
            setFacebookclientid(result?.data?.data?.record[0]?.details?.facebookclientid);
            setFacebookclientsecret(result?.data?.data?.record[0]?.details?.facebookclientsecret);
            setGoogleclientid(result?.data?.data?.record[0]?.details?.googleclientid);
            setGoogleclientsecret(result?.data?.data?.record[0]?.details?.googleclientsecret);
            setGooglemapbrowserkey(result?.data?.data?.record[0]?.details?.googlemapbrowserkey);
            setGooglemapserverkey(result?.data?.data?.record[0]?.details?.googlemapserverkey);
            setCloudinaryname(result?.data?.data?.record[0]?.details?.cloudinaryname);
            setCloudinarykey(result?.data?.data?.record[0]?.details?.cloudinarykey);
            setCloudinarysecret(result?.data?.data?.record[0]?.details?.cloudinarysecret);
            setCloudinarybaseurl(result?.data?.data?.record[0]?.details?.cloudinarybaseurl);
            setCloudinarysecreturl(result?.data?.data?.record[0]?.details?.cloudinarysecureurl);
            setCloudinaryapiurl(result?.data?.data?.record[0]?.details?.cloudinaryapiurl);
            setAppleteamid(result?.data?.data?.record[0]?.details?.appleteamid);
            setAppleserviceid(result?.data?.data?.record[0]?.details?.appleserviceid);
            setApplekeyid(result?.data?.data?.record[0]?.details?.applekeyid);
            setApplekeyfile(result?.data?.data?.record[0]?.details?.applekeyfile);
            setFcmserverkey(result?.data?.data?.record[0]?.details?.fcmserverkey);
            setFirebaseserviceaccountfile(result?.data?.data?.record[0]?.details?.firebaseserviceaccountfile);
            setFirebasedatabaseurl(result?.data?.data?.record[0]?.details?.firebasedatabaseurl);
            setFirebaseapikey(result?.data?.data?.record[0]?.details?.firebaseapikey);
            setFirebaseauthdomain(result?.data?.data?.record[0]?.details?.firebaseauthdomain);
            setFirebaseprojectid(result?.data?.data?.record[0]?.details?.firebaseprojectid);
            setFirebasemessagingsenderid(result?.data?.data?.record[0]?.details?.firebasemessagingsenderid);
            setFirebaseappid(result?.data?.data?.record[0]?.details?.firebaseappid);
            setRecaptchasitekey(result?.data?.data?.record[0]?.details?.recaptchasitekey);
            setRecaptchasecretkey(result?.data?.data?.record[0]?.details?.recaptchasecretkey);
            setPath(result?.data?.data?.record[0]?.details?.path);
        });
    }
   fetchdata();
  },[])
  
  const filevalidate = (image)=>{
    var fileName = image?.name ? image?.name :image;
        var idxDot = fileName?.lastIndexOf(".") + 1;
        var extFile = fileName?.substr(idxDot, fileName.length).toLowerCase();
        if(extFile=="pdf" || extFile=="txt" || extFile=="word"){
            return true;
        }else{
            return false;
        }
  }

  const handlesubmit = async()=>{
    console.log("applekeyid?.length" , applekeyid?.length);
    const error = {};
    if(facebookclientid?.length <=0){
        error.facebookclientid = "error";
        console.log("facebookclientid");
    }
    if(facebookclientsecret?.length <=0){
        error.facebookclientsecret = "error";
        console.log("facebookclientsecret");
    }
    if(googleclientid?.length <=0){
        error.googleclientid = "error";
        console.log("googleclientid");
    }
    if(googleclientsecret?.length <=0){
        error.googleclientsecret = "error";
        console.log("googleclientsecret");
    }
    if(googlemapbrowserkey?.length <=0){
        error.googlemapbrowserkey = "error";
        console.log("googlemapbrowserkey");
    }
    if(googlemapserverkey?.length <=0){
        error.googlemapserverkey = "error";
        console.log("googlemapserverkey");
    }
    if(cloudinaryname?.length <=0){
        error.cloudinaryname = "error";
        console.log("cloudinaryname");
    }
    if(cloudinarykey?.length <=0){
        error.cloudinarykey = "error";
        console.log("cloudinarykey");
    }
    if(cloudinarysecret?.length <=0){
        error.cloudinarysecret = "error";
        console.log("cloudinarysecret");
    }
    if(cloudinarybaseurl?.length <=0){
        error.cloudinarybaseurl = "error";
        console.log("cloudinarybaseurl");
    }
    if(cloudinarysecreturl?.length <=0){
        error.cloudinarysecreturl = "error";
        console.log("cloudinarysecreturl");
    }
    if(cloudinaryapiurl?.length <=0){
        error.cloudinaryapiurl = "error";
        console.log("cloudinaryapiurl");
    }
    if(appleserviceid?.length <=0){
        error.appleserviceid = "error";
        console.log("appleserviceid");
    }
    if(appleteamid?.length <=0){
        error.appleteamid = "error";
        console.log("appleteamid");
    }
    if(applekeyid?.length <=0){
        error.applekeyid = "error";
        console.log("applekeyid");
    }
    if(fcmserverkey?.length <=0){
        error.fcmserverkey = "error";
        console.log("fcmserverkey");
    }
    if(firebasedatabaseurl?.length <=0){
        error.firebasedatabaseurl = "error";
        console.log("firebasedatabaseurl");
    }
    if(firebaseapikey?.length <=0){
        error.firebaseapikey = "error";
        console.log("firebaseapikey");
    }
    if(firebaseauthdomain?.length <=0){
        error.firebaseauthdomain = "error";
        console.log("firebaseauthdomain");
    }
    if(firebaseprojectid?.length <=0){
        error.firebaseprojectid = "error";
        console.log("firebaseprojectid");
    }
    if(firebasemessagingsenderid?.length <=0){
        error.firebasemessagingsenderid = "error";
        console.log("firebasemessagingsenderid");
    }
    if(firebaseappid?.length <=0){
        error.firebaseappid = "error";
        console.log("firebaseappid");
    }
    if(recaptchasitekey?.length <=0){
        error.recaptchasitekey = "error";
        console.log("recaptchasitekey");
    }
    if(recaptchasecretkey?.length <=0){
        error.recaptchasecretkey = "error";
        console.log("recaptchasecretkey");
    }
    if(!applekeyfile){
        error.applekeyfile = "error";
    }
    if(!firebaseserviceaccountfile){
        error.firebaseserviceaccountfile = "error";
    }
    // if(facebookclientid?.length >0 && facebookclientsecret?.length > 0 && googleclientid?.length>0 &&
    //     googleclientsecret?.length >0 && googlemapbrowserkey?.length>0 && googlemapserverkey?.length>0 &&
    //     cloudinaryname?.length>0 && cloudinarykey?.length >0 && cloudinarysecret?.length >0 && 
    //     cloudinarybaseurl?.length >0 && cloudinarysecreturl?.length>0 && cloudinaryapiurl?.length>0 && 
    //     appleserviceid?.length>0 && appleteamid?.length>0 && applekeyid?.length>0 && 
    //     fcmserverkey?.length>0 && firebasedatabaseurl?.length>0 && firebaseapikey?.length>0 && 
    //     firebaseauthdomain?.length>0 , firebaseprojectid?.length>0 && firebasemessagingsenderid?.length>0 &&
    //     firebaseappid?.length >0 && recaptchasitekey?.length >0 && recaptchasecretkey?.length>0 && applekeyfile &&
    //     firebaseserviceaccountfile)
    console.log("error" , error);
    if(Object.keys(error).length === 0 )
        {
            if(filevalidate(applekeyfile) && filevalidate(firebaseserviceaccountfile)){
                let payload = {
                    facebookclientid : facebookclientid,
                    facebookclientsecret : facebookclientsecret,
                    googleclientid : googleclientid,
                    googleclientsecret : googleclientsecret,
                    googlemapbrowserkey : googlemapbrowserkey,
                    googlemapserverkey : googlemapserverkey,
                    cloudinaryname : cloudinaryname,
                    cloudinarykey : cloudinarykey,
                    cloudinarysecret : cloudinarysecret,
                    cloudinarybaseurl : cloudinarybaseurl,
                    cloudinarysecreturl : cloudinarysecreturl,
                    cloudinaryapiurl : cloudinaryapiurl,
                    appleserviceid : appleserviceid,
                    appleteamid : appleteamid,
                    applekeyid : applekeyid,
                    applekeyfile : applekeyfile,
                    fcmserverkey : fcmserverkey,
                    firebaseserviceaccountfile : firebaseserviceaccountfile,
                    firebasedatabaseurl : firebasedatabaseurl,
                    firebaseapikey : firebaseapikey,
                    firebaseauthdomain : firebaseauthdomain,
                    firebaseprojectid : firebaseprojectid,
                    firebasemessagingsenderid : firebasemessagingsenderid,
                    firebaseappid : firebaseappid,
                    recaptchasitekey : recaptchasitekey,
                    recaptchasecretkey : recaptchasecretkey
                };
                console.log("payload" , payload);
                await updateapicredentialhook(payload).then((result)=>{
                    console.log("update api credential result" , result);
                    // window.location.reload();
                })
            }
            else{
                window.alert("Invalid file format !");
            }
        }
        else{
            window.alert("Fill all the fields !");
        }
  }

    return(
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont'>
                <h4 className='adminblue my-4'>Api Credential</h4>
                <div className='admin-forms mb-4 '>
                    <div className='shadowbox p-4 br-10'>
                        <div className='row'>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Facebook Client Id *"
                                className="mb-3" >
                            <Form.Control placeholder="demo" type="text" defaultValue={facebookclientid} onChange={(e)=>{setFacebookclientid(e?.target?.value)}} />
                            </FloatingLabel>
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Facebook Client Secret *"
                                className="mb-3" >
                                <Form.Control placeholder="demo" type="text" defaultValue={facebookclientsecret} onChange={(e)=>{setFacebookclientsecret(e?.target?.value)}} />
                            </FloatingLabel>
                        </div>
                        </div>

                        <div className='row'>
                        <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Google Client Id *"
                                className="mb-3" >
                            <Form.Control placeholder="demo" type="text" defaultValue={googleclientid} onChange={(e)=>{setGoogleclientid(e?.target?.value)}} />
                            </FloatingLabel>
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Google Client Secret *"
                                className="mb-3" >
                                <Form.Control placeholder="demo" type="text" defaultValue={googleclientsecret} onChange={(e)=>{setGoogleclientsecret(e?.target?.value)}} />
                            </FloatingLabel>
                            </div>
                        </div>

                        <div className='row'>
                        <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Googlemap Browser Key *"
                                className="mb-3" >
                            <Form.Control placeholder="demo" type="text" defaultValue={googlemapbrowserkey} onChange={(e)=>{setGooglemapbrowserkey(e?.target?.value)}} />
                            </FloatingLabel>
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Googlemap Server Key *"
                                className="mb-3" >
                                <Form.Control placeholder="demo" type="text" defaultValue={googlemapserverkey} onChange={(e)=>{setGooglemapserverkey(e?.target?.value)}} />
                            </FloatingLabel>
                            </div>
                        </div>

                        <div className='row'>
                        <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Cloudinary Name *"
                                className="mb-3" >
                            <Form.Control placeholder="demo" type="text" defaultValue={cloudinaryname} onChange={(e)=>{setCloudinaryname(e?.target?.value)}} />
                            </FloatingLabel>
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Cloudinary Key *"
                                className="mb-3" >
                                <Form.Control placeholder="demo" type="text" defaultValue={cloudinarykey} onChange={(e)=>{setCloudinarykey(e?.target?.value)}} />
                            </FloatingLabel>
                            </div>
                        </div>

                        <div className='row'>
                        <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Cloudinary Secret *"
                                className="mb-3" >
                            <Form.Control placeholder="demo" type="text" defaultValue={cloudinarysecret} onChange={(e)=>{setCloudinarysecret(e?.target?.value)}} />
                            </FloatingLabel>
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Cloudinary Base Url *"
                                className="mb-3" >
                                <Form.Control placeholder="demo" type="text" defaultValue={cloudinarybaseurl} onChange={(e)=>{setCloudinarybaseurl(e?.target?.value)}} />
                            </FloatingLabel>
                            </div>
                        </div>

                        <div className='row'>
                        <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Cloudinary Secure Url *"
                                className="mb-3" >
                            <Form.Control placeholder="demo" type="text" defaultValue={cloudinarysecreturl} onChange={(e)=>{setCloudinarysecreturl(e?.target?.value)}} />
                            </FloatingLabel>
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Cloudinary Api Url *"
                                className="mb-3" >
                                <Form.Control placeholder="demo" type="text" defaultValue={cloudinaryapiurl} onChange={(e)=>{setCloudinaryapiurl(e?.target?.value)}} />
                            </FloatingLabel>
                            </div>
                        </div>

                        <div className='row'>
                        <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Apple Service Id *"
                                className="mb-3" >
                            <Form.Control placeholder="demo" type="text" defaultValue={appleserviceid} onChange={(e)=>{setAppleserviceid(e?.target?.value)}} />
                            </FloatingLabel>
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Apple Team Id *"
                                className="mb-3" >
                                <Form.Control placeholder="demo" type="text" defaultValue={appleteamid} onChange={(e)=>{setAppleteamid(e?.target?.value)}} />
                            </FloatingLabel>
                        </div>
                        </div>

                        <div className='row'>
                        <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Apple Key Id *"
                                className="mb-3" >
                            <Form.Control placeholder="demo" type="text" defaultValue={applekeyid} onChange={(e)=>{setApplekeyid(e?.target?.value)}} />
                            </FloatingLabel>
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Fmc Server Key *"
                                className="mb-3" >
                                <Form.Control placeholder="demo" type="text" defaultValue={fcmserverkey} onChange={(e)=>{setFcmserverkey(e?.target?.value)}} />
                            </FloatingLabel>
                            </div>
                        </div>

                        <div className='row'>
                        <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Firebase Database Url *"
                                className="mb-3" >
                            <Form.Control placeholder="demo" type="text" defaultValue={firebasedatabaseurl} onChange={(e)=>{setFirebasedatabaseurl(e?.target?.value)}} />
                            </FloatingLabel>
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Firebase Api Key *"
                                className="mb-3" >
                                <Form.Control placeholder="demo" type="text" defaultValue={firebaseapikey} onChange={(e)=>{setFirebaseapikey(e?.target?.value)}} />
                            </FloatingLabel>
                            </div>
                        </div>

                        <div className='row'>
                        <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Firebase Auth Domain *"
                                className="mb-3" >
                            <Form.Control placeholder="demo" type="text" defaultValue={firebaseauthdomain} onChange={(e)=>{setFirebaseauthdomain(e?.target?.value)}} />
                            </FloatingLabel>
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Firebase Project Id *"
                                className="mb-3" >
                                <Form.Control placeholder="demo" type="text" defaultValue={firebaseprojectid} onChange={(e)=>{setFirebaseprojectid(e?.target?.value)}} />
                            </FloatingLabel>
                            </div>
                        </div>

                        <div className='row'>
                        <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Firebase Messaging Sender Id *"
                                className="mb-3" >
                            <Form.Control placeholder="demo" type="text" defaultValue={firebasemessagingsenderid} onChange={(e)=>{setFirebasemessagingsenderid(e?.target?.value)}} />
                            </FloatingLabel>
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Firebase App Id *"
                                className="mb-3" >
                                <Form.Control placeholder="demo" type="text" defaultValue={firebaseappid} onChange={(e)=>{setFirebaseappid(e?.target?.value)}} />
                            </FloatingLabel>
                            </div>
                        </div>

                        <div className='row'>
                        <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Recaptcha Site Key *"
                                className="mb-3" >
                            <Form.Control placeholder="demo" type="text" defaultValue={recaptchasitekey} onChange={(e)=>{setRecaptchasitekey(e?.target?.value)}} />
                            </FloatingLabel>
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Recaptcha Secret Key *"
                                className="mb-3" >
                                <Form.Control placeholder="demo" type="text" defaultValue={recaptchasecretkey} onChange={(e)=>{setRecaptchasecretkey(e?.target?.value)}} />
                            </FloatingLabel>
                            </div>
                        </div>
                          

                          <div className='row'>
                            <div className='col-xxl-3 col-lg-4 col-sm-6'>
                                <h6>Apple Key File :</h6>
                                <div className='upphoto my-3 ml-6'>
                                {credential?.applekeyfile  &&
                                        <Button variant="link" className='nounder text-dark me-2 mb-2'>
                                            <span className='fa fa-photo'></span>
                                            {/* <p>Upload file</p> */}
                                            <a  href = {API_URL + path +"/"+ credential?.applekeyfile} target="_blank"><img src = {pdflogo}/></a> 
                                        </Button>}
                                        <input type="file" name = "img"  onChange ={(e)=>{setApplekeyfile(e?.target?.files[0])}}/>
                                </div>
                            </div>
                            <div className='col-xxl-3 col-lg-4 col-md-6'>
                            <h6>Firebase Service Account File :</h6>
                        <div className='upphoto my-3 ml-6'>
                            {credential?.firebaseserviceaccountfile &&
                                <Button variant="link" className='nounder text-dark me-2 mb-2'>
                                <span className='fa fa-photo'></span> 
                                 {/* <p>Upload file</p> */}
                                 <a  href = {API_URL + path +"/" + credential?.firebaseserviceaccountfile} target="_blank"><img src = {pdflogo}/></a> 
                            </Button>
                            }
                            {/* <iframe src = {API_URL + path +"/" + credential?.firebaseserviceaccountfile}></iframe> */}

                        
                                <input type="file" name = "img" accept = {".pdf"} onChange ={(e)=>{setFirebaseserviceaccountfile(e?.target?.files[0])}}/>

                        </div>
                                </div>
                          </div>
                        

                     




                        
                        <div className='px-4 text-end mt-3 btn_view_oneline' >
                            <button className='btn btn-theme me-3'onClick={handlesubmit}>Submit</button>
                            <Button variant="outline-dark" className='f-12 fw-600 outlinebtn'>Cancel</Button>
                        </div>
                    </div>
                
                </div>

                
               </div> 
            </div>
        </div>
    )
}

export default FormView