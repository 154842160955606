import React,{useState} from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import {FloatingLabel, Form,Button} from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import { Bar } from "react-chartjs-2";
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { API_URL } from '../../../config/env';
const FormView = () =>{
  const location = useLocation();
  const {push} = useHistory();
  const [details , setDetails] = useState({});

  useEffect(()=>{
    document.getElementById("managewithdraw").classList.add("active");
    if(window.innerWidth>767)
    {
        // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
        if(document.querySelectorAll(".themescroll li a.active").length>0)
        {           
             var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
      console.log(pagelinkofset,"pagelinkofset")
             document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);

        }

    }
    else
    {
        // alert(2)
        if(document.querySelectorAll(".themescroll li a.active").length>0)
        {
            // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
            if(document.querySelectorAll(".themescroll li a.active").length>0)
            {  
            var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
            document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);
            }
        }
        
    }
        },[]);

  useEffect(()=>{
    console.log("location data" , location?.state?.state);
    setDetails(location?.state?.state);
  },[])
  

    return(
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont'>
                <h4 className='adminblue my-4'>View Withdraw</h4>
                <div className='admin-forms mb-4 '>
                    <div className='shadowbox p-4 br-10'>
                        <div className='d-sm-flex gap-4 flex-1 px-4 pt-3'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Host Email"
                                className="mb-3" >
                            <Form.Control type="text" value={details?.hostemail} placeholder="name@example.com" readOnly = {true} />
                            </FloatingLabel>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Host Earning"
                                className="mb-3" >
                                <Form.Control type="text" value={details?.hostearning} placeholder="name@example.com" readOnly = {true}/>
                            </FloatingLabel>
                        </div>


                        <div className='d-sm-flex gap-4 flex-1 px-4 pt-3'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Host Available Balance"
                                className="mb-3" >
                            <Form.Control type="text" value={details?.hostavaliablebalance} placeholder="name@example.com" readOnly = {true}/>
                            </FloatingLabel>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Request Amount"
                                className="mb-3" >
                                <Form.Control type="text" value={details?.requestamount} placeholder="name@example.com" readOnly = {true}/>
                            </FloatingLabel>
                        </div>

                        <div className='d-sm-flex gap-4 flex-1 px-4 pt-3'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Status"
                                className="mb-3" >
                            <Form.Control type="text" value={details?.status} placeholder="name@example.com" readOnly = {true} />
                            </FloatingLabel>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Payment Status"
                                className="mb-3" >
                                <Form.Control type="text" value={details?.paymentstatus} placeholder="name@example.com" readOnly = {true}/>
                            </FloatingLabel>
                        </div>
                        <h4>Payment Created</h4>
                        <div className='d-sm-flex gap-4 flex-1 px-4 pt-3'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Id"
                                className="mb-3" >
                            <Form.Control type="text" value={details?.paymentcreated?.id} placeholder="name@example.com" readOnly = {true}/>
                            </FloatingLabel>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Entity"
                                className="mb-3" >
                                <Form.Control type="text" value={details?.paymentcreated?.entity} placeholder="name@example.com" readOnly = {true}/>
                            </FloatingLabel>
                        </div>

                        <div className='d-sm-flex gap-4 flex-1 px-4 pt-3'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Amount"
                                className="mb-3" >
                            <Form.Control type="text" value={details?.paymentcreated?.amount} placeholder="name@example.com" readOnly = {true}/>
                            </FloatingLabel>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Amount Paid"
                                className="mb-3" >
                                <Form.Control type="text" value={details?.paymentcreated?.amount_paid} placeholder="name@example.com" readOnly = {true}/>
                            </FloatingLabel>
                        </div>

                        <div className='d-sm-flex gap-4 flex-1 px-4 pt-3'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Amount Due"
                                className="mb-3" >
                            <Form.Control type="text" value={details?.paymentcreated?.amount_due} placeholder="name@example.com" readOnly = {true}/>
                            </FloatingLabel>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Currency"
                                className="mb-3" >
                                <Form.Control type="text" value={details?.paymentcreated?.currency} placeholder="name@example.com" readOnly = {true}/>
                            </FloatingLabel>
                        </div>

                        <div className='d-sm-flex gap-4 flex-1 px-4 pt-3'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Receipt"
                                className="mb-3" >
                            <Form.Control type="text" value={details?.paymentcreated?.receipt} placeholder="name@example.com" readOnly = {true}/>
                            </FloatingLabel>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Status"
                                className="mb-3" >
                                <Form.Control type="text" value={details?.paymentcreated?.status} placeholder="name@example.com" readOnly = {true}/>
                            </FloatingLabel>
                        </div>
                        <h4>Payment Verified</h4>

                        <div className='d-sm-flex gap-4 flex-1 px-4 pt-3'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Payment Id"
                                className="mb-3" >
                            <Form.Control type="text" value={details?.paymentverified?.razorpay_payment_id} placeholder="name@example.com" readOnly = {true}/>
                            </FloatingLabel>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Order Id"
                                className="mb-3" >
                                <Form.Control type="text" value={details?.paymentverified?.razorpay_order_id} placeholder="name@example.com" readOnly = {true}/>
                            </FloatingLabel>
                        </div>

                        <div className='d-sm-flex gap-4 flex-1 px-4 pt-3 w-50'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Signature"
                                className="mb-3" >
                            <Form.Control type="text" value={details?.paymentverified?.razorpay_signature} placeholder="name@example.com" readOnly = {true}/>
                            </FloatingLabel>
                            
                        </div>
                        <div className={!details?.manualwithdraw && "d-none"}>
                            <h4>Manual Payment</h4>
                            <div className='col-sm-6 mb-4'>
                            <h6>Image *:</h6>
                            <div className='upphoto my-3 ml-6'>
                                <a href = {API_URL+details?.manualwithdraw?.path +"/"+ details?.manualwithdraw?.image} target = "_blank">
                            <Button variant="link" className='nounder text-dark me-2 mb-2'>
                                        
                                        <span className='fa fa-photo'></span>
                                    
                                        <p>Upload image</p>
                                        <img src = {API_URL+details?.manualwithdraw?.path+"/"+  details?.manualwithdraw?.image}/>
                                        
                                        {/* <input type="file" accept="image/*"  onChange ={(e)=>{console.log("e" , e);setImageview(URL.createObjectURL(e?.target?.files[0]));setImage(e?.target?.files[0]); }}/> */}
                                    </Button></a>
                            </div>
                        </div>
                        </div>

                       
                        <div className='px-4 text-end mt-3' >
                            {/* <button className='btn btn-theme me-3'>Submit</button> */}
                            <Button variant="outline-dark" className='f-12 fw-600 outlinebtn' onClick={()=>push("/manage-withdraw")}>Cancel</Button>
                        </div>
                    </div>
                
                </div>

                
               </div> 
            </div>
        </div>
    )
}


export default FormView