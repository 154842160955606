import React,{useEffect, useState} from 'react';
import Cookies from 'universal-cookie';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import {Form, InputGroup} from 'react-bootstrap'
import "react-datepicker/dist/react-datepicker.css";
import prof from '../../../Assets/images/prof1.jpg';
import { useHistory, useLocation } from 'react-router-dom';
import { API_URL } from '../../../config/env';
import { disputechathook, getchathook } from '../../../Hooks/adminHook';
const Dashboard = () =>{
    const cookies = new Cookies();
    const location = useLocation();
    const {push} = useHistory();
    const [chat , setChat] = useState([]);
    const [image , setImage] = useState({});
    const [newmessage , setNewmessage] = useState('');
    const [sender , setSender] = useState('');

    useEffect(()=>{
        async function fetchdata(){
            console.log("location data" , location?.state?.key)
            setImage(location?.state?.state?.attachment);
            setSender(cookies.get("admin-Datas"))
            await getchathook(location?.state?.state?._id).then((result)=>{
                console.log("get chat hookk result" , result);
                setChat(result?.data?.record[0]?.chat);
            })
        }
        fetchdata();
        // console.log("location data" , location?.state?.state);
        // setChat( location?.state?.state?.chat);
        
    },[])


    const handlesend = async()=>{
        console.log("sender" , sender , newmessage);
        if(newmessage?.length > 0){
            let payload = {
                id : location?.state?.state?._id,
                sender : sender?._id,
                receiver : location?.state?.state?.raiser,
                message : newmessage
            }
            console.log("payload" , payload);
            await disputechathook(payload).then((result)=>{
                console.log("handle send result" , result);
                window.location.reload();
            })
        }
        
    }
    return(
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont'>
                <div className='d-flex justify-content-between'>
                <h4 className='adminblue my-4 '>Raiser Id : {location?.state?.state?.raiser}</h4>
                <div>  <button onClick={()=>{location?.state?.key == "crm" ?push("/manage-crm-dispute") :push("/managedispute")}} className="btn">X</button></div>
                </div>
                <div className='shadowbox  p-4 br-10'>
                    
                    <div className='chats themescroll'>
                    <div className='upimages upimag_sidue mb-3 px-4' >
                            <h6>Dispute Image</h6>
                            <div>
                             <a href = {API_URL+image?.path+"/"+image?.name} target="_blank">
                            <img src={API_URL+image?.path+"/"+image?.name}  />
                            </a>
                            </div>
                    </div>
                    <hr />
                        {chat?.map((data)=>
                            
                            data?.ref == "user" ?
                            
                            <div className='chatmsg'>
                            <div className='d-flex'>
                                <img src={prof} />
                                <div>
                                    {/* {setTime(data?.time)} */}
                                   {data?.image &&  <div className='mb-2'>
                               <a href = {API_URL+image?.path+"/"+data?.image} target="_blank">
                            <img src={API_URL+image?.path+"/"+data?.image}  />
                            </a>
                            </div>}
                                    <p>{data?.message}</p>
                                    <span className='text-muted f-12'> {data?.time?.slice(0,10)
                                    +"  "+ new Date(data?.time).getHours() +":"+new Date(data?.time).getMinutes()}</span>
                               
                                </div>
                            </div>
                        </div>
                        : data.ref == "supportteam" &&
                        <div className='replymsg'>
                        <div className='d-flex'>
                              
                                <div>
                                {data?.image &&  <div className='mb-2'>
                               <a href = {API_URL+image?.path+"/"+data?.image} target="_blank">
                            <img src={API_URL+image?.path+"/"+data?.image}  />
                            </a>
                            </div>}
                                <p>{data?.message}</p>{console.log("date " , data?.time)}
                                    <span className='text-muted f-12'> {data?.time?.slice(0,10)
                                    +"  "+ new Date(data?.time).getHours() +":"+new Date(data?.time).getMinutes()}</span>
                                </div>
                                <img src={prof} />
                            </div>
                        </div>

                        
                     )}
                        
                        
                    </div>


                        {/* <div className='chatmsg'>
                            <div className='d-flex'>
                                <img src={prof} />
                                <div>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. At magni impedit enim odit tenetur. Modi officia, iusto numquam laudantium quam quae commodi
                                       ipsam sapiente beatae doloremque veritatis officiis odit. Veritatis.</p>
                                    <span className='text-muted f-12'> 15-02-2022 19:12</span>
                                </div>
                            </div>
                        </div>
                        <div className='replymsg'>
                        <div className='d-flex'>
                              
                                <div>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. At magni impedit enim odit tenetur. Modi officia, iusto numquam laudantium quam quae commodi
                                       ipsam sapiente beatae doloremque veritatis officiis odit. Veritatis.</p>
                                    <span className='text-muted f-12'> 15-02-2022 19:12</span>
                                </div>
                                <img src={prof} />
                            </div>
                        </div>
                        <div className='chatmsg'>
                            <div className='d-flex'>
                                <img src={prof} />
                                <div>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. At magni impedit enim odit tenetur. Modi officia, iusto numquam laudantium quam quae commodi
                                       ipsam sapiente beatae doloremque veritatis officiis odit. Veritatis.</p>
                                    <span className='text-muted f-12'> 15-02-2022 19:12</span>
                                </div>
                            </div>
                        </div>
                        <div className='replymsg'>
                        <div className='d-flex'>
                              
                                <div>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. At magni impedit enim odit tenetur. Modi officia, iusto numquam laudantium quam quae commodi
                                       ipsam sapiente beatae doloremque veritatis officiis odit. Veritatis.</p>
                                    <span className='text-muted f-12'> 15-02-2022 19:12</span>
                                </div>
                                <img src={prof} />
                            </div>
                        </div>
                        <div className='chatmsg'>
                            <div className='d-flex'>
                                <img src={prof} />
                                <div>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. At magni impedit enim odit tenetur. Modi officia, iusto numquam laudantium quam quae commodi
                                       ipsam sapiente beatae doloremque veritatis officiis odit. Veritatis.</p>
                                    <span className='text-muted f-12'> 15-02-2022 19:12</span>
                                </div>
                            </div>
                        </div>
                        <div className='replymsg'>
                        <div className='d-flex'>
                              
                                <div>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. At magni impedit enim odit tenetur. Modi officia, iusto numquam laudantium quam quae commodi
                                       ipsam sapiente beatae doloremque veritatis officiis odit. Veritatis.</p>
                                    <span className='text-muted f-12'> 15-02-2022 19:12</span>
                                </div>
                                <img src={prof} />
                            </div>
                        </div>
                        <div className='chatmsg'>
                            <div className='d-flex'>
                                <img src={prof} />
                                <div>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. At magni impedit enim odit tenetur. Modi officia, iusto numquam laudantium quam quae commodi
                                       ipsam sapiente beatae doloremque veritatis officiis odit. Veritatis.</p>
                                    <span className='text-muted f-12'> 15-02-2022 19:12</span>
                                </div>
                            </div>
                        </div>
                        <div className='replymsg'>
                        <div className='d-flex'>
                              
                                <div>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. At magni impedit enim odit tenetur. Modi officia, iusto numquam laudantium quam quae commodi
                                       ipsam sapiente beatae doloremque veritatis officiis odit. Veritatis.</p>
                                    <span className='text-muted f-12'> 15-02-2022 19:12</span>
                                </div>
                                <img src={prof} />
                            </div>
                        </div>
                        <div className='chatmsg'>
                            <div className='d-flex'>
                                <img src={prof} />
                                <div>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. At magni impedit enim odit tenetur. Modi officia, iusto numquam laudantium quam quae commodi
                                       ipsam sapiente beatae doloremque veritatis officiis odit. Veritatis.</p>
                                    <span className='text-muted f-12'> 15-02-2022 19:12</span>
                                </div>
                            </div>
                        </div>
                        <div className='replymsg'>
                        <div className='d-flex'>
                              
                                <div>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. At magni impedit enim odit tenetur. Modi officia, iusto numquam laudantium quam quae commodi
                                       ipsam sapiente beatae doloremque veritatis officiis odit. Veritatis.</p>
                                    <span className='text-muted f-12'> 15-02-2022 19:12</span>
                                </div>
                                <img src={prof} />
                            </div>
                        </div>
                        <div className='chatmsg'>
                            <div className='d-flex'>
                                <img src={prof} />
                                <div>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. At magni impedit enim odit tenetur. Modi officia, iusto numquam laudantium quam quae commodi
                                       ipsam sapiente beatae doloremque veritatis officiis odit. Veritatis.</p>
                                    <span className='text-muted f-12'> 15-02-2022 19:12</span>
                                </div>
                            </div>
                        </div>
                        <div className='replymsg'>
                        <div className='d-flex'>
                              
                                <div>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. At magni impedit enim odit tenetur. Modi officia, iusto numquam laudantium quam quae commodi
                                       ipsam sapiente beatae doloremque veritatis officiis odit. Veritatis.</p>
                                    <span className='text-muted f-12'> 15-02-2022 19:12</span>
                                </div>
                                <img src={prof} />
                            </div>
                        </div>

                        <div className='chatmsg'>
                            <div className='d-flex'>
                                <img src={prof} />
                                <div>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. At magni impedit enim odit tenetur. Modi officia, iusto numquam laudantium quam quae commodi
                                       ipsam sapiente beatae doloremque veritatis officiis odit. Veritatis.</p>
                                    <span className='text-muted f-12'> 15-02-2022 19:12</span>
                                </div>
                            </div>
                        </div>
                        <div className='replymsg'>
                        <div className='d-flex'>
                              
                                <div>
                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. At magni impedit enim odit tenetur. Modi officia, iusto numquam laudantium quam quae commodi
                                       ipsam sapiente beatae doloremque veritatis officiis odit. Veritatis.</p>
                                    <span className='text-muted f-12'> 15-02-2022 19:12</span>
                                </div>
                                <img src={prof} />
                            </div>
                        </div> */}

                       
                   
                    <div className="textbox h-55">
                        <InputGroup className="">
        <Form.Control
        //   placeholder="Recipient's username"
          aria-label="Recipient's username"
          aria-describedby="basic-addon2"
          onChange={(e)=>{console.log(e?.target?.value);setNewmessage(e?.target?.value)}}
        />
        <InputGroup.Text id="basic-addon2" ><span className='fa fa-send px-3'  onClick={handlesend}></span></InputGroup.Text>
      </InputGroup>
                        </div>
                </div>
            
               </div> 
            </div>
        </div>
    )
}

export default Dashboard