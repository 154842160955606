import React,{useState, useEffect} from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import {FloatingLabel, Form,Button} from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import { Bar } from "react-chartjs-2";
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from 'react-router-dom';
import { addlegalandprivacy } from '../../../Hooks/adminHook';
const FormView = () =>{
//   const navigate = useNavigate();
const {push} = useHistory();
  const [type , setType] = useState('');
  const [status , setStatus] = useState('');
  const [image , setImage] =useState('');
  const [imageview , setImageview] = useState('');
  

  useEffect(()=>{
    document.getElementById("managelegal").classList.add("active");
    if(window.innerWidth>767)
    {
        // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
        if(document.querySelectorAll(".themescroll li a.active").length>0)
        {           
             var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
      console.log(pagelinkofset,"pagelinkofset")
             document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);

        }

    }
    else
    {
        // alert(2)
        if(document.querySelectorAll(".themescroll li a.active").length>0)
        {
            // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
            if(document.querySelectorAll(".themescroll li a.active").length>0)
            {  
            var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
            document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);
            }
        }
        
    }
},[]);
  
const handlesubmit = async()=> {
    if(type?.length > 0 && status?.length > 0 && image)
    {
        var fileName = image.name;
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();


        if(extFile=="jpg" || extFile=="jpeg" || extFile=="png" || extFile=="webp" || extFile=="svg"){
        let payload = {
            name :"legal",
            type : type,
            image : image,
            status : status
        };
        await addlegalandprivacy(payload).then((result) => {
            console.log("add legal and privacy" , result);
            push("/managelegal")
        })
    }   
    else
    {
        window.alert("Invalid file format !");
    }
} 
    else{
        window.alert("Fill all the datas!")
    }
}


    return(
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont'>
                <h4 className='adminblue my-4'>Add Legal</h4>
                <div className='admin-forms mb-4 '>
                    <div className='shadowbox p-4 br-10'>
                        <div className='row'>
                        <div className='col-sm-6 mb-4'>

                            <FloatingLabel
                                controlId="floatingInput"
                                label="Type *" >
                            <Form.Control type="text"  placeholder='demo' onChange={(e)=>{setType(e?.target?.value)}} />
                            </FloatingLabel>
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel controlId="floatingSelect" label="Status *">
                                <Form.Select aria-label="Floating label select example" onChange={(e)=>setStatus(e?.target?.value)}>
                                    <option value ="">Select Status</option>
                                    <option >Active</option>
                                    <option >Inactive</option>
                                </Form.Select>
                            </FloatingLabel>
                            </div>
                        </div>
                        

                        <div className='row'>
                            <div className='col-sm-6 mb-4'>
                        <h6>Image *:</h6>
                        <div className='upphoto my-3 ml-6'>
                        <Button variant="link" className='nounder text-dark me-2 mb-2'>
                                    <div className={imageview && 'd-none'}></div>
                                    <span className='fa fa-photo'></span>
                                   
                                    <p>Upload Image</p>
                                    
                                    <input type="file"  name = "img" accept=".jpg,.jpeg,.png" onChange ={(e)=>{setImage(e?.target?.files[0]); setImageview(URL.createObjectURL(e?.target?.files[0]))}}/>
                                    <img src = {imageview}/>
                                </Button>
                        </div>
                        </div>
                        </div>




                        
                        <div className='px-4 text-end mt-3 btn_view_oneline' >
                            <button className='btn btn-theme me-3' onClick={handlesubmit}>Submit</button>
                            <Button variant="outline-dark" className='f-12 fw-600 outlinebtn' onClick={()=>{push("/managelegal")}}>Cancel</Button>
                        </div>
                    </div>
                
                </div>

                
               </div> 
            </div>
        </div>
    )
}

export default FormView