import React, { Component, useEffect, useState } from 'react';
// import Toast,{toast, Toaster} from "react-hot-toast"
import { Link, useHistory } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import Manualwithdraw from "./manualwithdrawmodal";
import { createwithdrawpaymenthook, verifywithdrawpaymenthook } from '../../Hooks/adminHook';
// import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
// import { confirmRoom, createRazorPayment, reserveRoom, verifyRazorPayment } from '../../../Hooks/useUserHook';

// import { toastOptions } from '../../../config/env';

const PaymentIntegrationModal = (props) =>{
    const[show , setShow] = useState(true)
    const [roomDetails,setRoomDetails] = useState({});
    const [bookingDetails,setBookingDetails] = useState({});
    const [paymentUrl, setPaymentUrl] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [manual , setManual] = useState(false);

    const [secret , setSecret] = useState("");
    const [initiatedata , setInitiatedata] = useState({});
    const [pay , setPay] = useState(false);

    const {push} = useHistory();

    useEffect(()=>{
        console.log("propsdata" , props?.hostdata);
    })

    const initPayment = (data) => {
        console.log("initpayment data:",data);
		const options = {
			key: "rzp_test_U5z5AHc3kI4WtX",
			amount: parseInt(data?.amount),
			currency: data?.currency,
			name: "jet-to-let",
			description: "Test Transaction",
			image: "https://images-na.ssl-images-amazon.com/images/I/817tHNcyAgL.jpg",
			order_id: data?.id,
			handler: async (response) => {
				try {
                    let dataToVerify = response;
                    console.log("responser" , response);
                    var payload = {
                        paymentverified : response,
                        id : props?.hostdata?._id
                    }
                    console.log("payload" , payload);
                    // dataToVerify.hostemail = props.hostdata?.hostemail;
					const verifiedData = await verifywithdrawpaymenthook(payload);
					console.log("verifiedData",verifiedData);
                    if(verifiedData.data?.type== "success" ){
                        window.alert("Payment sucessfully!!");
                        setShow(false);
                        window.location.reload();
                        // navigate(`/payment-success/${props.bookingDetails._id}`);
                    }
				} catch (error) {
					console.log("error",error);
				}
			},
            // "prefill":{
            //     "name":'Murugavelraj',
            //     "email":'murugavelrajmaticz@gmail.com',
            //     "contact":'1234567890',
            // },
            // "notes": {
            //   "address": "Hello World"
            // },
			theme: {
				color: "#3399cc",
			},
		};
		const rzp1 = new window.Razorpay(options);
		rzp1.open();
	};

    const handlePayment = async () => {
		try {
			const data = {
                id : props?.hostdata?._id,
                amount: props.hostdata?.requestamount,
             }
            const createData = await createwithdrawpaymenthook(data);
			console.log("createData",createData?.data?.record?.record?.paymentcreated?.amount);
			initPayment(createData?.data?.record?.record?.paymentcreated);
		} catch (error) {
			console.log(error);
		}
	};

	

    

    return(
        <Modal show={show} size="md"  centered scrollable className='modal_style'>
          <Modal.Header className='border-0 pb-0'>
      
      <button type="button" className="btn close close_modal" onClick={() =>props.onDismiss()}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
      {manual && <Manualwithdraw data = {props?.hostdata} onDismiss={()=>setManual(false)}></Manualwithdraw>}
      </Modal.Header>
      <h5 className='modalhead text-center mb-4'>Select Payment Gateway</h5>
            <Modal.Body>
               <div>
                {/* <div>
                <PayPalScriptProvider>
                    <PayPalButtons 
                    createOrder={(data, actions) => {
                        return actions.order.create({
                            purchase_units: [
                                {
                                    amount: {
                                        value: props.bookingDetails.totalAmount,
                                    },
                                    payee: {
                                        email_address: "sb-sun8j15395062@business.example.com"
                                    }
                                },
                            ],
                        });
                    }}
                    onApprove={(data, actions) => {
                        console.log("onApprove data:",data,"actions:",actions);
                        return actions.order.capture().then((details) => {
                            console.log("onApprove then actions.order.capture() details:",details);
                            const name = details.payer.name.given_name;
                            bookRoom(details.id,"paypal");
                            alert(`Transaction completed by ${name}`);
                        });
                    }}
                    onError = {(err) => {
                        alert('error in fund transfer');
                    }}
                    style={{ layout: "horizontal" }} />
                </PayPalScriptProvider>
                </div> */}

                <Modal isOpen={isOpen} onDismiss={()=>{setIsOpen(false)}}>
                    <iframe src={paymentUrl} />
                </Modal>

                <button  className="w-full btn btn-theme mb-4">Paypal</button>
                <button  className="w-full btn btn-theme mb-4" onClick={handlePayment}>Razorpay</button>
                <button  className="w-full btn btn-theme mb-4"
                    onClick={()=> {
                        setManual(true);
                        
                    }}
                 >Manual payment</button>
               </div>
            </Modal.Body>
            {/* <Toaster/> */}
      </Modal>
    )
}

export default PaymentIntegrationModal