import React, { useEffect, useState } from 'react';
import { Button, FloatingLabel, Form } from 'react-bootstrap';
import Cookies from 'universal-cookie';
import Otpmodal from './otp-modal';
import logo from '../../Assets/images/logo.png'
import { forgotpasswordmailhook } from '../../Hooks/adminHook';
import { useHistory, useLocation } from 'react-router-dom';
const cookies = new Cookies();

const Forget = () =>{
    const [email , setEmail] = useState('');
    const [otpcomponent , setOtpcomponent] = useState(true);
    const {push} = useHistory();
    // useEffect(()=>{
    //     if(!cookies.get('otp-data')){
    //         push("/login");
    //     }
    // })
    const handlesubmit = async()=>{
        console.log("handle submit");
        var validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if(validRegex?.test(email)){
            await forgotpasswordmailhook(email).then((result)=>{
                console.log("forgot password mail hook result" , result);
                if(result?.data?.type == "reject"){
                    window.alert("Admin User Not Found");
                }
                if(result?.data?.type == "success"){
                    cookies.set('otp-data', result?.data?.otp, { path: '/' }, {expires: new Date(Date.now()+600000) });
                    cookies.set('email-data', email, { path: '/' }, {expires: new Date(Date.now()+600000) });
                    setOtpcomponent(true);
                }
            })
        }
        else{
            window.alert("Invalid email !..");
        }
    }
    return(
        <div className='adminlogin'>
             <div className='container container-theme'>
                <div className='row justify-content-center mh-100vh align-items-center m-sm-0 m-3'>
                    <div className='shadowbox col-lg-5 col-xl-4 col-md-6 bg-white br-10'>
                            <div className='py-5'>
                                <h3 className='px-lg-5 px-3  mb-4 text-center'>Forgot Pasword?</h3>
                                <div className='text-center'>
                                <img src={logo} className='img-fluid'/>
                                </div>
                                {otpcomponent &&
                                <Otpmodal onDismiss = {()=>{setOtpcomponent(false)}}></Otpmodal>
                                
                                }
                                <div className='px-lg-5 px-3 h55 pt-5'>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Email"
                                        className="mb-3" >
                                        <Form.Control type="email" placeholder="name@example.com" onChange={(e)=>{setEmail(e?.target?.value)}} />
                                    </FloatingLabel>
                                    
                                   <div className='mt-4 text-center'>
                                       <button className='btn btn-theme' onClick={handlesubmit}>Submit</button>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
             </div>
        </div>
    )
}

export default Forget