import React, { useState, useEffect } from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import { FloatingLabel, Form, Button } from 'react-bootstrap'
import ReactSummernote from 'react-summernote';
import DataTable from 'react-data-table-component';
import { Bar } from "react-chartjs-2";
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { acceptuserverificationhook, editemailtemplatehook, edituserhook, rejectuserverificationhook } from '../../../Hooks/adminHook';
import { API_URL } from '../../../config/env';
const FormView = () => {
    const location = useLocation();

    const { push } = useHistory();

    const [singleemailtemplate, setSingleEmailTemplate] = useState({});
    const [status, setStatus] = useState(false);
    const [content, setContent] = useState({});
    const [subject, setSubject] = useState('');

    useEffect(()=>{
        document.getElementById("manageemailtemplate").classList.add("active");
        if(window.innerWidth>767)
        {
            // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
            if(document.querySelectorAll(".themescroll li a.active").length>0)
            {           
                 var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
          console.log(pagelinkofset,"pagelinkofset")
                 document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);

            }

        }
        else
        {
            // alert(2)
            if(document.querySelectorAll(".themescroll li a.active").length>0)
            {
                // console.log(document.querySelectorAll(".list-group-item-action.active")[0].offsetTop,"top");
                if(document.querySelectorAll(".themescroll li a.active").length>0)
                {  
                var pagelinkofset = document.querySelectorAll(".themescroll li a.active")[0].offsetTop;
                document.getElementById("scrollbar_sidebar_id").scrollTo(0,pagelinkofset - 200);
                }
            }
            
        }
    },[])

    useEffect(() => {
        console.log("location data emailtemplate", location?.state?.state);
        setSingleEmailTemplate(location?.state?.state);
        setStatus(location?.state?.state?.status);
        setContent(location?.state?.state?.content);
        setSubject(location?.state?.state?.subject)

    }, [])

    const handlesubmit = async () => {
        console.log("handle submit");
        if (status?.length > 0) {
            let payload = {
                id: location?.state?.state?._id,
                status: status,
                subject: subject,
                content: content
            };
            await editemailtemplatehook(payload).then((result) => {
                console.log("edit userhook result", result);
                push("/manage-emailtemplates");
            })
        }
    }

    const handlecancel = async () => {
        console.log("cancel");
        push("/manage-emailtemplates");
    }


    return (
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar />
            </div>
            <div className='headandcont'>
                <div className="adminheader"><AdminHeader /></div>
                <div className='cont'>
                    <h4 className='adminblue my-4'>Edit Email Template</h4>
                    <div className='admin-forms mb-4 '>
                        <div className='shadowbox p-4 br-10'>
                            <div className='row'>
                                <div className='col-sm-6 mb-4'>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Identifier"
                                    >
                                        <Form.Control type="text" value={singleemailtemplate?.identifier} readOnly={true} placeholder="name@example.com" />
                                    </FloatingLabel>
                                </div>
                                <div className='col-sm-6 mb-4'>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Subject"
                                    >
                                        <Form.Control type="text" value={subject} placeholder="name@example.com" onChange={(e) => setSubject(e.target.value)} />
                                    </FloatingLabel>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-6 mb-4'>
                                    <p>Content *:</p>
                                    <ReactSummernote
                                        value={content}
                                        options={{
                                            //   lang: 'ru-RU',
                                            height: 350,
                                            dialogsInBody: true,
                                            toolbar: [
                                                ['style', ['style']],
                                                ['font', ['bold', 'underline', 'clear']],
                                                ['fontname', ['fontname']],
                                                ['para', ['ul', 'ol', 'paragraph']],
                                                ['table', ['table']],
                                                ['insert', ['link', 'picture', 'video']],
                                                ['view', ['fullscreen', 'codeview']]
                                            ]
                                        }}
                                        onChange={(e) => { console.log("e.tar.content", e); setContent(e) }}
                                    />
                                </div></div>
                            {/* <div className='row'>
                                <div className='col-sm-6 mb-4'>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Status"
                                    >
                                        <Form.Select aria-label="Floating label select example" value={status} onChange={e => setStatus(e.target.value)}>
                                            <option>active</option>
                                            <option>Inactive</option>
                                        </Form.Select>
                                    </FloatingLabel>
                                </div>

                            </div> */}


                            <div className='px-4 text-end mt-3 btn_view_oneline' >
                                <button className='btn btn-theme me-3' onClick={handlesubmit}>Submit</button>
                                <Button variant="outline-dark" className='f-12 fw-600 outlinebtn' onClick={handlecancel}>Cancel</Button>
                            </div>
                        </div>

                    </div>


                </div>
            </div>
        </div>
    )
}

export default FormView