import React,{useState , Fragment, useEffect} from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import {FloatingLabel, Form,Button} from 'react-bootstrap'
// import DataTable from 'react-data-table-component';
import DataTable  from '@ashvin27/react-datatable';
import { Bar } from "react-chartjs-2";
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from 'react-router-dom';
import { deactivatehostingexperiencehook, gethostingexperiencehook } from '../../../Hooks/adminHook';
const TableView = () =>{
    const {push} = useHistory();
    const [experience , setExperience] = useState([]);
    const [totalrecordcounts, setTotalRecordCounts] = useState(0)
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);

    useEffect(()=>{
        async function fetchdata(){
            let reqData = {
                page : page,
                limit : limit
            }
            await gethostingexperiencehook(reqData).then((result)=>{
                console.log("get hosting experience result" , result?.data?.data?.record);
                result?.data?.data?.record?.map((data , i)=> data.serial = i+1);
                setExperience(result?.data?.data?.record);
                setTotalRecordCounts(result?.data?.data?.count);
            })
        }
        fetchdata();
    },[])
    const columns = [
        {
            key: "serial",
            text: "Id",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "title",
            text: "Title",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "email",
            text: "Host email",
            className: "name",
            align: "left",
            // sortable: true,
        },
        {
            key: "startTime",
            text: "Start Date",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "endTime",
            text: "End Date",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "pricePerGuest",
            text: "Price Per Head",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "status",
            text: "Status",
            className: "name",
            align: "left",
            sortable: true,
        },
        {
            key: "isDeactivate",
            text: "Is Deactivate",
            className: "name",
            align: "left",
            sortable: true,
            cell:(data)=>(data.isDeactivate?"true" : "false")
        },
        
        {
            key: "action",
            text: "Action",
            className: "action",
            width: 100,
            align: "left",
            sortable: true,
            cell: record => {
                return (
                    <Fragment>
                        {/* <button
                            className="btn btn-primary btn-sm"
                            // onClick={() => viewRecord(record)}
                            style={{ marginRight: '5px' }}>
                            <i class="fa fa-eye mr-0" ></i>
                        </button> */}
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={() => editRecord(record)}
                            style={{ marginRight: '5px' }}
                            >
                            <i className="fa fa-edit mr-0"></i>
                        </button>
                        {/* <button
                            className="btn btn-danger btn-sm"
                            onClick={() => uploadImage(record)}>
                            <i className="fa fa-edit mr-0"></i>
                        </button> */}
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={() => deactivateexperience(record)}
                            style={{ marginRight: '5px' }}>
                            <i className="fa fa-trash mr-0"></i>
                        </button>
                    </Fragment>
                );
            }
        }
    ]
   
const editRecord = async(data)=>{
    console.log("data" , data);
    push("/edit-hosting-experience" , {state : data});
}
const deactivateexperience = async(data) => {
    console.log("deactivate");
    await deactivatehostingexperiencehook(data._id).then((result) => {
        console.log("deactivate room hook" , result);
        window.location.reload()
    })
}
    const config = {
        page_size: 10,
        length_menu: [ 10, 20, 50 ],
        filename: "Users",
        no_data_text: 'No user found!',
        button: {
            excel: true,
            print: true,
            csv: true
        },className: "table",
        language: {
            length_menu: "Show _MENU_",
            filter: "Filter User",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first:"<<",
                previous: "<",
                next: ">",
                last: ">>"
            }
        },
        show_length_menu: true,
        show_filter: true,
        show_pagination: true,
        show_info: true,
    };
  

const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun"];

// const datas = {
//   labels: labels,
//   datasets: [
//     {
//       label: "My First dataset",
//       backgroundColor: "rgb(255, 99, 132)",
//       borderColor: "rgb(255, 99, 132)",
//       data: [0, 10, 5, 2, 20, 30, 45],
//     },
//   ],
// };

const pageChange = async (index) => {
    try {
        let reqData = {
            page: index.page_number,
            limit : index.page_size,
            search: index.filter_value
        }
        await gethostingexperiencehook(reqData).then((result)=>{
            console.log("get hosting experience result" , result?.data?.data?.record);
            result?.data?.data?.record?.map((data , i)=> data.serial = i+1);
            setExperience(result?.data?.data?.record);
        })
        console.log('index_data', index)
    } catch (err) {
        console.log("pageChange_err", err)
    }
}
    return(
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont'>
                <h4 className='adminblue my-4'>Hosting Experience</h4>
                
             
              
                <div className='tablesec shadowbox px-3 mt-3 br-10 mb-5'>
                    <h6 className='adminblue pt-4 me-2' ><span className='fa fa-book'></span> View Host Exprience</h6>
                    {/* <DataTable pagination columns={columns} records={data} config = {config} className={config.className}/> */}
                    <DataTable striped responsive
                        config={config}
                        className={config.className}
                        records={experience}
                        columns={columns}
                        dynamic={true}
                        total_record={totalrecordcounts}
                        onChange={pageChange}

                    />
               </div>
               </div> 
            </div>
        </div>
    )
}

export default TableView