import React,{useEffect, useState} from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import {FloatingLabel, Form,Button} from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import validator from 'validator'
import { Bar } from "react-chartjs-2";
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { editsplitdayshook, getjoinushook, getsplitdayshooks, getstaydatehooks, updatejoinushook, updatestaydatehooks } from '../../../Hooks/adminHook';
const FormView = () =>{
    let regex =  /^\d+$/;
  const [record , setRecord] = useState({});
  const [minday , setMinday] = useState("");
  const [maxday , setMaxday] = useState("");

  const fetchdata = async() => {
    let res = await getsplitdayshooks();
    console.log("res in staysdate" , res);
    setRecord(res?.data?.data?.record);
    setMinday(res?.data?.data?.record?.minday);
    setMaxday(res?.data?.data?.record?.maxday);
}

  useEffect(()=>{
    
    fetchdata();
  },[])

  const handlesubmit = async()=>{
    if(isNaN(parseFloat(minday)) || parseFloat(minday) <= 0){
        window.alert("Invalid minimum days value");
        return
    }
    // if(isNaN(parseFloat(maxday)) || parseFloat(maxday) <= 0){
    //     window.alert("Invalid maximum days value");
    //     return
    // }
    let payload = {
        min : minday,
        max : maxday,
        id : record?._id
    }
    let result = await editsplitdayshook(minday);
    window.alert("Updated Successfully!..")
    await fetchdata();
}
  


    return(
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont'>
                <h4 className='adminblue my-4'>Stay Days</h4>
                <div className='admin-forms mb-4 '>
                    <div className='shadowbox p-4 br-10'>
                        <div className='row'>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Minimum Days *"
                                >
                                    {console.log("valuessssssss", minday)}
                                <Form.Control placeholder="demo" type="text" value={minday != undefined ? minday : ""} onChange={(e) => { console.log("regex.test(e?.target?.value)", regex.test(e?.target?.value));if(regex.test(e?.target?.value) || e?.target?.value == "") {setMinday(e?.target?.value)}   }} />
                            </FloatingLabel>
                          
                            </div>
                            {/* <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Maximum Days *"
                                >
                            <Form.Control placeholder="demo"  type="text" value={maxday != undefined ? maxday : "" } onChange={(e) => {   if(regex.test(e?.target?.value) || e?.target?.value == "") {setMaxday(e?.target?.value)}   }}  />
                            </FloatingLabel>
                            </div> */}
                        </div>
                        <div className='px-4 text-end mt-3' >
                            <button className='btn btn-theme me-3' onClick={handlesubmit}>Submit</button>
                            {/* <Button variant="outline-dark" className='f-12 fw-600 outlinebtn'>Cancel</Button> */}
                        </div>
                    </div>
                
                </div>

                
               </div> 
            </div>
        </div>
    )
}

export default FormView