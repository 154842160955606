import React, { useState , useEffect} from 'react';
import { Button, FloatingLabel, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import logo from '../../Assets/images/logo.png'
import { forgotpasswordhook } from '../../Hooks/adminHook';
const cookies = new Cookies();

const ChangePassword = () =>{
    const [password , setPassword] = useState('');
    const [confirmpassword , setConfirmpassword] = useState('');
    const {push} = useHistory();

    useEffect(()=>{
        if(!cookies.get('otp-data')){
            push("/login");
        }
    })
    const handlesubmit = async()=>{
        if(password?.length > 0 && confirmpassword?.length > 0){
            if(password == confirmpassword){
                let payload = {
                    password : password,
                    email : cookies.get('email-data')
                }
                await forgotpasswordhook(payload).then((result)=>{
                    console.log("forgotpasssword hook result" , result);
                    cookies.remove('otp-data');
                    cookies.remove('email-data');
                    push("/login");
                })
            }
            else{
                window.alert("Password and Confirm Password musb be same!..");
            }
        }
        else{
            window.alert("Fill all the fields !..");
        }
    }
    return(
        <div className=''>
             <div className='container container-theme'>
            
                <div className='row justify-content-center mh-100vh align-items-center m-sm-0 m-3'>
                    <div className='col-lg-8 '>
                       <div className='text-center mb-3'> <img src={logo} /></div>
                    <h5 className=' mb-4 text-center adminblue mt-5'>Change Password</h5>
                    <div className='shadowbox pt-5  p-4 bg-white br-10'>
                        <div className='d-flex gap-3'>
                        <div className='h-55 w-full'>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Password"
                                        className="mb-3" >
                                        <Form.Control type="password" placeholder="name@example.com" onChange={(e)=>{setPassword(e?.target?.value)}}/>
                                    </FloatingLabel>
                                </div>
                                <div className='h-55 w-full'>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Confirm Password"
                                        className="mb-3" >
                                        <Form.Control type="password" placeholder="name@example.com" onChange={(e)=>{setConfirmpassword(e?.target?.value)}}/>
                                    </FloatingLabel>
                                </div>
                        </div> 
                                
                                <div className='mt-3 text-end'>
                                       <button className='btn btn-theme me-2 ' onClick={handlesubmit}>Submit</button>
                                       <button className='btn btn-theme cancel' onClick={()=>{push("/login")}}>Cancel</button>
                                </div>
                    </div>
                    </div>
              
                </div>
             </div>
        </div>
    )
}

export default ChangePassword