import React,{useEffect, useState} from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import {FloatingLabel, Form,Button} from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import { Bar } from "react-chartjs-2";
import Datepicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { getpaymentgatewayhook, updatepaymentgatewayhook } from '../../../Hooks/adminHook';
const FormView = () =>{
  

    const [braintreemode , setBraintreemode] = useState("");
    const [braintreemerchantid , setBraintreemerchantid] = useState('');
    const [braintreemerchantaccountid , setBraintreemerchantaccountid] = useState('');
    const [braintreepublickey , setBraintreepublickey] = useState('');
    const [braintreeprivatekey , setBraintreeprivatekey] = useState('');
    const [braintreetokenizationkey , setBraintreetokenizationkey] = useState('');
    const [paypalclientid , setPaypalclientid] = useState('');
    const [paypalsecret , setPaypalsecret] = useState('');
    const [stripepublishablekey , setStripepublishablekey] = useState('');
    const [stripesecretkey , setStripesecretkey] = useState('');
    const [stripeclientid , setStripeclientid] = useState("");
    const [flutterwavepublishablekey , setFlutterwavepublishablekey] = useState('');
    const [flutterwavesecretkey , setFlutterwavesecretkey] = useState('');
    const [encryptionkey , setEncryptionkey] = useState('');

    useEffect(()=>{
        async function fetchdata(){
            await getpaymentgatewayhook().then((result)=>{
                console.log("get payment gateway result" , result?.data?.data?.record[0]?.details);
                setBraintreemode(result?.data?.data?.record[0]?.details?.braintreemode);
                setBraintreemerchantid(result?.data?.data?.record[0]?.details?.braintreemerchantid);
                setBraintreemerchantaccountid(result?.data?.data?.record[0]?.details?.braintreemerchantaccountid);
                setBraintreepublickey(result?.data?.data?.record[0]?.details?.braintreepublickey);
                setBraintreeprivatekey(result?.data?.data?.record[0]?.details?.braintreeprivatekey);
                setBraintreetokenizationkey(result?.data?.data?.record[0]?.details?.braintreetokenizationkey);
                setPaypalclientid(result?.data?.data?.record[0]?.details?.paypalclientid);
                setPaypalsecret(result?.data?.data?.record[0]?.details?.paypalsecret);
                setStripepublishablekey(result?.data?.data?.record[0]?.details?.stripepublishablekey);
                setStripesecretkey(result?.data?.data?.record[0]?.details?.stripesecretkey);
                setStripeclientid(result?.data?.data?.record[0]?.details?.stripeclientid);
                setFlutterwavepublishablekey(result?.data?.data?.record[0]?.details?.flutterwavepublishablekey);
                setFlutterwavesecretkey(result?.data?.data?.record[0]?.details?.flutterwavesecretkey);
                setEncryptionkey(result?.data?.data?.record[0]?.details?.encryptionkey);
            })
        }
        fetchdata();
    },[])
  
    const handlesubmit = async()=>{
        if(braintreemode?.length>0 && braintreemerchantid?.length>0 && braintreepublickey?.length &&
            braintreeprivatekey?.length>0 && braintreetokenizationkey?.length>0 && paypalclientid?.length>0 &&
            paypalsecret?.length>0 && flutterwavepublishablekey?.length>0 && flutterwavesecretkey?.length>0 &&
            encryptionkey?.length>0)
            {
                let payload = {
                    braintreemode : braintreemode,
                    braintreemerchantid :braintreemerchantid,
                    braintreemerchantaccountid : braintreemerchantaccountid,
                    braintreepublickey : braintreepublickey,
                    braintreeprivatekey : braintreeprivatekey,
                    braintreetokenizationkey : braintreetokenizationkey,
                    paypalclientid : paypalclientid,
                    paypalsecret : paypalsecret,
                    stripepublishablekey : stripepublishablekey,
                    stripesecretkey : stripesecretkey,
                    stripeclientid : stripeclientid,
                    flutterwavepublishablekey : flutterwavepublishablekey,
                    flutterwavesecretkey : flutterwavesecretkey,
                    encryptionkey : encryptionkey
                }
                console.log("payload" , payload);
                await updatepaymentgatewayhook(payload).then((result)=>{
                    console.log("update gateway result" , result);
                    window.location.reload();
                })
            }
            else{
                window.alert("Fill all the fields !..");
            }
    }

    return(
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont'>
                <h4 className='adminblue my-4'>Payment Gateway Form</h4>
                <div className='admin-forms mb-4 '>
                    <div className='shadowbox p-4 br-10'>
                        <div className='row'>
                            <div className='col-sm-6 mb-4'>
                            
                            <FloatingLabel controlId="floatingSelect" label="Braintree Mode" >
                            <Form.Select aria-label="Floating label select example" value={braintreemode} onChange={(e)=> setBraintreemode(e?.target?.value)}>
                                <option value= "">Select Mode</option>
                                <option >Sandbox</option>
                                <option >Live</option>
                            </Form.Select>
                            </FloatingLabel>
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Braintree Merchant Id *"
                                 >
                                <Form.Control placeholder="demo" type="text" defaultValue={braintreemerchantid} onChange={(e)=> setBraintreemerchantid(e?.target?.value) }/>
                            </FloatingLabel>
                            </div>
                        </div>
                        <div className='row'>
                        <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Braintree Merchant Account Id"
                                 >
                            <Form.Control placeholder="demo" type="text" defaultValue={braintreemerchantaccountid} onChange={(e)=> setBraintreemerchantaccountid(e?.target?.value)} />
                            </FloatingLabel>
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Braintree Public Key *"
                                 >
                                <Form.Control placeholder="demo" type="text" defaultValue={braintreepublickey} onChange={(e)=> setBraintreepublickey(e?.target?.value) }/>
                            </FloatingLabel>
                            </div>
                        </div>

                        <div className='row'>
                        <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Braintree Private Key *"
                                 >
                            <Form.Control placeholder="demo" type="text" defaultValue={braintreeprivatekey} onChange={(e)=> setBraintreeprivatekey(e?.target?.value)} />
                            </FloatingLabel>
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Braintree Tokenization Key *"
                                 >
                                <Form.Control placeholder="demo" type="text" defaultValue={braintreetokenizationkey} onChange={(e)=> setBraintreetokenizationkey(e?.target?.value) }/>
                            </FloatingLabel>
                            </div>
                        </div>

                        <div className='row'>
                        <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Paypal Client Id *"
                                 >
                            <Form.Control placeholder="demo" type="text" defaultValue={paypalclientid} onChange={(e)=> setPaypalclientid(e?.target?.value)} />
                            </FloatingLabel>
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Paypal Secret *"
                                 >
                                <Form.Control placeholder="demo" type="text" defaultValue={paypalsecret} onChange={(e)=> setPaypalsecret(e?.target?.value) }/>
                            </FloatingLabel>
                            </div>
                        </div>

                        <div className='row'>
                        <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Stripe publishable Key"
                                 >
                            <Form.Control placeholder="demo" type="text" defaultValue={stripepublishablekey} onChange={(e)=> setStripepublishablekey(e?.target?.value)} />
                            </FloatingLabel>
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Stripe Secret Key"
                                 >
                                <Form.Control placeholder="demo" type="text" defaultValue={stripesecretkey} onChange={(e)=> setStripesecretkey(e?.target?.value) }/>
                            </FloatingLabel>
                            </div>
                        </div>

                        <div className='row'>
                        <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Stripe Client Id"
                                 >
                            <Form.Control placeholder="demo" type="text" defaultValue={stripeclientid} onChange={(e)=> setStripeclientid(e?.target?.value)} />
                            </FloatingLabel>
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Flutterwave Publishable Key *"
                                 >
                                <Form.Control placeholder="demo" type="text" defaultValue={flutterwavepublishablekey} onChange={(e)=> setFlutterwavepublishablekey(e?.target?.value) }/>
                            </FloatingLabel>
                            </div>
                        </div>
                        <div className='row'>
                        <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Flutterwave Secret Key *"
                                 >
                            <Form.Control placeholder="demo" type="text" defaultValue={flutterwavesecretkey} onChange={(e)=> setFlutterwavesecretkey(e?.target?.value)} />
                            </FloatingLabel>
                            </div>
                            <div className='col-sm-6 mb-4'>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Encryption Key *"
                                 >
                                <Form.Control placeholder="demo" type="text" defaultValue={encryptionkey} onChange={(e)=> setEncryptionkey(e?.target?.value) }/>
                            </FloatingLabel>
                            </div>
                        </div>

                        
                        <div className='px-4 text-end mt-3' >
                            <button className='btn btn-theme me-3' onClick={handlesubmit}>Submit</button>
                            {/* <Button variant="outline-dark" className='f-12 fw-600 outlinebtn'>Cancel</Button> */}
                        </div>
                    </div>
                
                </div>

                
               </div> 
            </div>
        </div>
    )
}

export default FormView