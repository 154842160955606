import React,{useEffect, useState} from 'react';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import {Button} from 'react-bootstrap'
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useHistory } from 'react-router-dom';
const FormView = () =>{
  const location = useLocation();
//   const navigate = useNavigate();
const {push} = useHistory();

    useEffect(() => {
        console.log("location data" , location?.state);
    },[])
  

    return(
        <div id="dashboard" className='showsidebar'>
            <div id='sidebar'>
                <Sidebar/>
            </div>
            <div className='headandcont'>
               <div className="adminheader"><AdminHeader /></div> 
               <div className='cont'>
                <h4 className='adminblue my-4'>Payout</h4>
                <div className='d-sm-flex gap-4 flex-1 px-4 pt-3'>
                    <h6>name</h6>
                    <p>name</p>
                </div>
                
                <div className='px-4 text-end mt-3 btn_view_oneline' >
                            <button className='btn btn-theme me-3'>Submit</button>
                            <Button variant="outline-dark" className='f-12 fw-600 outlinebtn'>Cancel</Button>
                        </div>
                
               </div> 
            </div>
        </div>
    )
}

export default FormView