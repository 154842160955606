import React, { useState ,useEffect} from 'react';
import {Modal, Button, InputGroup , Form} from 'react-bootstrap'
// import { TENMINUTESINSECONDS } from '../../config/env';
// import { CheckLoginOtp } from '../../Hooks/UseBackendAction/UseCreateUser.js';
import axios from "axios";
import Cookies from 'universal-cookie';

import {Buffer} from 'buffer';
import { useHistory } from 'react-router-dom';
import { forgotpasswordmailhook } from '../../Hooks/adminHook';
const cookies = new Cookies();


const Otp = (props) =>{
  const TENMINUTESINSECONDS = 600;
   const[otp, setOtp] = useState(true)
   const [box1,setbox1] = useState("")
   const [box2,setbox2] = useState("")
   const [box3,setbox3] = useState("")
   const [box4,setbox4] = useState("")
   const [box5,setbox5] = useState("")
   const [box6,setbox6] = useState("")
   const [username,setusername] = useState(sessionStorage.getItem("username"))
   const [Enablebtn, setEnablebtn] = useState(false)
   const [counter, setCounter] = useState(TENMINUTESINSECONDS)
   const [Seconds, setseconds] = useState(0)
   const [Minutes, setminutes] = useState(0)
   const [dbotp , setDbotp] = useState( cookies.get("otp-data"))
   const [ip, setIP] = useState('');
   const {push} = useHistory();
   const hidelogin = () => {
      document.getElementById("login").style.visibility = "hidden"
    }
    let tabChange = (val) => {
      if(3 >= val === true){
      
        let ele = document.querySelectorAll('input.otp');
        
        if(ele[val-1].value !== ''){
          ele[val].focus()
        }else if(ele[val-1].value === ''){
          ele[val-2].focus()
        }  
      } 
     }
     useEffect(() => {
      // GetIpAddress()
      if( counter == 0){
       
          setEnablebtn(true);
       
      }
      const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
     
      const seconds = String(counter % 60).padStart(2, 0);
      setseconds(seconds)
      const minutes = String(Math.floor(counter / 60)).padStart(2, 0);
      setminutes(minutes)


      
      return () => 
      
           clearInterval(timer);
          

     
  }, [counter]);

     const GetIpAddress = async()=>{
      const res = await axios.get('https://geolocation-db.com/json/')
      
       setIP(res?.data?.IPv4)
  }
     const onOtpSubmit = async (e) => {
      

      const Loginhistory ={
       
        loginTime:Buffer.from(String(Date.now())).toString('base64') ,
        Browser:Buffer.from(window.navigator.userAgent).toString('base64'),
        ipaddress: Buffer.from(ip).toString('base64')

     }
      e.preventDefault();
    
      const UserOtpData = {
        // headers : {authorization: `Bearer ${accessToken}`},

        user_name: username,
        Otp: box1 + box2 + box3 + box4,
        Date:Date.now(),
        LoginHistory:Loginhistory
          
      };
     
      // await CheckLoginOtp(UserOtpData)

  };
const verifyotp = async() => {
  // const dbotp = cookies.get("otp-data").toString();
  const otp = box1 + box2 + box3 + box4;
  console.log("otp" , dbotp , otp);
  if(otp == dbotp){
    push("/change-password");
  }
  else{
    window.alert("Invalid otp !");
  }
}

const resendotp = async()=>{
  const email = cookies.get('email-data');
  await forgotpasswordmailhook(email);
}

   return(
    <>
<Modal size="md" show={otp} centered scrollable  onHide={() => {props.onDismiss()}} className='modal_style thememodal loginmodal'>
      <Modal.Header className='border-0 pb-0'>
      <button type="button" className="btn close close_modal ms-auto" onClick={() =>{props.onDismiss()}}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>

      </Modal.Header>
      <Modal.Body>
       
      <div className='mb-4'>
        <div className=''>
           <h3 className='mb-0 text-center'>Forgot Password </h3>
            
        </div>
        <p className='text-center'><label className='mt-4'>Enter Otp</label></p>
        <form action="" className=" mb-4 confirm justify-content-center d-flex gap-2">
              <input className="otp" type="text"  onChange={(e) => { setbox1(e.target.value) }} onKeyUp={() => tabChange(1)} maxlength="1" />
              <input className="otp" type="text"  onChange={(e) => { setbox2(e.target.value) }} onKeyUp={() => tabChange(2)} maxlength="1" />
              <input className="otp" type="text"  onChange={(e) => { setbox3(e.target.value) }} onKeyUp={() => tabChange(3)} maxlength="1" />
              <input className="otp" type="text"  onChange={(e) => { setbox4(e.target.value) }} onKeyUp={() => tabChange(4)} maxlength="1" />
              
         </form>
         <div className="text-center">
      
          
            <button className="btn btn-theme me-2" onClick={resendotp}>Resend</button>
            <button className="btn btn-theme" onClick={verifyotp}>Confirm</button>
           </div>
           <div className=" mt-3 text-center">
            <p className="pr-0"> Otp will expires in <b>  <span>{Minutes}:{Seconds}</span></b></p></div> 
           
                
      </div>
      </Modal.Body>

    </Modal>
  </>
   )
}

export default Otp